import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';
import { AppState } from '../types';

/** Set current configuration ID to given ID */
export const setConfigId = createAction<string>('setConfigId');

/** Append reducer for {@see setConfigId} action to Redux slice */
export const addSetConfigIdReducer = (builder: ActionReducerMapBuilder<AppState>) => {
  builder.addCase(setConfigId, (state, action) => {
    state.configId = action.payload;
  });
};
