import { useDispatch } from 'react-redux';
import { addWidget } from '../../../state/actions';
import { WidgetType } from '../../../core/widgets/definitions';
import { ModalDescription, Modal } from '../components';
import imageImage from './st-image.svg';
import textImage from './st-text.svg';
import buttonImage from './st-button.svg';
import styles from './AddWidgetModal.module.scss';

interface AddWidgetModalProps {
  onRequestClose: () => void;
}

export function AddWidgetModal(props: AddWidgetModalProps) {
  const dispatch = useDispatch();
  const handleAddTextClick = () => {
    props.onRequestClose();
    dispatch(addWidget(WidgetType.Text));
  };
  const handleAddButtonClick = () => {
    props.onRequestClose();
    dispatch(addWidget(WidgetType.Button));
  };
  const handleAddImageClick = () => {
    props.onRequestClose();
    dispatch(addWidget(WidgetType.Image));
  };

  return (
    <Modal title="Add new element" isOpen={true} onRequestClose={props.onRequestClose} width={400}>
      <ModalDescription>Select element type to add it to the design.</ModalDescription>

      <ul className={styles.list}>
        <li>
          <button className={styles.button} onClick={handleAddImageClick}>
            {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
            <img className={styles.buttonImage} src={imageImage} alt="Image" />
            Image
          </button>
        </li>
        <li>
          <button className={styles.button} onClick={handleAddTextClick}>
            <img className={styles.buttonImage} src={textImage} alt="Text" />
            Text
          </button>
        </li>
        <li>
          <button className={styles.button} onClick={handleAddButtonClick}>
            <img className={styles.buttonImage} src={buttonImage} alt="Button" />
            Button
          </button>
        </li>
      </ul>
    </Modal>
  );
}
