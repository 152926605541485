import classNames from 'classnames';
import { HorizontalAlign } from '../../../core/widgets/definitions';
import leftImage from './assets/position-switcher-left.svg';
import centerImage from './assets/position-switcher-center.svg';
import rightImage from './assets/position-switcher-right.svg';
import { useField } from 'formik';
import styles from './HorizontalPositionFormField.module.scss';

interface HorizontalPositionFormFieldProps {
  name: string;
}

export function HorizontalPositionFormField(props: HorizontalPositionFormFieldProps) {
  const [field, , helpers] = useField(props);
  console.log(field);

  return (
    <ul className={styles.list}>
      <li className={styles.item}>
        <button className={styles.button} onClick={() => helpers.setValue(HorizontalAlign.Left)}>
          <div
            className={classNames({
              [styles.imageContainer]: true,
              [styles.active]: field.value === HorizontalAlign.Left,
            })}
          >
            <img
              className={classNames({ [styles.image]: true })}
              src={leftImage}
              alt="Align left"
            />
          </div>
          Left
        </button>
      </li>
      <li className={styles.item}>
        <button className={styles.button} onClick={() => helpers.setValue(HorizontalAlign.Center)}>
          <div
            className={classNames({
              [styles.imageContainer]: true,
              [styles.active]: field.value === HorizontalAlign.Center,
            })}
          >
            <img className={styles.image} src={centerImage} alt="Align center" />
          </div>
          Center
        </button>
      </li>
      <li className={styles.item}>
        <button className={styles.button} onClick={() => helpers.setValue(HorizontalAlign.Right)}>
          <div
            className={classNames({
              [styles.imageContainer]: true,
              [styles.active]: field.value === HorizontalAlign.Right,
            })}
          >
            <img
              className={classNames({
                [styles.image]: true,
                // [styles.active]: field.value === HorizontalAlign.Right,
              })}
              src={rightImage}
              alt="Align right"
            />
          </div>
          Right
        </button>
      </li>
    </ul>
  );
}
