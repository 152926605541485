import { Dispatch } from 'react';
import { useDispatch } from 'react-redux';
import { contrastToHexColor, findTemplateWidget } from '../../../../../../core/utils';
import { mergeWidgetConfig } from '../../../../../../state/actions';
import { CommonWidgetIds, Template } from '../../../../../../core/template';
import { normalizeColor } from '../../../../../form-components';
import { FontFamily, FontWeight, WidgetType } from '../../../../../../core/widgets/definitions';
import {
  ButtonWidgetConfig,
  WithBorder,
  WithBorderRadius,
} from '../../../../../../core/widgets/types';
import { UseFormResult } from '../../types';

export enum ButtonShape {
  Rounded = 'rounded',
  Square = 'square',
  Pill = 'pill',
  Ghost = 'ghost',
}

interface FormConfig {
  buttonFontFamily: FontFamily;
  buttonFontWeight: FontWeight;
  buttonBackgroundColor: string;
  buttonShape: ButtonShape;
  dismissButtonFontFamily: FontFamily;
  dismissButtonFontWeight: FontWeight;
  dismissButtonBackgroundColor: string;
  dismissButtonShape: ButtonShape;
}

function validateForm(values: FormConfig) {
  const errors: { [name in keyof FormConfig]?: string } = {};

  return errors;
}

function getWidgets(template: Template) {
  const mainContainer = findTemplateWidget(
    template,
    CommonWidgetIds.MainContainer,
    WidgetType.Container
  );
  const mainButton = findTemplateWidget(template, CommonWidgetIds.MainButton, WidgetType.Button);
  const triggerButton = findTemplateWidget(
    template,
    CommonWidgetIds.TriggerButton,
    WidgetType.Button
  );
  const dismissButton = findTemplateWidget(
    template,
    CommonWidgetIds.DismissButton,
    WidgetType.Button
  );

  return { mainButton, triggerButton, dismissButton, mainContainer };
}

function formInitialValue(template: Template): FormConfig {
  const { mainButton, dismissButton } = getWidgets(template);

  const getButtonStyles = (button?: ButtonWidgetConfig) => {
    let buttonShape: ButtonShape = ButtonShape.Rounded;
    if (button?.widgetStyle?.borderRadius === 1000) {
      buttonShape = ButtonShape.Pill;
    } else if (button?.widgetStyle?.border?.width) {
      buttonShape = ButtonShape.Ghost;
    } else if (button?.widgetStyle?.borderRadius === 0) {
      buttonShape = ButtonShape.Square;
    }

    let buttonBackgroundColor = normalizeColor(button?.widgetStyle?.backgroundColor);
    if (buttonShape === ButtonShape.Ghost) {
      buttonBackgroundColor = normalizeColor(button?.widgetStyle?.border?.color);
    }

    return { buttonShape, buttonBackgroundColor };
  };

  const { buttonShape, buttonBackgroundColor } = getButtonStyles(mainButton);
  const {
    buttonShape: dismissButtonShape,
    buttonBackgroundColor: dismissButtonBackgroundColor,
  } = getButtonStyles(dismissButton);

  return {
    buttonFontFamily: mainButton?.widgetStyle?.firstRowStyle?.fontFamily ?? FontFamily.Arvo,
    buttonFontWeight: mainButton?.widgetStyle?.firstRowStyle?.fontWeight ?? FontWeight.w400,
    buttonBackgroundColor,
    buttonShape,
    dismissButtonFontFamily:
      dismissButton?.widgetStyle?.firstRowStyle?.fontFamily ?? FontFamily.Arvo,
    dismissButtonFontWeight:
      dismissButton?.widgetStyle?.firstRowStyle?.fontWeight ?? FontWeight.w400,
    dismissButtonBackgroundColor,
    dismissButtonShape,
  };
}

const saveFormValues = (template: Template, dispatch: Dispatch<any>) => (values: FormConfig) => {
  const { mainButton, triggerButton, dismissButton, mainContainer } = getWidgets(template);

  const getButtonStyle = (values: {
    buttonFontFamily: FontFamily;
    buttonFontWeight: FontWeight;
    buttonBackgroundColor: string;
    buttonShape: ButtonShape;
  }) => {
    let withBorder: WithBorder = {
      border: {
        width: 0,
        color: '',
      },
    };
    let withBorderRadius: WithBorderRadius = {
      borderRadius: template.widgetPresets.buttons.rounded.borderRadius,
    };
    let backgroundColor: string = values.buttonBackgroundColor;
    let color: string = contrastToHexColor(values.buttonBackgroundColor);

    switch (values.buttonShape) {
      case ButtonShape.Pill:
        withBorderRadius.borderRadius = 1000;
        break;
      case ButtonShape.Square:
        withBorderRadius.borderRadius = 0;
        break;
      case ButtonShape.Ghost:
        withBorder = {
          border: {
            width: template.widgetPresets.buttons.ghost.border.width,
            color: values.buttonBackgroundColor,
          },
        };
        withBorderRadius.borderRadius = template.widgetPresets.buttons.ghost.borderRadius;
        backgroundColor = mainContainer?.widgetStyle?.backgroundColor ?? '';
        color = values.buttonBackgroundColor;
        break;
    }

    return {
      border: withBorder.border,
      borderRadius: withBorderRadius.borderRadius,
      backgroundColor,
      firstRowStyle: {
        fontFamily: values.buttonFontFamily,
        fontWeight: values.buttonFontWeight,
        color,
      },
      secondRowStyle: {
        fontFamily: values.buttonFontFamily,
        fontWeight: values.buttonFontWeight,
        color,
      },
    };
  };

  if (mainButton) {
    dispatch(
      mergeWidgetConfig({
        ...mainButton,
        widgetStyle: {
          ...getButtonStyle(values),
        },
      })
    );
  }

  if (triggerButton) {
    dispatch(
      mergeWidgetConfig({
        ...triggerButton,
        widgetStyle: {
          ...getButtonStyle(values),
        },
      })
    );
  }

  if (dismissButton) {
    dispatch(
      mergeWidgetConfig({
        ...dismissButton,
        widgetStyle: {
          ...getButtonStyle({
            buttonFontFamily: values.buttonFontFamily,
            buttonFontWeight: values.dismissButtonFontWeight,
            buttonBackgroundColor: values.dismissButtonBackgroundColor,
            buttonShape: values.buttonShape,
          }),
        },
      })
    );
  }
};

export function useButtonsForm(template: Template): UseFormResult<FormConfig> {
  const dispatch = useDispatch();
  const initialValue = formInitialValue(template);

  return {
    initialValues: initialValue,
    saveFormValues: saveFormValues(template, dispatch),
    validateForm: validateForm,
    isValid: true,
  };
}
