import { CSSProperties } from 'react';
import {
  WithBackground,
  WithBackgroundImage,
  WithBorder,
  WithBorderRadius,
  WithColor,
  WithMargin,
  WithPadding,
  WithShadow,
} from '../types';

/**
 * Create CSS styles from common widget styles
 *
 * @param style Widget style
 */
export function baseWidgetStyleToCss(
  style?: WithMargin &
    WithPadding &
    WithBorder &
    WithShadow &
    WithBackground &
    WithBackgroundImage &
    WithBorderRadius &
    WithColor
): CSSProperties {
  return {
    maxWidth: '100%',
    width: '100%',
    marginTop: style?.margin?.top ?? 0,
    marginBottom: style?.margin?.bottom ?? 0,
    marginLeft: style?.margin?.left ?? 0,
    marginRight: style?.margin?.right ?? 0,
    paddingTop: style?.padding?.top ?? 0,
    paddingBottom: style?.padding?.bottom ?? 0,
    paddingLeft: style?.padding?.left ?? 0,
    paddingRight: style?.padding?.right ?? 0,
    borderStyle: style?.border?.width ? 'solid' : 'none',
    borderWidth: style?.border?.width ?? 0,
    borderColor: style?.border?.color ?? 'transparent',
    boxShadow:
      style?.shadow?.color && style.shadow.radius
        ? `0 0 ${style.shadow.radius}px ${style.shadow.color}`
        : undefined,
    backgroundColor: style?.backgroundColor ?? 'transparent',
    borderRadius: style?.borderRadius ?? 0,
    color: style?.color ?? 'black',
  };
}
