/**
 * Fix URL and return it. Append if needed to URL current fronted origin.
 *
 * For example:
 * ```
 * 'http://somesite.com/image.png' -> 'http://somesite.com/image.png'
 * '/image.png' -> 'https://CURRENT_FRONTEND_ORIGIN/image.png'
 * ```
 *
 * @param url Image URL
 */
export function fixImageUrl(url: string): string {
  const lowerCaseUrl = url.toLowerCase();

  if (lowerCaseUrl.startsWith('https://') || lowerCaseUrl.startsWith('http://')) {
    return url;
  }

  return document.location.origin + url;
}
