import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { loadTemplate, replaceTemplate } from '../../../state/actions';
import { selectConfigId } from '../../../state/selectors';
import { Template } from '../../../core/template';
import { Button, ComponentTheme } from '../../components';
import { ModalActionButtons, ModalDescription, Modal } from '../components';

interface ReplaceTemplateModalProps {
  template: Template;
  onRequestClose: () => void;
}

export function ReplaceTemplateModal(props: ReplaceTemplateModalProps) {
  const dispatch = useDispatch();
  const history = useHistory();

  const configId = useSelector(selectConfigId);

  const handleClear = () => {
    props.onRequestClose();
    dispatch(loadTemplate(props.template));
    history.push(`/${configId}/customizer`);
  };

  const handleKeep = () => {
    props.onRequestClose();
    dispatch(replaceTemplate(props.template));
    history.push(`/${configId}/customizer`);
  };

  return (
    <Modal title="Keep existing work?" isOpen={true} onRequestClose={props.onRequestClose}>
      <ModalDescription>
        Use the new theme and keep existing copy, brand colors, fonts, and settings?
      </ModalDescription>

      <ModalActionButtons>
        <Button onClick={handleClear} theme={ComponentTheme.Red}>
          Clear existing data
        </Button>
        <Button onClick={handleKeep}>Keep my settings</Button>
      </ModalActionButtons>
    </Modal>
  );
}
