import { useContext } from 'react';
import { CommonWidgetIds } from '../../template';
import { TemplateContext, TemplateEvent } from '../../TemplateContext';
import { ClickActionType } from '../definitions';
import { WithOnClick } from '../types';
import MobileDetect from 'mobile-detect';

/**
 * Result of {@see useOnClick}.
 *
 * Can contain only `href` or `handleClick` separately.
 */
type UseOnClickResult =
  | {
      /** Link that must be opened by click */
      href: string;
      /** On click handler */
      handleClick: undefined;
    }
  | {
      /** Link that must be opened by click */
      href: undefined;
      /** On click handler */
      handleClick: () => void;
    };

/**
 * Hook that return `onClick` handler based on `onClick` config and widget `id`.
 *
 * @param widget Widget config
 */
export function useOnClick(widget: WithOnClick & { id: string }): UseOnClickResult {
  const context = useContext(TemplateContext);

  let href = '';
  let handleClick = () => {};

  switch (widget.id) {
    case CommonWidgetIds.TriggerButton:
      handleClick = () => context.emmitEvent(TemplateEvent.TriggerButtonClick);
      break;
    case CommonWidgetIds.DismissButton:
      handleClick = () => context.emmitEvent(TemplateEvent.PopupDismissClick);
      break;
    default:
      switch (widget.onClick?.type) {
        case ClickActionType.ClosePopup:
          handleClick = () => context.emmitEvent(TemplateEvent.PopupDismissClick);
          break;
        case ClickActionType.SendMessage:
          if (context.useFakeSmsApplication) {
            handleClick = () => context.emmitEvent(TemplateEvent.OpenSmsApplicationClick);
          } else {
            const phone = widget.onClick?.phone?.replace(/[^\d]/g, '');
            const body = widget.onClick?.text
              ? `body=${widget.onClick.text.replace(/\s/g, '%20')}`
              : '';

            const mobileDetect = new MobileDetect(window.navigator.userAgent);
            if (mobileDetect.is('iOS') || mobileDetect.is('iPadOS')) {
              href = `sms:${phone}&${body}`;
            } else {
              href = `sms:${phone}?${body}`;
            }
          }
          break;
        case ClickActionType.OpenLink:
          href = widget.onClick?.url;
          break;
      }
  }

  if (href) {
    return { href, handleClick: undefined };
  } else {
    return { href: undefined, handleClick };
  }
}
