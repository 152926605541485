import { CSSProperties } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { selectEditPanelState } from '../../../state/selectors';
import { ColumnWidget } from '../release/ColumnWidget';
import { baseWidgetStyleToCss } from '../utils';
import { ColumnWidgetConfig } from '../types';
import { DebugWidget } from './DebugWidget';

/** Props for {@see DebugColumnWidget} */
interface DebugColumnWidgetProps extends ColumnWidgetConfig {
  /** Custom CSS styles */
  style?: CSSProperties;
  /** Custom CSS every children styles */
  childrenStyle?: CSSProperties;
}

/**
 * Implementation of column widget that used in development. Support children widget
 * drag and drops and custom CSS styles.
 */
export function DebugColumnWidget(props: DebugColumnWidgetProps) {
  if (props.id === undefined) {
    throw new Error('Every column must have id');
  }

  const isExpertMode = useSelector(selectEditPanelState).isExpertModeEnabled;

  if (isExpertMode) {
    return (
      <Droppable droppableId={props.id}>
        {(provided) => (
          <ul
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={{
              ...baseWidgetStyleToCss(props.widgetStyle),
              display: 'flex',
              flexDirection: 'column',
              margin: 0,
              padding: 0,
              listStyle: 'none',
              ...props.style,
            }}
          >
            {props.children.map((childProps, index) => {
              if (childProps.id === undefined) {
                throw new Error('In the column found widget without id');
              }
              return (
                <Draggable draggableId={childProps.id} index={index} key={childProps.id}>
                  {(droppableProvided) => (
                    <li
                      {...droppableProvided.draggableProps}
                      {...droppableProvided.dragHandleProps}
                      ref={droppableProvided.innerRef}
                    >
                      <DebugWidget {...childProps} style={props.childrenStyle} />
                    </li>
                  )}
                </Draggable>
              );
            })}

            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    );
  } else {
    return <ColumnWidget {...props} widgetRender={DebugWidget} children={props.children} />;
  }
}
