import { createSelector } from '@reduxjs/toolkit';
import { selectApp } from './selectApp';

/** Select current edited template */
export const selectTemplate = createSelector([selectApp], (state) => {
  if (!state.template) {
    throw new Error('Template is not loaded');
  }

  return state.template;
});
