import { useDispatch, useSelector } from 'react-redux';
import { Template } from '../../../../../../core/template';
import { HorizontalAlign, WidgetType } from '../../../../../../core/widgets/definitions';
import { setMainHorizontalPosition } from '../../../../../../state/actions';
import { selectTemplate } from '../../../../../../state/selectors';
import { AccordionSection, FormRow } from '../../../../../components';
import {
  BackgroundImageConfigSection,
  BackgroundColorConfig,
  BorderRadiusConfig,
  MarginConfigSection,
  PaddingConfigSection,
  ShadowConfig,
  StylingSection,
  PositionConfigSection,
  OverlayColorConfig,
} from '../../panel-components';
import { useWidgetConfig } from '../../hooks';

interface ContainerWidgetPanelProps {
  id: string;
  type?: string;
}

export function ContainerWidgetPanel(props: ContainerWidgetPanelProps) {
  const dispatch = useDispatch();

  const template = useSelector(selectTemplate);

  const [widget, handleChanges] = useWidgetConfig(props.id, WidgetType.Container);

  const handlePositionChange = (values: Partial<Template>) => {
    dispatch(setMainHorizontalPosition(values.horizontalPosition ?? HorizontalAlign.Right));
  };

  return (
    <AccordionSection id={props.id} title={props.type ?? 'Container'}>
      <BackgroundImageConfigSection widget={widget} onChange={handleChanges} />

      <PositionConfigSection template={template} onChange={handlePositionChange} />

      <StylingSection>
        <FormRow>
          <BackgroundColorConfig widget={widget} onChange={handleChanges} />
          <BorderRadiusConfig widget={widget} onChange={handleChanges} />
        </FormRow>
        <OverlayColorConfig widget={widget} onChange={handleChanges} />
        <ShadowConfig widget={widget} onChange={handleChanges} />
      </StylingSection>

      <MarginConfigSection widget={widget} onChange={handleChanges} />
      <PaddingConfigSection widget={widget} onChange={handleChanges} />
    </AccordionSection>
  );
}
