import firebase from 'firebase/app';
import 'firebase/firestore';
import { loadBackendState } from '../state/actions';
import { selectConfigId, selectContacts } from '../state/selectors';
import { store } from '../state/store';
import { AppState } from '../state/types';
import { debounce, pick, isEqual } from 'lodash';

/** Keys that will be synchronized from remote store to local */
const storePick: (keyof AppState)[] = [
  'contacts',
  'preview',
  'template',
  'isTemplateChanged',
  'savedTemplate',
];
/** Keys that will be synchronized from local store to remote */
const statePick: (keyof AppState)[] = [
  'contacts',
  'preview',
  'template',
  'isTemplateChanged',
  // savedTemplate not save. It will be saved by backend.
];

/**
 * Starts synchronization of local and remote stores.
 *
 * Remote store will be updated by any changes in local store with debounce in 1 sec.
 * Local store will be updated only once at start.
 *
 * From local store to remote will be saved only keys described in {@see statePick}.
 * From remote store to local will be saved only keys described in {@see storePick}.
 */
export function startBackendSync() {
  const db = firebase.firestore();

  let loaded = false;
  let loading = false;
  let lastBackendData = '';

  async function update() {
    const state = store.getState();
    const configId = selectConfigId(state);
    const stateToSave = pick(state.app, statePick);

    const stringStateToSave = JSON.stringify(stateToSave);
    if (lastBackendData === stringStateToSave) {
      return;
    }

    console.log('BackendSync.updateBackend', stateToSave);
    lastBackendData = stringStateToSave;

    const doc = db.collection('configs').doc(configId);
    if ((await doc.get()).exists) {
      return db.collection('configs').doc(configId).update(JSON.parse(stringStateToSave)); // Parse JSON because we need remove undefined fields.
    } else {
      return db.collection('configs').doc(configId).set(JSON.parse(stringStateToSave)); // Parse JSON because we need remove undefined fields.
    }
  }
  const debouncedUpdate = debounce(update, 1000);

  function updateStoreFromData(data?: AppState) {
    const dataToSave = pick(data, storePick);
    if (data && !isEqual(pick(store.getState().app, storePick), dataToSave)) {
      console.log('BackendSync.updateStore', dataToSave);

      lastBackendData = JSON.stringify(dataToSave);
      store.dispatch(loadBackendState(dataToSave));
    }
  }

  async function loadState(configId: string) {
    const snapshot = await db.collection('configs').doc(configId).get();
    const data = snapshot.data() as AppState;
    updateStoreFromData(data);
  }

  store.subscribe(async () => {
    const state = store.getState();
    const configId = selectConfigId(state);

    if (!configId) {
      return;
    }

    if (!loaded) {
      if (!loading) {
        try {
          loading = true;
          const contacts = selectContacts(state);
          if (!contacts.email) {
            await loadState(configId);
          } else {
            debouncedUpdate();
          }
          loaded = true;
        } finally {
          loading = false;
        }
      }
    } else {
      debouncedUpdate();
    }
  });
}
