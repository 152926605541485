import { Form, ColorFormField, normalizeColor } from '../../../../../form-components';
import { WidgetConfig, WithColor } from '../../../../../../core/widgets/types';
import { useStyleConfigHelpers } from '../../hooks/useStyleConfigHelpers';
import { WidgetStyleConfigPanelProps } from '../types';

interface WithColorConfig {
  color: string;
}

export function ColorConfig<T extends WidgetConfig>(
  props: WidgetStyleConfigPanelProps<WithColor, T> & { label?: string }
) {
  const [widgetStyle, onChange] = useStyleConfigHelpers(props);

  const initialValue: WithColorConfig = {
    color: normalizeColor(widgetStyle?.color),
  };

  const handleChange = (formValues: WithColorConfig) => {
    onChange({
      color: formValues.color || null,
    });
  };

  return (
    <Form initialValues={initialValue} onValuesChange={handleChange}>
      <ColorFormField name="color" label={props.label ?? 'Color'} />
    </Form>
  );
}
