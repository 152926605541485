import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';
import { AppState } from '../types';

/** Restore current edited template to last saved template */
export const revertTemplate = createAction<void>('revertTemplate');

/** Append reducer for {@see revertTemplate} action to Redux slice */
export const addRevertTemplateReducer = (builder: ActionReducerMapBuilder<AppState>) => {
  builder.addCase(revertTemplate, (state) => {
    if (state.savedTemplate) {
      state.template = state.savedTemplate;
      state.isTemplateChanged = false;
    }
  });
};
