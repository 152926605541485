import classNames from 'classnames';
import { useField } from 'formik';
import { ComponentTheme } from '../../components';
import styles from './SwitchFormField.module.scss';

export enum Size {
  Default,
  Mini,
}

interface SwitchFormFieldProps {
  name: string;
  label: string;
  theme?: ComponentTheme;
  size?: Size;
}

export function SwitchFormField(props: SwitchFormFieldProps) {
  const [field] = useField(props as any);

  return (
    <label
      className={classNames({
        [styles.switch]: true,
        [styles.transparentSwitch]: props.theme === ComponentTheme.Transparent,
      })}
    >
      <input
        {...field}
        checked={field.value}
        className={classNames({
          [styles.checkbox]: true,
          [styles.transparentCheckbox]: props.theme === ComponentTheme.Transparent,
          [styles.sizeMini]: props.size === Size.Mini,
        })}
        type="checkbox"
      />
      {props.label}
    </label>
  );
}
