import { DeepPartial } from '../../../../../types/deepPartial';
import { WidgetConfig } from '../../../../../core/widgets/types';
import { WidgetStyleConfigPanelProps } from '../panel-components/types';

export function useStyleConfigHelpers<
  T extends DeepPartial<WidgetConfig['widgetStyle']>,
  W extends WidgetConfig
>(props: WidgetStyleConfigPanelProps<T, W>): [T | undefined, (style: T) => void] {
  const style = props.styleFromWidgetConfig
    ? props.styleFromWidgetConfig(props.widget)
    : props.widget.widgetStyle;

  const styleToWidgetConfig = props.styleToWidgetConfig ?? ((style) => ({ widgetStyle: style }));
  const onChange = (style: T) => {
    props.onChange(styleToWidgetConfig(style));
  };

  return [style as T | undefined, onChange];
}
