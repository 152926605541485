/**
 * Application build configuration.
 *
 * Data for configuration stored in `.env` file.
 */
export class AppConfig {
  /** Backend URL without ended slash */
  static readonly backendUrl = process.env.REACT_APP_BACKEND_URL as string;
  /** Frontend URL without ended slash */
  static readonly frontendUrl = process.env.REACT_APP_FRONTEND_URL as string;
}

console.log('Run with config', {
  backendUrl: AppConfig.backendUrl,
  frontendUrl: AppConfig.frontendUrl,
});
