import {
  Form,
  InputFormField,
  InputType,
  ColorFormField,
  normalizeColor,
} from '../../../../../form-components';
import { WidgetConfig, WithBorder } from '../../../../../../core/widgets/types';
import { FormRow } from '../../../../../components/form-row/FormRow';
import { useStyleConfigHelpers } from '../../hooks/useStyleConfigHelpers';
import { WidgetStyleConfigPanelProps } from '../types';

interface FormConfig {
  borderColor: string;
  borderWidth: string;
}

export function BorderConfig<T extends WidgetConfig>(
  props: WidgetStyleConfigPanelProps<WithBorder, T>
) {
  const [widgetStyle, onChange] = useStyleConfigHelpers(props);

  const initialValue: FormConfig = {
    borderColor: normalizeColor(widgetStyle?.border?.color),
    borderWidth: widgetStyle?.border?.width?.toFixed(0) ?? '',
  };

  const handleChange = (formValues: FormConfig) => {
    onChange({
      border: {
        color: formValues.borderColor || undefined,
        width: formValues.borderWidth !== '' ? Number(formValues.borderWidth) : null,
      },
    });
  };

  return (
    <Form initialValues={initialValue} onValuesChange={handleChange}>
      <FormRow>
        <ColorFormField name="borderColor" label="Border color" />

        <InputFormField name="borderWidth" label="Border width" type={InputType.PositiveNumbers} />
      </FormRow>
    </Form>
  );
}
