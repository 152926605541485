import classNames from 'classnames';
import { useField } from 'formik';
import { useSelector } from 'react-redux';
import MaskedInput from 'react-text-mask';
import { DismissBehavior, FirstExperience } from '../../../../../../core/template';
import { SmsType } from '../../../../../../core/widgets/definitions';
import { selectTemplate } from '../../../../../../state/selectors';
import {
  AccordionSection,
  EditPanelDescription,
  EditPanelSection,
  FormRow,
  Tab,
  Tabs,
} from '../../../../../components';
import {
  Form,
  InputFormField,
  InputType,
  RadioButton,
  TextareaFormField,
} from '../../../../../form-components';
import styles from './UserEngagementPanel.module.scss';
import { useSmsForm } from './useSmsForm';
import { useUserEngagementForm } from './useUserEngagementForm';

const secMask = [/[1-9]/, /\d/];

function SetDelayOfLabel() {
  const [field, meta] = useField('delaySec');

  return (
    <div>
      Set delay of{' '}
      <MaskedInput
        {...field}
        mask={secMask}
        guide={false}
        className={classNames({
          [styles.delayInput]: true,
          [styles.delayInputHasError]: !!meta.error,
        })}
        placeholder="0"
      />{' '}
      sec
    </div>
  );
}

export function UserEngagementPanel() {
  const template = useSelector(selectTemplate);

  const userEngagementForm = useUserEngagementForm(template);
  const smsForm = useSmsForm(template);

  return (
    <AccordionSection
      id="userEngagement"
      title="Behavior"
      isHasError={!userEngagementForm.isValid || !smsForm.isValid}
    >
      <Tabs
        tabs={[
          { label: 'Behavior', id: 'behavior', hasError: !userEngagementForm.isValid },
          { label: 'SMS', id: 'sms', hasError: !smsForm.isValid },
        ]}
      >
        <Tab id="behavior">
          <Form
            initialValues={userEngagementForm.initialValues}
            onValuesChange={userEngagementForm.saveFormValues}
            validate={userEngagementForm.validateForm}
          >
            <EditPanelSection title="First Experience">
              <RadioButton
                label="Open popup"
                value={FirstExperience.ShowPopUp}
                name="firstExperience"
              />
              <RadioButton
                label="Show trigger button"
                value={FirstExperience.TriggerButton}
                name="firstExperience"
              />
            </EditPanelSection>

            <EditPanelSection title="Timer">
              <RadioButton label="Show immediately on page load" value="immediately" name="timer" />
              <RadioButton label={<SetDelayOfLabel />} value="delay" name="timer" />
            </EditPanelSection>

            <EditPanelSection title="Dismiss behavior">
              <RadioButton
                label="Show trigger button"
                value={DismissBehavior.ShowTriggerButton}
                name="dismissBehavior"
              />
              <RadioButton
                label="Close all"
                value={DismissBehavior.CloseAll}
                name="dismissBehavior"
              />
            </EditPanelSection>
          </Form>
        </Tab>

        <Tab id="sms">
          <Form
            initialValues={smsForm.initialValues}
            onValuesChange={smsForm.saveFormValues}
            validate={smsForm.validateForm}
          >
            <EditPanelDescription>
              Pre-populate the text message to your number
            </EditPanelDescription>
            <FormRow>
              <InputFormField
                name="phone"
                label="Texting phone number"
                type={InputType.PhoneNumber}
              />
            </FormRow>

            <EditPanelSection title="Message type">
              <RadioButton name="smsType" label="Keyword" value={SmsType.Keyword} maxWidth={292}>
                <FormRow>
                  <InputFormField
                    name="message"
                    label="Keyword"
                    type={InputType.Keyword}
                    helperText={
                      <div className={styles.helperText}>
                        Don't have a keyword?{' '}
                        <a
                          className={styles.link}
                          href="https://help.simpletexting.com/en/articles/1071268-setting-up-a-keyword"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Learn more here.
                        </a>
                      </div>
                    }
                  />
                </FormRow>
              </RadioButton>

              <RadioButton name="smsType" label="Text message" value={SmsType.Text} maxWidth={292}>
                <FormRow>
                  <TextareaFormField
                    name="message"
                    label="Text message"
                    rows={4}
                    helperText={
                      <div className={styles.helperText}>
                        Want your contacts to be added to a list? Use a keyword.{' '}
                        <a
                          className={styles.link}
                          href="https://help.simpletexting.com/en/articles/1071268-setting-up-a-keyword"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Learn more here.
                        </a>
                      </div>
                    }
                  />
                </FormRow>
                <div className={styles.twoLinePadding} />
              </RadioButton>
            </EditPanelSection>
          </Form>
        </Tab>
      </Tabs>
    </AccordionSection>
  );
}
