import { Button, ComponentTheme } from '../../components';
import { Modal, ModalDescription } from '../components';
import image from './image.png';
import styles from './PublishedModal.module.scss';

interface PublishModalProps {
  onRequestClose: () => void;
}

export function PublishedModal(props: PublishModalProps) {
  return (
    <Modal isOpen={true} onRequestClose={props.onRequestClose} width={500}>
      <section className={styles.content}>
        <img className={styles.image} src={image} alt="Publish" />
        <h1 className={styles.title}>Your widget looks great! Nice job.</h1>

        <div className={styles.description}>
          <ModalDescription>
            There's just one more step. We emailed you instructions for how to embed it on your
            website.
          </ModalDescription>
        </div>

        <a
          className={styles.link}
          href="https://help.simpletexting.com/en/articles/3781664-install-your-mobile-sign-up-widget"
          target="_blank"
          rel="noreferrer"
        >
          Learn more
        </a>

        <div className={styles.button}>
          <Button theme={ComponentTheme.Gray} onClick={props.onRequestClose}>
            Close
          </Button>
        </div>
      </section>
    </Modal>
  );
}
