import { Dispatch } from 'react';
import { useDispatch } from 'react-redux';
import { findTemplateWidget } from '../../../../../../core/utils';
import { mergeWidgetConfig } from '../../../../../../state/actions';
import { CommonWidgetIds, Template } from '../../../../../../core/template';
import { WidgetType } from '../../../../../../core/widgets/definitions';
import { UseFormResult } from '../../types';

interface FormConfig {
  triggerButtonText: string;
  mainButtonPrimaryText: string;
  mainButtonSecondLineText: string;
  dismissButtonPrimaryText: string;
  dismissButtonSecondLineText: string;
}

function validateForm(values: FormConfig) {
  const errors: { [name in keyof FormConfig]?: string } = {};

  return errors;
}

function getWidgets(template: Template) {
  const mainButton = findTemplateWidget(template, CommonWidgetIds.MainButton, WidgetType.Button);
  const dismissButton = findTemplateWidget(
    template,
    CommonWidgetIds.DismissButton,
    WidgetType.Button
  );
  const triggerButton = findTemplateWidget(
    template,
    CommonWidgetIds.TriggerButton,
    WidgetType.Button
  );

  return { mainButton, triggerButton, dismissButton };
}

function formInitialValue(template: Template): FormConfig {
  const { mainButton, triggerButton, dismissButton } = getWidgets(template);

  return {
    triggerButtonText:
      (Array.isArray(triggerButton?.text) ? triggerButton?.text[0] : triggerButton?.text) ?? '',
    mainButtonPrimaryText:
      (Array.isArray(mainButton?.text) ? mainButton?.text[0] : mainButton?.text) ?? '',
    mainButtonSecondLineText: (Array.isArray(mainButton?.text) ? mainButton?.text[1] : '') ?? '',
    dismissButtonPrimaryText:
      (Array.isArray(dismissButton?.text) ? dismissButton?.text[0] : dismissButton?.text) ?? '',
    dismissButtonSecondLineText:
      (Array.isArray(dismissButton?.text) ? dismissButton?.text[1] : '') ?? '',
  };
}

const saveFormValues = (template: Template, dispatch: Dispatch<any>) => (values: FormConfig) => {
  const { mainButton, triggerButton, dismissButton } = getWidgets(template);

  if (mainButton) {
    dispatch(
      mergeWidgetConfig({
        ...mainButton,
        text: values.mainButtonSecondLineText
          ? [values.mainButtonPrimaryText, values.mainButtonSecondLineText]
          : values.mainButtonPrimaryText,
        widgetStyle: {
          padding: values.mainButtonSecondLineText
            ? template.widgetPresets.buttons.doubleLine.padding
            : template.widgetPresets.buttons.singleLine.padding,
        },
      })
    );
  }

  if (dismissButton) {
    dispatch(
      mergeWidgetConfig({
        ...dismissButton,
        text: values.dismissButtonSecondLineText
          ? [values.dismissButtonPrimaryText, values.dismissButtonSecondLineText]
          : values.dismissButtonPrimaryText,
        widgetStyle: {
          padding: values.dismissButtonSecondLineText
            ? template.widgetPresets.buttons.doubleLine.padding
            : template.widgetPresets.buttons.singleLine.padding,
        },
      })
    );
  }

  if (triggerButton) {
    dispatch(
      mergeWidgetConfig({
        ...triggerButton,
        text: values.triggerButtonText,
        widgetStyle: {
          padding: template.widgetPresets.buttons.singleLine.padding,
        },
      })
    );
  }
};

export function useButtonsForm(template: Template): UseFormResult<FormConfig> {
  const dispatch = useDispatch();
  const initialValue = formInitialValue(template);

  return {
    initialValues: initialValue,
    saveFormValues: saveFormValues(template, dispatch),
    validateForm: validateForm,
    isValid: true,
  };
}
