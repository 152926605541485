import {
  ClickActionType,
  FontFamily,
  FontWeight,
  HorizontalAlign,
  ImageFit,
  PoliciesLinkType,
  SmsType,
  WidgetType,
} from '../core/widgets/definitions';
import {
  CommonWidgetIds,
  DismissBehavior,
  FirstExperience,
  Template,
  TemplateTag,
  TimerType,
} from '../core/template';

export const sophisticated: Template = {
  userEngagement: {
    timer: {
      type: TimerType.Immediately,
    },
    firstExperience: FirstExperience.TriggerButton,
    dismissBehavior: DismissBehavior.ShowTriggerButton,
  },
  info: {
    tags: [TemplateTag.FullSize, TemplateTag.FeatureImage],
    previewImageUrl: '/assets/themes/sophisticated.png',
    name: 'Sophisticated',
  },
  triggerButtonWidget: {
    widgetStyle: {
      backgroundColor: '#0D294A',
      margin: {
        top: 12,
        bottom: 12,
        right: 12,
        left: 12,
      },
      padding: {
        bottom: 13,
        left: 16,
        right: 16,
        top: 13,
      },
      isExpanded: true,
      firstRowStyle: {
        fontFamily: FontFamily.Lato,
        fontSize: 16,
        fontWeight: FontWeight.w400,
        color: '#FFF',
      },
      borderRadius: 0,
      secondRowStyle: {
        fontWeight: FontWeight.w400,
        fontSize: 10,
        color: '#FFF',
        fontFamily: FontFamily.Lato,
      },
      border: {
        color: '',
        width: 0,
      },
    },
    text: 'Get 10% Off',
  },
  popupCloseButtonWidget: {
    widgetStyle: {
      color: 'rgba(0, 27, 72, 0.31)',
      padding: {
        bottom: 8,
        right: 8,
        top: 8,
        left: 8,
      },
      size: 20,
    },
  },
  triggerButtonCloseButtonWidget: {
    widgetStyle: {
      shadow: {
        radius: 8,
        color: '#00000029',
      },
      borderRadius: 20,
      size: 14,
      color: 'rgba(0, 27, 72, 0.31)',
      backgroundColor: '#FFFFFF',
      padding: {
        top: 4,
        left: 4,
        right: 4,
        bottom: 4,
      },
      margin: {
        right: -8,
        left: -8,
        top: -8,
      },
    },
  },
  horizontalPosition: HorizontalAlign.Right,
  widgets: [
    {
      localUrl: null,
      fileName: 'PoppyBarley2.jpg',
      type: WidgetType.Image,
      id: CommonWidgetIds.HeroImage,
      url:
        'https://firebasestorage.googleapis.com/v0/b/simple-texting-tools.appspot.com/o/configs%2FKUMdo5WpGthpguWY7B5Zc%2Fimages%2Flt-_XR8xrWk_GLGawcHT1.png?alt=media',
      widgetStyle: {
        fit: ImageFit.Contain,
        align: HorizontalAlign.Center,
        margin: {
          bottom: 32,
        },
      },
    },
    {
      id: CommonWidgetIds.Headline,
      text: 'Fall Clearance',
      widgetStyle: {
        fontSize: 28,
        color: '#727171',
        textAlign: HorizontalAlign.Left,
        fontWeight: FontWeight.w500,
        margin: {
          bottom: 8,
          top: 8,
        },
        fontFamily: FontFamily.Lora,
      },
      type: WidgetType.Text,
    },
    {
      widgetStyle: {
        fontSize: 13,
        fontFamily: FontFamily.Lato,
        margin: {
          bottom: 32,
        },
        textAlign: HorizontalAlign.Left,
        fontWeight: FontWeight.w400,
        color: '#878484',
      },
      text:
        "It's coming. That once a year occaision when all Poppy Barley totes are on sale. Sign up now for an early invite.",
      id: CommonWidgetIds.SubHeadline,
      type: WidgetType.Text,
    },
    {
      widgetStyle: {
        isExpanded: true,
        padding: {
          left: 16,
          right: 16,
          top: 12,
          bottom: 12,
        },
        border: {
          width: 0,
          color: '',
        },
        firstRowStyle: {
          fontWeight: FontWeight.w400,
          color: '#FFF',
          fontSize: 16,
          fontFamily: FontFamily.Lato,
        },
        secondRowStyle: {
          fontFamily: FontFamily.Lato,
          color: '#FFF',
          fontSize: 10,
          fontWeight: FontWeight.w400,
        },
        borderRadius: 0,
        margin: {
          bottom: 24,
        },
        backgroundColor: '#0D294A',
      },
      type: WidgetType.Button,
      onClick: {
        phone: '(844) 928-4252',
        smsType: SmsType.Keyword,
        text: '',
        type: ClickActionType.SendMessage,
      },
      id: CommonWidgetIds.MainButton,
      text: ['Invite me', 'To the latest news by text'],
    },
    {
      type: WidgetType.LegalText,
      widgetStyle: {
        fontSize: 9,
        margin: {
          bottom: 16,
        },
        textAlign: HorizontalAlign.Center,
        fontFamily: FontFamily.Rubik,
        color: '#75849C',
        fontWeight: FontWeight.w400,
      },
      smsRate: 4,
      id: CommonWidgetIds.LegalText,
    },
    {
      link: {
        type: PoliciesLinkType.AutoGenerated,
        complianceEmail: 'stephen+poppy@simpletexting.net',
        companyName: 'Poppy Barley',
      },
      widgetStyle: {
        color: '#75849C',
        fontFamily: FontFamily.Rubik,
        fontWeight: FontWeight.w400,
        textAlign: HorizontalAlign.Center,
        fontSize: 9,
      },
      type: WidgetType.PoliciesText,
      id: CommonWidgetIds.PoliciesText,
    },
    {
      id: CommonWidgetIds.SimpleTextingCopyright,
      widgetStyle: {
        fontSize: 13,
        fontFamily: FontFamily.Rubik,
        textAlign: HorizontalAlign.Center,
        color: '#75849C',
        fontWeight: FontWeight.w400,
        margin: {
          top: 16,
        },
      },
      hidden: false,
      type: WidgetType.SimpleTextingCopyrightText,
    },
  ],
  widgetPresets: {
    buttons: {
      rounded: {
        borderRadius: 4,
      },
      ghost: {
        border: {
          width: 1,
        },
        borderRadius: 0,
      },
      singleLine: {
        padding: {
          bottom: 13,
          top: 13,
          left: 16,
          right: 16,
        },
      },
      doubleLine: {
        padding: {
          bottom: 8,
          top: 8,
          right: 16,
          left: 16,
        },
      },
    },
    popup: {
      overlay: {
        color: '#33333320',
        blurRadius: 2,
      },
    },
  },
  mainContainerWidget: {
    widgetStyle: {
      shadow: {
        radius: 40,
        color: '#00000029',
      },
      backgroundColor: '#FFf',
      padding: {
        top: 32,
        bottom: 32,
        left: 32,
        right: 32,
      },
      margin: {
        top: 12,
        bottom: 12,
        left: 12,
        right: 12,
      },
      borderRadius: 12,
    },
  },
};
