import classNames from 'classnames';
import styles from './SmsApplication.module.scss';

interface SmsApplicationProps {
  phone: string;
  message: string;
  onBackClick: () => void;
}

export function SmsApplication(props: SmsApplicationProps) {
  const firstRow = ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'];
  const secondRow = ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'];
  const thirdRow = ['Z', 'X', 'C', 'V', 'B', 'N', 'M'];

  return (
    <div className={styles.smsApplication}>
      <div className={styles.phone}>
        <button className={styles.backButton} onClick={props.onBackClick}>
          arrow_back
        </button>
        {props.phone}
      </div>

      <div className={styles.content}>
        <div className={styles.messageFrom} />
        <div className={styles.messageTo} />
      </div>

      <div className={styles.message}>{props.message}</div>

      <div className={styles.keyword}>
        <div className={styles.row}>
          {firstRow.map((key) => (
            <div className={styles.key}>{key}</div>
          ))}
        </div>
        <div className={classNames(styles.row, styles.secondRow)}>
          {secondRow.map((key) => (
            <div className={styles.key}>{key}</div>
          ))}
        </div>
        <div className={styles.row}>
          <div className={styles.wideKey} />
          {thirdRow.map((key) => (
            <div className={styles.key}>{key}</div>
          ))}
          <div className={styles.wideKey} />
        </div>
        <div className={styles.row}>
          <div className={styles.wideKey} />
          <div className={styles.wideKey} />
          <div className={styles.space} />
          <div className={styles.extraWideKey} />
        </div>
      </div>
    </div>
  );
}
