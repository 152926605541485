import classNames from 'classnames';
import React, { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { loadTemplate } from '../../../state/actions';
import {
  selectConfigId,
  selectIsTemplateLoaded,
  selectTemplateName,
} from '../../../state/selectors';
import { Template, TemplateTag } from '../../../core/template';
import { templates } from '../../../templates/templates';
import { Button, DeviceFrame } from '../../components';
import { ReplaceTemplateModal, TwoTapsToTextModal } from '../../modals';
import styles from './GalleryPage.module.scss';
import { intersection } from 'lodash';

// const tags: { value: TemplateTag; label: string }[] = [
//   { value: TemplateTag.FullSize, label: 'Full size' },
//   { value: TemplateTag.Mini, label: 'Mini' },
//   { value: TemplateTag.FeatureImage, label: 'Feature image' },
//   { value: TemplateTag.BackgroundImage, label: 'Background image' },
//   { value: TemplateTag.OfferFocused, label: 'Offer focused' },
//   { value: TemplateTag.TextOnly, label: 'Text only' },
//   { value: TemplateTag.SquareLogo, label: 'Square logo' },
// ];

export function GalleryPage() {
  const dispatch = useDispatch();
  const configId = useSelector(selectConfigId);
  const history = useHistory();
  const isTemplateLoaded = useSelector(selectIsTemplateLoaded);
  const templateName = useSelector(selectTemplateName);

  const [checkedTabs] = useState<TemplateTag[]>([]);
  const [templateToChange, setTemplateToChange] = useState<Template | undefined>();

  const handleTemplateClick = (template: Template) => () => {
    if (!isTemplateLoaded) {
      dispatch(loadTemplate(template));
      history.push(`/${configId}/customizer`);
    } else {
      setTemplateToChange(template);
    }
  };

  // const handleTagClick = (tag: TemplateTag) => () => {
  //   if (checkedTabs.includes(tag)) {
  //     setCheckedTabs(checkedTabs.filter((element) => element !== tag));
  //   } else {
  //     setCheckedTabs([...checkedTabs, tag]);
  //   }
  // };

  const filteredTemplates =
    checkedTabs.length === 0
      ? templates
      : templates.filter((template) => {
          return intersection(template.info.tags, checkedTabs).length > 0;
        });

  return (
    <div className={styles.gallery}>
      <PerfectScrollbar>
        {/*<div className={styles.filterBy}>*/}
        {/*  Filter by*/}
        {/*  <ul className={styles.tags}>*/}
        {/*    {tags.map((tag) => (*/}
        {/*      <li key={tag.value}>*/}
        {/*        <button*/}
        {/*          className={classNames({*/}
        {/*            [styles.tag]: true,*/}
        {/*            [styles.tagChecked]: checkedTabs.includes(tag.value),*/}
        {/*          })}*/}
        {/*          onClick={handleTagClick(tag.value)}*/}
        {/*        >*/}
        {/*          {tag.label}*/}
        {/*        </button>*/}
        {/*      </li>*/}
        {/*    ))}*/}
        {/*  </ul>*/}
        {/*</div>*/}

        <ul className={styles.templates}>
          {filteredTemplates.map((template, index) => (
            <li key={index} className={styles.template}>
              <div className={styles.deviceContainer}>
                <DeviceFrame
                  url="simpletexting.com"
                  screenHover={
                    <div className={styles.selectButtonContainer}>
                      <div>
                        <Button onClick={handleTemplateClick(template)}>Select template</Button>
                      </div>
                    </div>
                  }
                >
                  <div className={styles.screen}>
                    <img
                      className={styles.templateImage}
                      src={template.info.previewImageUrl}
                      alt="Template preview"
                    />
                  </div>
                </DeviceFrame>
              </div>

              <div
                className={classNames({
                  [styles.templateName]: true,
                  [styles.currentTemplate]: templateName === template.info.name,
                })}
              >
                {template.info.name}
              </div>
            </li>
          ))}
        </ul>
      </PerfectScrollbar>

      {templateToChange && (
        <ReplaceTemplateModal
          template={templateToChange}
          onRequestClose={() => setTemplateToChange(undefined)}
        />
      )}
      {!configId && <TwoTapsToTextModal />}
    </div>
  );
}
