import { CSSProperties, useContext, useEffect, useRef, useState } from 'react';
import { Transition } from 'react-transition-group';
import { DismissBehavior, FirstExperience, TimerType } from '../../template';
import { TemplateContext, TemplateEvent } from '../../TemplateContext';
import { HorizontalAlign } from '../../widgets/definitions';
import { PopupContainerProps } from '../TemplateRender';

/** State of template */
enum State {
  /** Template is forever hidden */
  Hidden,
  /** Trigger button is displayed */
  TriggerButtonVisible,
  /** Popup is displayed */
  PopupVisible,
}

const transitionStyles: { [status: string]: CSSProperties } = {
  entering: { bottom: 0, opacity: 1 },
  entered: { bottom: 0, opacity: 1 },
  exiting: { bottom: '-100%', opacity: 0 },
  exited: { bottom: '-100%', opacity: 0 },
};

/** Popup container for production use. Contains show/hide transitions and other actions */
export function ReleasePopupContainer(props: PopupContainerProps): JSX.Element | null {
  const context = useContext(TemplateContext);

  const [state, setState] = useState(State.Hidden);

  const { dismissBehavior, firstExperience, timer } = props.template.userEngagement;

  const popupTransitionRef = useRef<HTMLDivElement>(null);
  const triggerButtonTransitionRef = useRef<HTMLDivElement>(null);

  const handleOverlayClick = () => {
    if (state === State.PopupVisible) {
      context.emmitEvent(TemplateEvent.PopupDismissClick);
    }
  };

  useEffect(() => {
    switch (props.lastEvent) {
      case TemplateEvent.PopupDismissClick: {
        switch (dismissBehavior) {
          case DismissBehavior.CloseAll:
            setState(State.Hidden);
            break;
          case DismissBehavior.ShowTriggerButton:
            setState(State.TriggerButtonVisible);
            break;
        }
        break;
      }
      case TemplateEvent.TriggerButtonClick: {
        setState(State.PopupVisible);
        break;
      }
      case TemplateEvent.TriggerButtonRemoveClick: {
        setState(State.Hidden);
        break;
      }
    }
  }, [dismissBehavior, props.lastEvent]);

  const isFirstShow = useRef(true);
  useEffect(() => {
    if (isFirstShow.current) {
      isFirstShow.current = false;

      const show = () => {
        switch (firstExperience) {
          case FirstExperience.ShowPopUp:
            setState(State.PopupVisible);
            break;
          case FirstExperience.TriggerButton:
            setState(State.TriggerButtonVisible);
            break;
        }
      };

      switch (timer.type) {
        case TimerType.Immediately:
          show();
          break;
        case TimerType.Delay:
          setTimeout(show, timer.delaySec * 1000);
      }
    }
  }, [firstExperience, timer]);

  if (state === State.Hidden) {
    return null;
  }

  let alignItems: CSSProperties['alignItems'] = 'flex-end';
  switch (props.template.horizontalPosition) {
    case HorizontalAlign.Left:
      alignItems = 'flex-start';
      break;
    case HorizontalAlign.Center:
      alignItems = 'center';
      break;
    case HorizontalAlign.Right:
      alignItems = 'flex-end';
      break;
  }

  return (
    <div
      className="st-tools"
      style={{
        position: props.fitToDiv ? 'absolute' : 'fixed',
        right: 0,
        bottom: 0,
        left: 0,
        top: 0,
        pointerEvents: state === State.PopupVisible ? 'all' : 'none', // For scroll working
        zIndex: props.fitToDiv ? undefined : 99999,
        transition: 'background-color 200ms ease-in-out',
        backgroundColor:
          state === State.PopupVisible
            ? props.template.mainContainerWidget.widgetStyle?.overlay?.color ?? 'transparent'
            : 'transparent',
        backdropFilter:
          state === State.PopupVisible &&
          props.template.mainContainerWidget.widgetStyle?.overlay?.blurRadius
            ? `blur(${props.template.mainContainerWidget.widgetStyle.overlay.blurRadius}px)`
            : undefined,
      }}
    >
      {state === State.PopupVisible && (
        <div
          style={{
            position: props.fitToDiv ? 'absolute' : 'fixed',
            right: 0,
            bottom: 0,
            left: 0,
            top: 0,
          }}
          onClick={handleOverlayClick}
        />
      )}

      <div
        style={{
          position: 'absolute',
          top: props.template.mainContainerWidget.widgetStyle?.margin?.top ?? 0,
          right: props.template.mainContainerWidget.widgetStyle?.margin?.right ?? 0,
          bottom: props.template.mainContainerWidget.widgetStyle?.margin?.bottom ?? 0,
          left: props.template.mainContainerWidget.widgetStyle?.margin?.left ?? 0,
          pointerEvents: 'none',
          display: 'flex',
          flexDirection: 'column',
          alignItems,
        }}
      >
        <Transition nodeRef={popupTransitionRef} in={state === State.PopupVisible} timeout={300}>
          {(transitionState) => (
            <div
              ref={popupTransitionRef}
              style={{
                ...transitionStyles[transitionState],
                position: 'absolute',
                transition: 'all 300ms cubic-bezier(0.120, 0.900, 0.300, 0.900)',
                maxWidth: '100%',
                width: 'max-content',
                maxHeight: '100%',
                height: 'max-content',
                pointerEvents: 'all',
              }}
            >
              {transitionState !== 'exited' && props.popup}
            </div>
          )}
        </Transition>
      </div>

      <div
        style={{
          position: 'absolute',
          top: props.template.triggerButtonWidget.widgetStyle?.margin?.top ?? 0,
          right: props.template.triggerButtonWidget.widgetStyle?.margin?.right ?? 0,
          bottom: props.template.triggerButtonWidget.widgetStyle?.margin?.bottom ?? 0,
          left: props.template.triggerButtonWidget.widgetStyle?.margin?.left ?? 0,
          pointerEvents: 'none',
          display: 'flex',
          flexDirection: 'column',
          alignItems,
        }}
      >
        <Transition
          nodeRef={triggerButtonTransitionRef}
          in={state === State.TriggerButtonVisible}
          timeout={300}
        >
          {(transitionState) => (
            <div
              ref={triggerButtonTransitionRef}
              style={{
                ...transitionStyles[transitionState],
                position: 'absolute',
                transition: 'all 300ms cubic-bezier(0.120, 0.900, 0.300, 0.900)',
                maxWidth: '100%',
                width: 'max-content',
                maxHeight: '100%',
                height: 'max-content',
                pointerEvents: 'all',
              }}
            >
              {transitionState !== 'exited' && props.triggerButton}
            </div>
          )}
        </Transition>
      </div>
    </div>
  );
}
