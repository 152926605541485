import { ReactNode } from 'react';
import styles from './EditPanelSection.module.scss';

interface SectionProps {
  title: string;
  children: ReactNode;
}

export function EditPanelSection(props: SectionProps) {
  return (
    <section className={styles.section}>
      <h1 className={styles.title}>{props.title}</h1>

      {props.children}
    </section>
  );
}
