import { CommonWidgetIds, Template } from '../template';
import { WidgetType } from '../widgets/definitions';
import { WidgetConfig } from '../widgets/types';

/**
 * Returns widget with `id` from `template`. Also check that widget has valid `type`.
 *
 * @param template Template
 * @param id ID of widget
 * @param type Type of widget
 */
export function findTemplateWidget<T extends WidgetType>(
  template: Template,
  id: CommonWidgetIds,
  type: T
): (WidgetConfig & { type: T }) | undefined {
  let result: (WidgetConfig & { type: T }) | undefined;
  switch (id) {
    case CommonWidgetIds.TriggerButton:
      result = {
        id: CommonWidgetIds.TriggerButton,
        type: WidgetType.Button,
        ...template.triggerButtonWidget,
      } as any;
      break;
    case CommonWidgetIds.MainContainer:
      result = {
        id: CommonWidgetIds.MainContainer,
        type: WidgetType.Container,
        ...template.mainContainerWidget,
      } as any;
      break;
    case CommonWidgetIds.PopupCloseButton:
      result = {
        id: CommonWidgetIds.PopupCloseButton,
        type: WidgetType.IconButton,
        ...template.popupCloseButtonWidget,
      } as any;
      break;
    default:
      result = template.widgets.find((widget) => widget.id === id) as any;
      break;
  }

  if (result && result.type !== type) {
    throw new Error(`Widget ${result.id} is not ${type}`);
  }

  return result;
}
