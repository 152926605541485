import { useSelector } from 'react-redux';
import { selectContacts } from '../../../../../../state/selectors';
import { FormRow, AccordionSection } from '../../../../../components';
import { Form, InputFormField } from '../../../../../form-components';
import { useForm } from './useForm';

export function ContactSettingsPanel() {
  const contacts = useSelector(selectContacts);

  const form = useForm(contacts);

  return (
    <AccordionSection title="Contact settings" id="contacts" isHasError={!form.isValid}>
      <Form
        initialValues={form.initialValues}
        onValuesChange={form.saveFormValues}
        validate={form.validateForm}
      >
        <FormRow>
          <InputFormField name="companyName" label="Company name" helperText="Your company name" />
        </FormRow>
        <FormRow>
          <InputFormField name="email" label="Email" helperText="Your email" />
        </FormRow>
      </Form>
    </AccordionSection>
  );
}
