import classNames from 'classnames';
import { useField } from 'formik';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import { ComponentTheme } from '../../components';
import { HorizontalAlign } from '../../../core/widgets/definitions';
import styles from './HorizontalAlignFormField.module.scss';
import { FormField } from '../form-field/FormField';

interface AlignFormFieldProps {
  name: string;
  label: string;
  theme?: ComponentTheme;
  helperText?: string;
}

export function HorizontalAlignFormField(props: AlignFormFieldProps) {
  const [field, meta, helpers] = useField(props as any);

  return (
    <div className={styles.horizontalAlignFormField}>
      <FormField
        label={props.label}
        theme={props.theme}
        error={meta.error}
        helperText={props.helperText}
        wrapToLabel={false}
      >
        <div className={styles.alignButtons}>
          <button
            data-tip="Align left"
            className={classNames({
              [styles.alignButton]: true,
              [styles.alignLeft]: true,
              [styles.selected]: field.value === HorizontalAlign.Left,
            })}
            onClick={() => helpers.setValue(HorizontalAlign.Left)}
          />
          <button
            data-tip="Align center"
            className={classNames({
              [styles.alignButton]: true,
              [styles.alignCenter]: true,
              [styles.selected]: field.value === HorizontalAlign.Center,
            })}
            onClick={() => helpers.setValue(HorizontalAlign.Center)}
          />
          <button
            data-tip="Align right"
            className={classNames({
              [styles.alignButton]: true,
              [styles.alignRight]: true,
              [styles.selected]: field.value === HorizontalAlign.Right,
            })}
            onClick={() => helpers.setValue(HorizontalAlign.Right)}
          />
        </div>

        <ReactTooltip place="bottom" className="st-tolltip-basic" effect="solid" delayShow={600} />
      </FormField>
    </div>
  );
}
