import {
  ClickActionType,
  FontFamily,
  FontWeight,
  HorizontalAlign,
  PoliciesLinkType,
  SmsType,
  WidgetType,
} from '../core/widgets/definitions';
import {
  CommonWidgetIds,
  DismissBehavior,
  FirstExperience,
  Template,
  TemplateTag,
  TimerType,
} from '../core/template';

export const bold: Template = {
  widgetPresets: {
    buttons: {
      rounded: {
        borderRadius: 4,
      },
      ghost: {
        borderRadius: 0,
        border: {
          width: 1,
        },
      },
      singleLine: {
        padding: {
          right: 16,
          left: 16,
          top: 13,
          bottom: 13,
        },
      },
      doubleLine: {
        padding: {
          left: 16,
          right: 16,
          bottom: 8,
          top: 8,
        },
      },
    },
    popup: {
      overlay: {
        color: '#33333320',
        blurRadius: 2,
      },
    },
  },
  horizontalPosition: HorizontalAlign.Right,
  triggerButtonWidget: {
    text: 'Get 15% off',
    widgetStyle: {
      margin: {
        top: 12,
        bottom: 12,
        right: 12,
        left: 12,
      },
      border: {
        color: '',
        width: 0,
      },
      secondRowStyle: {
        fontWeight: FontWeight.w600,
        fontFamily: FontFamily.Poppins,
        fontSize: 10,
        color: '#FFF',
      },
      padding: {
        top: 13,
        bottom: 13,
        left: 16,
        right: 16,
      },
      borderRadius: 4,
      isExpanded: true,
      backgroundColor: '#FA5715',
      firstRowStyle: {
        fontFamily: FontFamily.Poppins,
        color: '#FFF',
        fontWeight: FontWeight.w600,
        fontSize: 16,
      },
    },
  },
  triggerButtonCloseButtonWidget: {
    widgetStyle: {
      padding: {
        bottom: 4,
        top: 4,
        right: 4,
        left: 4,
      },
      color: 'rgba(0, 27, 72, 0.31)',
      size: 14,
      margin: {
        right: -8,
        left: -8,
        top: -8,
      },
      borderRadius: 20,
      shadow: {
        color: '#00000029',
        radius: 8,
      },
      backgroundColor: '#FFFFFF',
    },
  },
  mainContainerWidget: {
    widgetStyle: {
      backgroundColor: '#FFFFFF',
      borderRadius: 12,
      shadow: {
        color: '#00000029',
        radius: 40,
      },
      padding: {
        left: 32,
        bottom: 32,
        right: 32,
        top: 32,
      },
      margin: {
        right: 12,
        bottom: 12,
        top: 12,
        left: 12,
      },
    },
  },
  popupCloseButtonWidget: {
    widgetStyle: {
      color: 'rgba(0, 27, 72, 0.31)',
      padding: {
        bottom: 8,
        right: 8,
        left: 8,
        top: 8,
      },
      size: 20,
    },
  },
  info: {
    previewImageUrl: '/assets/themes/bold.png',
    tags: [TemplateTag.Mini, TemplateTag.TextOnly],
    name: 'Bold',
  },
  userEngagement: {
    firstExperience: FirstExperience.TriggerButton,
    dismissBehavior: DismissBehavior.ShowTriggerButton,
    timer: {
      type: TimerType.Immediately,
    },
  },
  widgets: [
    {
      text: '15% Off',
      id: CommonWidgetIds.Headline,
      widgetStyle: {
        fontSize: 37,
        textAlign: HorizontalAlign.Center,
        margin: {
          top: 13,
          bottom: 13,
        },
        fontWeight: FontWeight.w800,
        color: '#443381',
        fontFamily: FontFamily.Merriweather,
      },
      type: WidgetType.Text,
    },
    {
      widgetStyle: {
        textAlign: HorizontalAlign.Center,
        fontFamily: FontFamily.Poppins,
        fontSize: 15,
        fontWeight: FontWeight.w400,
        margin: {
          bottom: 20,
        },
        color: '#443381',
      },
      id: CommonWidgetIds.SubHeadline,
      text: 'Subscribe & Save!',
      type: WidgetType.Text,
    },
    {
      onClick: {
        type: ClickActionType.SendMessage,
        text: '',
        phone: '(844) 928-4252',
        smsType: SmsType.Keyword,
      },
      text: ['Get 15% off now', 'When you sign up for texts'],
      widgetStyle: {
        backgroundColor: '#FA5715',
        padding: {
          bottom: 8,
          left: 16,
          top: 8,
          right: 16,
        },
        margin: {
          right: null,
          top: null,
          left: null,
          bottom: 26,
        },
        borderRadius: 4,
        firstRowStyle: {
          fontWeight: FontWeight.w600,
          fontFamily: FontFamily.Poppins,
          color: '#FFF',
          fontSize: 16,
        },
        secondRowStyle: {
          fontFamily: FontFamily.Poppins,
          fontWeight: FontWeight.w600,
          fontSize: 10,
          color: '#FFF',
        },
        border: {
          color: '',
          width: 0,
        },
        isExpanded: true,
      },
      id: CommonWidgetIds.MainButton,
      type: WidgetType.Button,
    },
    {
      widgetStyle: {
        fontFamily: FontFamily.Rubik,
        fontSize: 9,
        textAlign: HorizontalAlign.Center,
        fontWeight: FontWeight.w400,
        margin: {
          bottom: 16,
        },
        color: '#75849C',
      },
      smsRate: 4,
      id: CommonWidgetIds.LegalText,
      type: WidgetType.LegalText,
    },
    {
      widgetStyle: {
        textAlign: HorizontalAlign.Center,
        margin: {
          bottom: 5,
          left: null,
          top: null,
          right: null,
        },
        fontFamily: FontFamily.Rubik,
        fontWeight: FontWeight.w400,
        color: '#75849C',
        fontSize: 9,
      },
      type: WidgetType.PoliciesText,
      link: {
        type: PoliciesLinkType.AutoGenerated,
        complianceEmail: 'stephen+jackie@simpletexting.net',
        companyName: 'Jackies Chocolates',
      },
      id: CommonWidgetIds.PoliciesText,
    },
    {
      type: WidgetType.SimpleTextingCopyrightText,
      id: CommonWidgetIds.SimpleTextingCopyright,
      widgetStyle: {
        color: '#75849C',
        fontWeight: FontWeight.w400,
        fontFamily: FontFamily.Rubik,
        margin: {
          top: 16,
        },
        fontSize: 13,
        textAlign: HorizontalAlign.Center,
      },
    },
  ],
};
