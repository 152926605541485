import { Template, TimerType } from '../template';

/**
 * Validate user engagement template configuration. Returns `true` if configuration is valid.
 *
 * @param userEngagement User engagement template configuration.
 */
export function validateUserEngagement(userEngagement?: Template['userEngagement']): boolean {
  return (
    userEngagement?.timer.type === TimerType.Immediately ||
    (userEngagement?.timer.type === TimerType.Delay && userEngagement?.timer.delaySec > 0)
  );
}
