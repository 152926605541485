import React from 'react';
import { useSelector } from 'react-redux';
import { findTemplateWidget } from '../../../../../../core/utils';
import { selectConfigId, selectTemplate } from '../../../../../../state/selectors';
import { CommonWidgetIds, Template } from '../../../../../../core/template';
import { WidgetType } from '../../../../../../core/widgets/definitions';
import { AccordionSection } from '../../../../../components';
import styles from './TemplatePanel.module.scss';
import { Link } from 'react-router-dom';

function hasTemplateWidget(template: Template, id: CommonWidgetIds, type: WidgetType): boolean {
  return !!findTemplateWidget(template, id, type);
}

function templateFeatures(template: Template): string[] {
  const features: string[] = [];
  if (hasTemplateWidget(template, CommonWidgetIds.LogoImage, WidgetType.Image)) {
    features.push('Logo');
  }
  if (hasTemplateWidget(template, CommonWidgetIds.HeroImage, WidgetType.Image)) {
    features.push('Feature Image');
  }
  if (hasTemplateWidget(template, CommonWidgetIds.SubHeadline, WidgetType.Text)) {
    features.push('Feature Text');
  }
  if (hasTemplateWidget(template, CommonWidgetIds.DismissButton, WidgetType.Button)) {
    features.push('Dismiss Button');
  }

  return features;
}

export function TemplatePanel() {
  const template = useSelector(selectTemplate);
  const configId = useSelector(selectConfigId);

  const features = templateFeatures(template);

  return (
    <AccordionSection id="template" title="Template">
      <div className={styles.template}>
        <div className={styles.device}>
          <div className={styles.cameraAndSpeaker}>
            <div className={styles.camera} />
            <div className={styles.speaker} />
          </div>
          <div className={styles.screen}>
            <img className={styles.image} src={template.info.previewImageUrl} alt="Theme preview" />
          </div>
        </div>

        <div>
          <div className={styles.title}>{template.info.name}</div>

          <Link to={`/${configId}/gallery`} className={styles.pickNewThemeButton}>
            Pick new theme
          </Link>

          {features.length > 9 && (
            <div className={styles.featuresSection}>
              <div className={styles.title}>Features</div>
              <ul className={styles.features}>
                {features.map((name) => (
                  <li key={name} className={styles.feature}>
                    {name}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </AccordionSection>
  );
}
