import {
  ClickActionType,
  FontFamily,
  FontWeight,
  HorizontalAlign,
  ImageFit,
  PoliciesLinkType,
  SmsType,
  WidgetType,
} from '../core/widgets/definitions';
import {
  CommonWidgetIds,
  DismissBehavior,
  FirstExperience,
  Template,
  TemplateTag,
  TimerType,
} from '../core/template';

export const bright: Template = {
  userEngagement: {
    dismissBehavior: DismissBehavior.ShowTriggerButton,
    firstExperience: FirstExperience.TriggerButton,
    timer: {
      type: TimerType.Immediately,
    },
  },
  widgetPresets: {
    buttons: {
      rounded: {
        borderRadius: 4,
      },
      ghost: {
        border: {
          width: 1,
        },
        borderRadius: 0,
      },
      singleLine: {
        padding: {
          right: 16,
          left: 16,
          bottom: 13,
          top: 13,
        },
      },
      doubleLine: {
        padding: {
          bottom: 8,
          left: 16,
          right: 16,
          top: 8,
        },
      },
    },
    popup: {
      overlay: {
        color: '#33333320',
        blurRadius: 2,
      },
    },
  },
  triggerButtonWidget: {
    text: 'Get 10% Off',
    widgetStyle: {
      margin: {
        right: 12,
        left: 12,
        bottom: 12,
        top: 12,
      },
      backgroundColor: '#F90480',
      firstRowStyle: {
        fontSize: 16,
        color: '#FFF',
        fontWeight: FontWeight.w400,
        fontFamily: FontFamily.Rubik,
      },
      borderRadius: 4,
      secondRowStyle: {
        fontSize: 10,
        fontFamily: FontFamily.Rubik,
        color: '#FFF',
        fontWeight: FontWeight.w400,
      },
      border: {
        color: '',
        width: 0,
      },
      padding: {
        right: 16,
        top: 13,
        left: 16,
        bottom: 13,
      },
      isExpanded: true,
    },
  },
  horizontalPosition: HorizontalAlign.Right,
  mainContainerWidget: {
    widgetStyle: {
      backgroundColor: '#FFFFFF',
      borderRadius: 12,
      margin: {
        top: 12,
        left: 12,
        bottom: 12,
        right: 12,
      },
      shadow: {
        color: '#00000029',
        radius: 40,
      },
      padding: {
        left: 32,
        bottom: 32,
        right: 32,
        top: 32,
      },
    },
  },
  popupCloseButtonWidget: {
    widgetStyle: {
      padding: {
        left: 8,
        right: 8,
        bottom: 8,
        top: 8,
      },
      color: 'rgba(0, 27, 72, 0.31)',
      size: 20,
    },
  },
  triggerButtonCloseButtonWidget: {
    widgetStyle: {
      size: 14,
      shadow: {
        color: '#00000029',
        radius: 8,
      },
      padding: {
        right: 4,
        left: 4,
        bottom: 4,
        top: 4,
      },
      backgroundColor: '#FFFFFF',
      margin: {
        right: -8,
        left: -8,
        top: -8,
      },
      borderRadius: 20,
      color: 'rgba(0, 27, 72, 0.31)',
    },
  },
  info: {
    previewImageUrl: '/assets/themes/bright.png',
    tags: [TemplateTag.FullSize, TemplateTag.FeatureImage],
    name: 'Bright',
  },
  widgets: [
    {
      widgetStyle: {
        fit: ImageFit.Contain,
        margin: {
          bottom: 16,
        },
        align: HorizontalAlign.Center,
        size: {
          width: 160,
          height: null,
        },
      },
      onClick: {
        type: ClickActionType.OpenLink,
        url: 'https://www.planetbeauty.com',
      },
      localUrl: null,
      fileName: 'logo.png',
      id: CommonWidgetIds.LogoImage,
      url:
        'https://firebasestorage.googleapis.com/v0/b/simple-texting-tools.appspot.com/o/configs%2FYi9_jKAH0vHNzfh6JLBpE%2Fimages%2FupAKn5HFs-OQ8ioogFbaL.png?alt=media',
      type: WidgetType.Image,
    },
    {
      widgetStyle: {
        fit: ImageFit.Contain,
        align: HorizontalAlign.Center,
        margin: {
          top: null,
          right: null,
          bottom: 25,
          left: null,
        },
      },
      localUrl: null,
      type: WidgetType.Image,
      url:
        'https://firebasestorage.googleapis.com/v0/b/simple-texting-tools.appspot.com/o/configs%2FYi9_jKAH0vHNzfh6JLBpE%2Fimages%2F9GSkBuuHOxGvpsmGlF8yM.png?alt=media',
      fileName: 'PlanetBeauty_brandbare.jpg',
      id: CommonWidgetIds.HeroImage,
    },
    {
      id: CommonWidgetIds.Headline,
      widgetStyle: {
        fontWeight: FontWeight.w700,
        textAlign: HorizontalAlign.Center,
        fontSize: 21,
        margin: {
          top: 0,
          right: null,
          left: null,
          bottom: 5,
        },
        fontFamily: FontFamily.OpenSans,
        color: '#F90480',
      },
      text: '20% OFF',
      type: WidgetType.Text,
    },
    {
      type: WidgetType.Text,
      widgetStyle: {
        fontFamily: FontFamily.Rubik,
        fontSize: 15,
        color: '#75849C',
        margin: {
          bottom: 32,
        },
        fontWeight: FontWeight.w400,
        textAlign: HorizontalAlign.Center,
      },
      id: CommonWidgetIds.SubHeadline,
      text: 'YOUR FIRST ORDER',
    },
    {
      text: ['SIGN UP FOR TEXTS', ''],
      widgetStyle: {
        border: {
          color: '',
          width: 0,
        },
        isExpanded: true,
        padding: {
          top: 8,
          left: 16,
          right: 16,
          bottom: 8,
        },
        secondRowStyle: {
          color: '#FFF',
          fontWeight: FontWeight.w400,
          fontFamily: FontFamily.Rubik,
          fontSize: 10,
        },
        margin: {
          bottom: 24,
        },
        firstRowStyle: {
          fontFamily: FontFamily.Rubik,
          fontSize: 16,
          color: '#FFF',
          fontWeight: FontWeight.w400,
        },
        borderRadius: 4,
        backgroundColor: '#F90480',
      },
      type: WidgetType.Button,
      id: CommonWidgetIds.MainButton,
      onClick: {
        smsType: SmsType.Keyword,
        text: '',
        type: ClickActionType.SendMessage,
        phone: '(844) 928-4252',
      },
    },
    {
      type: WidgetType.LegalText,
      widgetStyle: {
        margin: {
          bottom: 16,
        },
        fontWeight: FontWeight.w400,
        color: '#75849C',
        fontFamily: FontFamily.Rubik,
        fontSize: 9,
        textAlign: HorizontalAlign.Center,
      },
      id: CommonWidgetIds.LegalText,
      smsRate: 4,
    },
    {
      id: CommonWidgetIds.PoliciesText,
      type: WidgetType.PoliciesText,
      widgetStyle: {
        textAlign: HorizontalAlign.Center,
        fontFamily: FontFamily.Rubik,
        fontSize: 9,
        color: '#75849C',
        fontWeight: FontWeight.w400,
      },
      link: {
        type: PoliciesLinkType.AutoGenerated,
        companyName: 'Planet Beauty',
        complianceEmail: 'stephen+planetbeauty@simpletexting.net',
      },
    },
    {
      widgetStyle: {
        color: '#75849C',
        fontWeight: FontWeight.w400,
        fontSize: 13,
        textAlign: HorizontalAlign.Center,
        fontFamily: FontFamily.Rubik,
        margin: {
          top: 16,
        },
      },
      id: CommonWidgetIds.SimpleTextingCopyright,
      type: WidgetType.SimpleTextingCopyrightText,
    },
  ],
};
