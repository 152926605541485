import { FormikConfig, FormikValues } from 'formik/dist/types';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Formik, Form as FormikForm, useFormikContext } from 'formik';
import { omit, isEqual } from 'lodash';

interface FormProps<Values extends FormikValues = FormikValues>
  extends Omit<FormikConfig<Values>, 'onSubmit'> {
  onSubmit?: (values: Values) => void;
  onValuesChange?: (values: Values) => void;
  onIsValidChange?: (isValid: boolean) => void;
  className?: string;
}

function useEffectWithLatestCallback<T>(
  callback: (args: T) => void,
  callbackFactoryDeps: any[],
  callbackArg: T
) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoCallback = useCallback(callback, callbackFactoryDeps);
  const callbackRef = useRef(memoCallback);
  callbackRef.current = memoCallback;
  useEffect(() => {
    callbackRef.current(callbackArg);
  }, [callbackRef, callbackArg]);
}

function FormikFix<Values extends FormikValues = FormikValues>(props: {
  initialValues: Values;
  validate?: (values: Values) => any;
  onValuesChange?: (values: Values) => void;
  onIsValidChange?: (isValid: boolean) => void;
}) {
  const formikContext = useFormikContext<Values>();

  const valuesDeps = Object.values(formikContext.values);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const values = useMemo(() => formikContext.values, valuesDeps);

  useEffectWithLatestCallback(
    (values: Values) => {
      if (props.onValuesChange && !isEqual(values, props.initialValues)) {
        console.log('Form.onValuesChange', values);
        props.onValuesChange(values);
      }
    },
    [props.onValuesChange, props.initialValues],
    values
  );

  const isValidRef = useRef<boolean | null>(null);
  useEffectWithLatestCallback(
    (isValid: boolean) => {
      if (props.onIsValidChange && !isEqual(isValidRef.current, isValid)) {
        isValidRef.current = isValid;
        console.log('Form.onIsValidChange', isValid);
        props.onIsValidChange(isValid);
      }
    },
    [props.validate, props.onIsValidChange, isValidRef],
    formikContext.isValid
  );

  useEffectWithLatestCallback(
    (initialValues: Values) => {
      if (!isEqual(values, initialValues)) {
        console.log('Form.setValues', initialValues);
        formikContext.setValues(initialValues);
      }
    },
    [formikContext.setValues, values],
    props.initialValues
  );

  return null;
}

export function Form<Values extends FormikValues = FormikValues>(props: FormProps<Values>) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const firstInitialValues = useMemo(() => props.initialValues, []);

  return (
    <Formik
      {...omit(props, ['onValuesChange', 'className'])}
      initialValues={firstInitialValues}
      validateOnMount={true}
      onSubmit={props.onSubmit ?? (() => {})}
      validate={props.validate}
    >
      <>
        <FormikFix
          initialValues={props.initialValues}
          onValuesChange={props.onValuesChange}
          onIsValidChange={props.onIsValidChange}
          validate={props.validate}
        />
        <FormikForm className={props.className}>{props.children}</FormikForm>
      </>
    </Formik>
  );
}
