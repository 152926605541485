import { Form, InputFormField, InputType } from '../../../../../form-components';
import { WidgetConfig, WithBorderRadius } from '../../../../../../core/widgets/types';
import { useStyleConfigHelpers } from '../../hooks';
import { WidgetStyleConfigPanelProps } from '../types';

interface WithBorderRadiusConfig {
  borderRadius: string;
}

export function BorderRadiusConfig<T extends WidgetConfig>(
  props: WidgetStyleConfigPanelProps<WithBorderRadius, T>
) {
  const [widgetStyle, onChange] = useStyleConfigHelpers(props);

  const initialValue: WithBorderRadiusConfig = {
    borderRadius: widgetStyle?.borderRadius?.toFixed(0) ?? '',
  };

  const handleChange = (formValues: WithBorderRadiusConfig) => {
    onChange({
      borderRadius: formValues.borderRadius !== '' ? Number(formValues.borderRadius) : null,
    });
  };

  return (
    <Form initialValues={initialValue} onValuesChange={handleChange}>
      <InputFormField name="borderRadius" label="Corner radius" type={InputType.PositiveNumbers} />
    </Form>
  );
}
