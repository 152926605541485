import {
  ClickActionType,
  FontFamily,
  FontWeight,
  HorizontalAlign,
  ImageFit,
  PoliciesLinkType,
  SmsType,
  WidgetType,
} from '../core/widgets/definitions';
import {
  CommonWidgetIds,
  DismissBehavior,
  FirstExperience,
  Template,
  TemplateTag,
  TimerType,
} from '../core/template';

export const colorful: Template = {
  popupCloseButtonWidget: {
    widgetStyle: {
      size: 20,
      color: 'rgba(0, 27, 72, 0.31)',
      padding: {
        bottom: 8,
        right: 8,
        left: 8,
        top: 8,
      },
    },
  },
  triggerButtonWidget: {
    widgetStyle: {
      isExpanded: true,
      margin: {
        left: 12,
        right: 12,
        bottom: 12,
        top: 12,
      },
      borderRadius: 0,
      firstRowStyle: {
        fontWeight: FontWeight.w900,
        fontFamily: FontFamily.Lato,
        color: '#FFFFFfFF',
        fontSize: 16,
        textAlign: HorizontalAlign.Center,
      },
      backgroundColor: '#D3B031',
      border: {
        color: '',
        width: 0,
      },
      padding: {
        left: 16,
        right: 16,
        bottom: 13,
        top: 13,
      },
      secondRowStyle: {
        fontSize: 10,
        fontFamily: FontFamily.Lato,
        fontWeight: FontWeight.w900,
        color: '#000',
      },
    },
    text: 'Join us',
  },
  widgets: [
    {
      type: WidgetType.Image,
      widgetStyle: {
        align: HorizontalAlign.Center,
        size: {
          width: 200,
          height: 70,
        },
        fit: ImageFit.Contain,
      },
      id: CommonWidgetIds.LogoImage,
      onClick: {
        url: 'https://www.noondaycollection.com',
        type: ClickActionType.OpenLink,
      },
      url:
        'https://firebasestorage.googleapis.com/v0/b/simple-texting-tools.appspot.com/o/configs%2FOKsece4Y35Qe_lJISAhoM%2Fimages%2FtzjdhQumE57frDrkuiKvR.png?alt=media',
      fileName: 'noonday-logo.png',
      localUrl: null,
    },
    {
      widgetStyle: {
        fontWeight: FontWeight.w700,
        fontFamily: FontFamily.Merriweather,
        textAlign: HorizontalAlign.Center,
        margin: {
          top: 11,
          bottom: 11,
        },
        fontSize: 34,
        color: '#3D3D3D',
      },
      type: WidgetType.Text,
      id: CommonWidgetIds.Headline,
      text: 'Spring 2021 Collection',
    },
    {
      id: CommonWidgetIds.SubHeadline,
      widgetStyle: {
        color: '#626262',
        fontSize: 18,
        margin: {
          bottom: 38,
        },
        fontWeight: FontWeight.w600,
        fontFamily: FontFamily.OpenSans,
        textAlign: HorizontalAlign.Center,
      },
      type: WidgetType.Text,
      text: 'COMING SOON',
    },
    {
      onClick: {
        type: ClickActionType.SendMessage,
        phone: '(844) 928-4252',
        text: '',
        smsType: SmsType.Keyword,
      },
      type: WidgetType.Button,
      widgetStyle: {
        backgroundColor: '#D3B031',
        padding: {
          top: 8,
          bottom: 8,
          right: 16,
          left: 16,
        },
        border: {
          width: 0,
          color: '',
        },
        secondRowStyle: {
          color: '#FFFFFFFF',
          fontWeight: FontWeight.w700,
          fontSize: 10,
          fontFamily: FontFamily.Lato,
          textAlign: HorizontalAlign.Center,
        },
        margin: {
          bottom: 32,
        },
        borderRadius: 0,
        firstRowStyle: {
          fontSize: 16,
          fontFamily: FontFamily.Lato,
          textAlign: HorizontalAlign.Center,
          color: '#FFFFFfFF',
          fontWeight: FontWeight.w900,
        },
        isExpanded: true,
      },
      text: ['Text Updates', 'on the latest collections'],
      id: CommonWidgetIds.MainButton,
    },
    {
      widgetStyle: {
        margin: {
          bottom: 16,
        },
        color: '#75849C',
        fontFamily: FontFamily.Rubik,
        fontSize: 9,
        textAlign: HorizontalAlign.Center,
        fontWeight: FontWeight.w400,
      },
      smsRate: 4,
      type: WidgetType.LegalText,
      id: CommonWidgetIds.LegalText,
    },
    {
      id: CommonWidgetIds.PoliciesText,
      type: WidgetType.PoliciesText,
      widgetStyle: {
        margin: {
          bottom: 16,
        },
        color: '#75849C',
        fontWeight: FontWeight.w400,
        fontSize: 9,
        textAlign: HorizontalAlign.Center,
        fontFamily: FontFamily.Rubik,
      },
      link: {
        complianceEmail: 'stephen+noon@simpletexting.net',
        type: PoliciesLinkType.AutoGenerated,
        companyName: 'Noon Day Collection',
      },
    },
    {
      widgetStyle: {
        fontWeight: FontWeight.w400,
        textAlign: HorizontalAlign.Center,
        fontSize: 13,
        color: '#75849C',
        margin: {
          top: 16,
        },
        fontFamily: FontFamily.Rubik,
      },
      id: CommonWidgetIds.SimpleTextingCopyright,
      type: WidgetType.SimpleTextingCopyrightText,
    },
  ],
  mainContainerWidget: {
    widgetStyle: {
      borderRadius: 12,
      shadow: {
        color: '#00000029',
        radius: 40,
      },
      backgroundImage: {
        fit: ImageFit.Cover,
        url:
          'https://firebasestorage.googleapis.com/v0/b/simple-texting-tools.appspot.com/o/configs%2FOKsece4Y35Qe_lJISAhoM%2Fimages%2FMaQum0Wv0rar5VjPyFxDe.png?alt=media',
        opacity: 30,
        fileName: 'calypso-earrings-small.jpg',
        localUrl: null,
      },
      padding: {
        bottom: 32,
        right: 32,
        top: 32,
        left: 32,
      },
      backgroundColor: '#FFFFfFFF',
      margin: {
        left: 12,
        bottom: 12,
        top: 12,
        right: 12,
      },
    },
  },
  horizontalPosition: HorizontalAlign.Right,
  userEngagement: {
    firstExperience: FirstExperience.TriggerButton,
    dismissBehavior: DismissBehavior.ShowTriggerButton,
    timer: {
      type: TimerType.Immediately,
    },
  },
  triggerButtonCloseButtonWidget: {
    widgetStyle: {
      shadow: {
        color: '#00000029',
        radius: 8,
      },
      margin: {
        left: -8,
        top: -8,
        right: -8,
      },
      borderRadius: 20,
      size: 14,
      backgroundColor: '#FFFFFF',
      color: 'rgba(0, 27, 72, 0.31)',
      padding: {
        bottom: 4,
        top: 4,
        right: 4,
        left: 4,
      },
    },
  },
  info: {
    previewImageUrl: '/assets/themes/colorful.png',
    tags: [TemplateTag.FullSize, TemplateTag.BackgroundImage, TemplateTag.SquareLogo],
    name: 'Colorful',
  },
  widgetPresets: {
    buttons: {
      rounded: {
        borderRadius: 4,
      },
      ghost: {
        border: {
          width: 1,
        },
        borderRadius: 0,
      },
      singleLine: {
        padding: {
          top: 13,
          right: 16,
          left: 16,
          bottom: 13,
        },
      },
      doubleLine: {
        padding: {
          left: 16,
          bottom: 8,
          right: 16,
          top: 8,
        },
      },
    },
    popup: {
      overlay: {
        color: '#33333320',
        blurRadius: 2,
      },
    },
  },
};
