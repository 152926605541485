import classNames from 'classnames';
import { useField } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import MaskedInput from 'react-text-mask';
import {
  CommonWidgetIds,
  DismissBehavior,
  FirstExperience,
  Template,
  TimerType,
} from '../../../../../../core/template';
import {
  findTemplateWidget,
  validateOnClick,
  validateUserEngagement,
} from '../../../../../../core/utils';
import { ClickActionType, SmsType, WidgetType } from '../../../../../../core/widgets/definitions';
import { Validator } from '../../../../../../services';
import { mergeWidgetConfig, setUserEngagement } from '../../../../../../state/actions';
import { selectTemplate } from '../../../../../../state/selectors';
import {
  AccordionSection,
  EditPanelDescription,
  EditPanelSection,
  FormRow,
  Tab,
  Tabs,
} from '../../../../../components';
import {
  Form,
  InputFormField,
  InputType,
  RadioButton,
  TextareaFormField,
} from '../../../../../form-components';
import styles from './UserEngagementPanel.module.scss';

const secMask = [/[1-9]/, /\d/];

function SetDelayOfLabel() {
  const [field, meta] = useField('delaySec');

  return (
    <div>
      Set delay of{' '}
      <MaskedInput
        {...field}
        mask={secMask}
        guide={false}
        className={classNames({
          [styles.delayInput]: true,
          [styles.delayInputHasError]: !!meta.error,
        })}
        placeholder="0"
      />{' '}
      sec
    </div>
  );
}

interface FormConfig {
  firstExperience: FirstExperience;
  timer: TimerType;
  delaySec: string;
  dismissBehavior: DismissBehavior;
  phone: string;
  smsType: SmsType;
  message: string;
}

function validateForm(values: FormConfig) {
  const errors: { [name in keyof typeof values]?: string } = {};

  if (values.timer === TimerType.Delay && (!values.delaySec || values.delaySec === '0')) {
    errors['delaySec'] = 'Must be not empty';
  }

  if (!Validator.isPhone(values.phone)) {
    errors['phone'] = 'Enter valid phone';
  }

  return errors;
}

function validateSmsTab(config: Template) {
  const mainButton = findTemplateWidget(config, CommonWidgetIds.MainButton, WidgetType.Button);
  if (!mainButton) {
    throw new Error('Not found Main Button widget');
  }

  return validateOnClick(mainButton.onClick);
}

function validateUserEngagementTab(config: Template) {
  return validateUserEngagement(config.userEngagement);
}

function validateConfig(config: Template) {
  return validateUserEngagementTab(config) && validateSmsTab(config);
}

export function UserEngagementPanel() {
  const dispatch = useDispatch();
  const template = useSelector(selectTemplate);

  const isValid = validateConfig(template);

  const mainButton = findTemplateWidget(template, CommonWidgetIds.MainButton, WidgetType.Button);
  if (!mainButton) {
    throw new Error('Not found Main Button widget');
  }

  const initialValues: FormConfig = {
    firstExperience: template.userEngagement.firstExperience,
    timer: template.userEngagement.timer.type,
    delaySec:
      'delaySec' in template.userEngagement.timer && template.userEngagement.timer.delaySec
        ? template.userEngagement.timer.delaySec.toFixed()
        : '',
    dismissBehavior: template.userEngagement.dismissBehavior,
    phone: mainButton.onClick?.type === ClickActionType.SendMessage ? mainButton.onClick.phone : '',
    smsType:
      mainButton.onClick?.type === ClickActionType.SendMessage
        ? mainButton.onClick.smsType
        : SmsType.Keyword,
    message:
      mainButton.onClick?.type === ClickActionType.SendMessage ? mainButton.onClick.text : '',
  };

  const handleChange = (values: typeof initialValues) => {
    dispatch(
      setUserEngagement({
        firstExperience: values.firstExperience,
        timer: {
          type: values.timer,
          delaySec: values.delaySec ? Number(values.delaySec) : 0,
        },
        dismissBehavior: values.dismissBehavior,
      })
    );
    dispatch(
      mergeWidgetConfig({
        ...mainButton,
        onClick: {
          type: ClickActionType.SendMessage,
          smsType: values.smsType,
          phone: values.phone,
          text: values.message,
        },
      })
    );
  };

  return (
    <AccordionSection title="Behavior" id="userEngagement" isHasError={!isValid}>
      <Form initialValues={initialValues} validate={validateForm} onValuesChange={handleChange}>
        <Tabs
          tabs={[
            { label: 'Behavior', id: 'behavior', hasError: !validateUserEngagementTab(template) },
            { label: 'SMS', id: 'sms', hasError: !validateSmsTab(template) },
          ]}
        >
          <Tab id="behavior">
            <EditPanelSection title="First Experience">
              <RadioButton
                label="Open popup"
                value={FirstExperience.ShowPopUp}
                name="firstExperience"
              />
              <RadioButton
                label="Show trigger button"
                value={FirstExperience.TriggerButton}
                name="firstExperience"
              />
            </EditPanelSection>

            <EditPanelSection title="Timer">
              <RadioButton label="Show immediately on page load" value="immediately" name="timer" />
              <RadioButton label={<SetDelayOfLabel />} value="delay" name="timer" />
            </EditPanelSection>

            <EditPanelSection title="Dismiss behavior">
              <RadioButton
                label="Show trigger button"
                value={DismissBehavior.ShowTriggerButton}
                name="dismissBehavior"
              />
              <RadioButton
                label="Close all"
                value={DismissBehavior.CloseAll}
                name="dismissBehavior"
              />
            </EditPanelSection>
          </Tab>

          <Tab id="sms">
            <EditPanelDescription>
              Pre-populate the text message to your number
            </EditPanelDescription>
            <FormRow>
              <InputFormField
                name="phone"
                label="Texting phone number"
                type={InputType.PhoneNumber}
              />
            </FormRow>

            <EditPanelSection title="Message type">
              <RadioButton name="smsType" label="Keyword" value={SmsType.Keyword} maxWidth={292}>
                <FormRow>
                  <InputFormField
                    name="message"
                    label="Keyword"
                    type={InputType.Keyword}
                    helperText={
                      <div className={styles.helperText}>
                        Don't have a keyword?{' '}
                        <a
                          className={styles.link}
                          href="https://help.simpletexting.com/en/articles/1071268-setting-up-a-keyword"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Learn more here.
                        </a>
                      </div>
                    }
                  />
                </FormRow>
              </RadioButton>

              <RadioButton name="smsType" label="Text message" value={SmsType.Text} maxWidth={292}>
                <FormRow>
                  <TextareaFormField
                    name="message"
                    label="Text message"
                    rows={4}
                    helperText={
                      <div className={styles.helperText}>
                        Want your contacts to be added to a list? Use a keyword.{' '}
                        <a
                          className={styles.link}
                          href="https://help.simpletexting.com/en/articles/1071268-setting-up-a-keyword"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Learn more here.
                        </a>
                      </div>
                    }
                  />
                </FormRow>
                <div className={styles.twoLinePadding} />
              </RadioButton>
            </EditPanelSection>
          </Tab>
        </Tabs>
      </Form>
    </AccordionSection>
  );
}
