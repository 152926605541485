import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';
import { CommonWidgetIds, Template } from '../../core/template';
import { findTemplateWidget, fixImageUrl, fixUrl } from '../../core/utils';
import {
  ClickActionType,
  PoliciesLinkType,
  SmsType,
  WidgetType,
} from '../../core/widgets/definitions';
import { AppState } from '../types';
import { generateWidgetIds } from '../utils/generateWidgetIds';

/**
 * Set given template as editing template.
 *
 * Unlike {@see replaceTemplate} this action erase all changes that user made in previous
 * template.
 */
export const loadTemplate = createAction<Template>('loadTemplate');

/** Append reducer for {@see loadTemplate} action to Redux slice */
export const addLoadTemplateReducer = (builder: ActionReducerMapBuilder<AppState>) => {
  builder.addCase(loadTemplate, (state, action) => {
    state.template = generateWidgetIds(action.payload);
    state.isTemplateChanged = true;

    const mainButton = findTemplateWidget(
      state.template,
      CommonWidgetIds.MainButton,
      WidgetType.Button
    );
    if (mainButton) {
      mainButton.onClick = {
        type: ClickActionType.SendMessage,
        smsType: SmsType.Keyword,
        phone: state.contacts.phone,
        text: '',
      };
    }

    const policiesText = findTemplateWidget(
      state.template,
      CommonWidgetIds.PoliciesText,
      WidgetType.PoliciesText
    );
    if (policiesText) {
      policiesText.link = {
        type: PoliciesLinkType.AutoGenerated,
        companyName: state.contacts.companyName,
        complianceEmail: state.contacts.email,
      };
    }

    const logoImage = findTemplateWidget(
      state.template,
      CommonWidgetIds.LogoImage,
      WidgetType.Image
    );
    if (logoImage) {
      logoImage.onClick = {
        type: ClickActionType.OpenLink,
        url: fixUrl(state.contacts.webSite),
      };
      logoImage.url = fixImageUrl(logoImage.url);
    }

    const heroImage = findTemplateWidget(
      state.template,
      CommonWidgetIds.HeroImage,
      WidgetType.Image
    );
    if (heroImage) {
      heroImage.url = fixImageUrl(heroImage.url);
    }
  });
};
