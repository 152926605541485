import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';
import { CommonWidgetIds } from '../../core/template';
import { AppState } from '../types';

/** Payload for {@see openEditPanelAccordionSection} action */
interface OpenEditPanelAccordionSectionInput {
  /** ID on section that must be opened */
  sectionId: string;
  /** If `true` accordion will scroll to section after section will be opened */
  useAutoScroll: boolean;
}

/** Open given section in Customizer accordion */
export const openEditPanelAccordionSection = createAction<OpenEditPanelAccordionSectionInput>(
  'openEditPanelAccordionSection'
);

/** Append reducer for {@see openEditPanelAccordionSection} action to Redux slice */
export const addOpenEditPanelAccordionSectionReducer = (
  builder: ActionReducerMapBuilder<AppState>
) => {
  builder.addCase(openEditPanelAccordionSection, (state, action) => {
    state.editPanel.isAccordionAutoScrollEnabled = action.payload.useAutoScroll;

    if (state.editPanel.isExpertModeEnabled) {
      state.editPanel.expandedAccordionSectionId = action.payload.sectionId;
    } else {
      switch (action.payload.sectionId) {
        case CommonWidgetIds.SimpleTextingCopyright:
        case CommonWidgetIds.TriggerButtonCloseButton:
        case CommonWidgetIds.PopupCloseButton:
        case CommonWidgetIds.MainContainer:
          state.editPanel.expandedAccordionSectionId = 'base_styling';
          state.editPanel.activeTabId = 'general';
          break;
        case CommonWidgetIds.TriggerButton:
        case CommonWidgetIds.DismissButton:
        case CommonWidgetIds.MainButton:
          state.editPanel.expandedAccordionSectionId = 'base_copy';
          state.editPanel.activeTabId = 'buttons';
          break;
        case CommonWidgetIds.PreHeadline:
        case CommonWidgetIds.Headline:
        case CommonWidgetIds.SubHeadline:
          state.editPanel.expandedAccordionSectionId = 'base_copy';
          state.editPanel.activeTabId = 'text';
          break;
        case CommonWidgetIds.LogoImage:
        case CommonWidgetIds.HeroImage:
          state.editPanel.expandedAccordionSectionId = 'base_images';
          state.editPanel.activeTabId = 'buttons';
          break;
        case CommonWidgetIds.LegalText:
        case CommonWidgetIds.PoliciesText:
          state.editPanel.expandedAccordionSectionId = 'base_legal';
          break;
        default:
          state.editPanel.expandedAccordionSectionId = action.payload.sectionId;
      }
    }
  });
};
