import {
  ClickActionType,
  FontFamily,
  FontWeight,
  HorizontalAlign,
  ImageFit,
  PoliciesLinkType,
  SmsType,
  WidgetType,
} from '../core/widgets/definitions';
import {
  CommonWidgetIds,
  DismissBehavior,
  FirstExperience,
  Template,
  TemplateTag,
  TimerType,
} from '../core/template';

export const evening: Template = {
  popupCloseButtonWidget: {
    widgetStyle: {
      size: 20,
      padding: {
        top: 8,
        bottom: 8,
        left: 8,
        right: 8,
      },
      color: 'rgba(225, 225, 225, 0.7)',
    },
  },
  horizontalPosition: HorizontalAlign.Right,
  triggerButtonCloseButtonWidget: {
    widgetStyle: {
      padding: {
        left: 4,
        bottom: 4,
        top: 4,
        right: 4,
      },
      size: 14,
      shadow: {
        color: '#00000029',
        radius: 8,
      },
      margin: {
        right: -8,
        top: -8,
        left: -8,
      },
      borderRadius: 20,
      backgroundColor: '#FFFFFF',
      color: 'rgba(0, 27, 72, 0.31)',
    },
  },
  userEngagement: {
    dismissBehavior: DismissBehavior.ShowTriggerButton,
    firstExperience: FirstExperience.TriggerButton,
    timer: {
      type: TimerType.Immediately,
    },
  },
  triggerButtonWidget: {
    widgetStyle: {
      firstRowStyle: {
        fontSize: 16,
        textAlign: HorizontalAlign.Center,
        color: '#FFFFFF',
        fontWeight: FontWeight.w500,
        fontFamily: FontFamily.Oswald,
      },
      isExpanded: true,
      margin: {
        bottom: 12,
        top: 12,
        left: 12,
        right: 12,
      },
      border: {
        color: '',
        width: 0,
      },
      backgroundColor: '#4DC5D5',
      secondRowStyle: {
        fontFamily: FontFamily.Oswald,
        color: '#000',
        fontSize: 10,
        fontWeight: FontWeight.w500,
      },
      borderRadius: 0,
      padding: {
        left: 16,
        top: 13,
        right: 16,
        bottom: 13,
      },
    },
    text: 'GET 10% OFF',
  },
  info: {
    name: 'Evening',
    tags: [TemplateTag.Mini, TemplateTag.FeatureImage],
    previewImageUrl: '/assets/themes/evening.png',
  },
  widgetPresets: {
    buttons: {
      rounded: {
        borderRadius: 4,
      },
      ghost: {
        border: {
          width: 1,
        },
        borderRadius: 0,
      },
      singleLine: {
        padding: {
          bottom: 13,
          right: 16,
          left: 16,
          top: 13,
        },
      },
      doubleLine: {
        padding: {
          bottom: 8,
          left: 16,
          right: 16,
          top: 8,
        },
      },
    },
    popup: {
      overlay: {
        color: '#33333320',
        blurRadius: 2,
      },
    },
  },
  mainContainerWidget: {
    widgetStyle: {
      margin: {
        bottom: 12,
        left: 12,
        top: 12,
        right: 12,
      },
      borderRadius: 12,
      shadow: {
        radius: 40,
        color: '#00000029',
      },
      padding: {
        right: 32,
        left: 32,
        top: 32,
        bottom: 32,
      },
      backgroundColor: '#333',
    },
  },
  widgets: [
    {
      onClick: {
        url: 'http://titan.clothing',
        type: ClickActionType.OpenLink,
      },
      type: WidgetType.Image,
      fileName: '5_410x.png',
      widgetStyle: {
        margin: {
          bottom: 16,
        },
        fit: ImageFit.Contain,
        size: {
          width: 50,
          height: null,
        },
        align: HorizontalAlign.Center,
      },
      id: CommonWidgetIds.LogoImage,
      localUrl: null,
      url:
        'https://firebasestorage.googleapis.com/v0/b/simple-texting-tools.appspot.com/o/configs%2FV2B4oMTXsIca_mXQYY8_s%2Fimages%2F7e0ZJ5UQE_DgAmhHh__1F.png?alt=media',
    },
    {
      fileName: 'titan-woman.jpg',
      localUrl: '',
      widgetStyle: {
        align: HorizontalAlign.Center,
        fit: ImageFit.Contain,
        margin: {
          bottom: 32,
        },
      },
      id: CommonWidgetIds.HeroImage,
      url:
        'https://firebasestorage.googleapis.com/v0/b/simple-texting-tools.appspot.com/o/configs%2FV2B4oMTXsIca_mXQYY8_s%2Fimages%2FHXvgEuOiUBWxLUCKOS__g.png?alt=media',
      type: WidgetType.Image,
    },
    {
      type: WidgetType.LegalText,
      id: CommonWidgetIds.LegalText,
      smsRate: 4,
      widgetStyle: {
        fontSize: 9,
        textAlign: HorizontalAlign.Center,
        fontWeight: FontWeight.w400,
        color: '#75849C',
        fontFamily: FontFamily.Rubik,
        margin: {
          bottom: 16,
        },
      },
    },
    {
      widgetStyle: {
        color: '#75849C',
        fontWeight: FontWeight.w400,
        fontFamily: FontFamily.Rubik,
        fontSize: 9,
        textAlign: HorizontalAlign.Center,
      },
      type: WidgetType.PoliciesText,
      id: CommonWidgetIds.PoliciesText,
      link: {
        type: PoliciesLinkType.AutoGenerated,
        complianceEmail: 'stephen+titan@simpletexting.net',
        companyName: 'Titan Clothing',
      },
    },
    {
      id: CommonWidgetIds.MainButton,
      text: ['GET 10% OFF', 'When you sign up for texts'],
      type: WidgetType.Button,
      onClick: {
        type: ClickActionType.SendMessage,
        smsType: SmsType.Keyword,
        text: '',
        phone: '(844) 928-4252',
      },
      widgetStyle: {
        isExpanded: true,
        secondRowStyle: {
          textAlign: HorizontalAlign.Center,
          fontFamily: FontFamily.Oswald,
          color: '#FFFFFF',
          fontWeight: FontWeight.w500,
          fontSize: 11,
        },
        margin: {
          top: 24,
          bottom: 12,
        },
        firstRowStyle: {
          color: '#FFFFFF',
          fontWeight: FontWeight.w400,
          textAlign: HorizontalAlign.Center,
          fontFamily: FontFamily.Oswald,
          fontSize: 18,
        },
        backgroundColor: '#4DC5D5',
        borderRadius: 0,
        border: {
          width: 0,
          color: '',
        },
        padding: {
          left: 16,
          bottom: 12,
          right: 16,
          top: 12,
        },
      },
    },
    {
      type: WidgetType.SimpleTextingCopyrightText,
      hidden: false,
      id: CommonWidgetIds.SimpleTextingCopyright,
      widgetStyle: {
        color: '#75849C',
        textAlign: HorizontalAlign.Center,
        fontWeight: FontWeight.w400,
        fontSize: 13,
        fontFamily: FontFamily.Rubik,
        margin: {
          bottom: null,
          left: null,
          right: null,
          top: 9,
        },
      },
    },
  ],
};
