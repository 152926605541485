import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { AppConfig } from '../../core/appConfig';
import { selectConfigId, selectTemplate } from '../selectors';
import { AppState } from '../types';

/** Publish current edited template */
export const publish = createAsyncThunk<
  void,
  void,
  {
    state: { app: AppState };
    extra: {
      jwt: string;
    };
  }
>('publish', async (action, thunkAPI) => {
  const configId = selectConfigId(thunkAPI.getState());
  const template = selectTemplate(thunkAPI.getState());

  await fetch(`${AppConfig.backendUrl}/publish?configId=${configId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(template),
  });
});

/** Append pending reducer of {@see publish} thunk to Redux slice */
export const addPublishPendingReducer = (builder: ActionReducerMapBuilder<AppState>) => {
  builder.addCase(publish.pending, (state, action) => {
    state.isTemplateChanged = false;
  });
};

/** Append fulfilled reducer of {@see publish} thunk to Redux slice */
export const addPublishFulfilledReducer = (builder: ActionReducerMapBuilder<AppState>) => {
  builder.addCase(publish.fulfilled, (state, action) => {
    state.savedTemplate = state.template;
  });
};

/** Append rejected reducer of {@see publish} thunk to Redux slice */
export const addPublishRejectedReducer = (builder: ActionReducerMapBuilder<AppState>) => {
  builder.addCase(publish.rejected, (state, action) => {
    state.isTemplateChanged = true;
  });
};
