import { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import { selectIsTemplateLoaded, selectPreviewConfig } from '../../../state/selectors';
import { Button, ComponentTheme, Device, PreviewConfigForm, RenderType } from '../../components';
import styles from './PreviewPage.module.scss';

export function PreviewPage() {
  const config = useSelector(selectPreviewConfig);
  const isTemplateLoaded = useSelector(selectIsTemplateLoaded);

  const screenSize = config.deviceScreen.split('x');
  if (screenSize.length !== 2) {
    throw new Error(`Invalid screen size ${screenSize}`);
  }
  const screenWidth = Number(screenSize[0]);
  const screenHeight = Number(screenSize[1]);

  const [previewVersion, setPreviewVersion] = useState(0);

  const handleRestartClick = () => {
    setPreviewVersion(previewVersion + 1);
  };

  if (!isTemplateLoaded) {
    return null;
  }

  return (
    <div className={styles.previewPage}>
      <PerfectScrollbar>
        <div className={styles.previewContent}>
          <PreviewConfigForm />

          <div className={styles.device}>
            <Device
              key={previewVersion}
              screenWidth={screenWidth}
              screenHeight={screenHeight}
              renderType={RenderType.Release}
            />

            <div className={styles.restartButton}>
              <Button theme={ComponentTheme.Transparent} onClick={handleRestartClick}>
                <div className={styles.restartText}>Restart</div>
              </Button>
            </div>
          </div>
        </div>
      </PerfectScrollbar>
    </div>
  );
}
