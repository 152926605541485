import { CommonWidgetIds } from '../../../../../../core/template';
import { ImageFormField } from '../../../../../form-components';
import { WidgetType } from '../../../../../../core/widgets/definitions';
import { AccordionSection, FormRow } from '../../../../../components';
import { useWidgetConfig } from '../../hooks';
import {
  ClickActionConfigSection,
  validateOnClickConfig,
  ImageConfig,
  MarginConfigSection,
  SetupConfigSection,
  StylingSection,
} from '../../panel-components';

interface ImageWidgetPanelProps {
  id: string;
}

interface FormConfig {
  urlFileName: string;
}

export function ImageWidgetPanel(props: ImageWidgetPanelProps) {
  const [widget, handleChanges] = useWidgetConfig(props.id, WidgetType.Image);

  const isValid = validateOnClickConfig(widget);

  let title = 'Image';
  let subTitle = widget.fileName;
  switch (props.id) {
    case CommonWidgetIds.LogoImage:
      title = 'Logo';
      subTitle = '';
      break;
    case CommonWidgetIds.HeroImage:
      title = 'Hero image';
      subTitle = '';
      break;
  }

  return (
    <AccordionSection
      id={props.id}
      title={title}
      subTitle={subTitle}
      isHasError={!isValid}
      isControlledWidgetCanBeRemoved={true}
    >
      <SetupConfigSection
        widget={widget}
        onChange={handleChanges}
        fromWidgetConfigToFormConfig={(config) => {
          return {
            urlFileName: widget.fileName,
          };
        }}
        fromFormConfigToWidgetConfig={(config: FormConfig) => {
          return {};
        }}
      >
        <FormRow>
          <ImageFormField
            name="urlFileName"
            label="Image"
            onChanged={handleChanges}
            onClear={() => handleChanges({ url: '', localUrl: null, fileName: '' })}
          />
        </FormRow>
      </SetupConfigSection>

      <StylingSection>
        <ImageConfig widget={widget} onChange={handleChanges} />
      </StylingSection>

      <ClickActionConfigSection noneActionEnabled={true} widget={widget} onChange={handleChanges} />

      <MarginConfigSection widget={widget} onChange={handleChanges} />
    </AccordionSection>
  );
}
