import { InputFormField, InputType } from '../../../../../form-components';
import { WidgetType } from '../../../../../../core/widgets/definitions';
import { LegalTextWidgetConfig } from '../../../../../../core/widgets/types';
import {
  AccordionSection,
  EditPanelDescription,
  FormRow,
  EditPanelSection,
} from '../../../../../components';
import { useWidgetConfig } from '../../hooks';
import { FontConfig, MarginConfigSection, SetupConfigSection } from '../../panel-components';

interface TextWidgetPanelProps {
  id: string;
}

interface FormConfig {
  widgetSmsRate: string;
}

function fromWidgetConfigToFormConfig(config: LegalTextWidgetConfig): FormConfig {
  return {
    widgetSmsRate: config.smsRate.toFixed(0),
  };
}

function fromFormConfigToWidgetConfig(config: FormConfig): Partial<LegalTextWidgetConfig> {
  return {
    smsRate: config.widgetSmsRate ? Number(config.widgetSmsRate) : 0,
  };
}

export function LegalTextWidgetPanel(props: TextWidgetPanelProps) {
  const [widget, handleChanges] = useWidgetConfig(props.id, WidgetType.LegalText);

  return (
    <AccordionSection
      id={props.id}
      title="Legal footer"
      isControlledWidgetVisible={widget.hidden}
      isControlledWidgetCanChangeVisible={true}
    >
      <SetupConfigSection
        widget={widget}
        onChange={handleChanges}
        fromWidgetConfigToFormConfig={fromWidgetConfigToFormConfig}
        fromFormConfigToWidgetConfig={fromFormConfigToWidgetConfig}
      >
        <EditPanelDescription>How often do you plan to text your subscribers?</EditPanelDescription>

        <FormRow>
          <InputFormField
            name="widgetSmsRate"
            label="Messages per month"
            type={InputType.PositiveNumbers}
          />
        </FormRow>
      </SetupConfigSection>

      <EditPanelSection title="Styling">
        <FontConfig widget={widget} onChange={handleChanges} />
      </EditPanelSection>

      <MarginConfigSection widget={widget} onChange={handleChanges} />
    </AccordionSection>
  );
}
