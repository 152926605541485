import React, { ReactNode } from 'react';
import styles from './EditPanel.module.scss';

interface PanelProps {
  children: ReactNode;
}

export function EditPanel(props: PanelProps) {
  return (
    <>
      <aside className={styles.panel}>
        <div id="st-widget-config-panel" className={styles.content}>
          {props.children}
        </div>

        <div className={styles.footer}>
          {new Date().getFullYear()} ©{' '}
          <a href="https://simpletexting.com" target="_blank" rel="noreferrer">
            SimpleTexting, LLC
          </a>
          . All rights reserved.
        </div>
      </aside>
    </>
  );
}
