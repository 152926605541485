import { ReactNode, useContext } from 'react';
import { TabContext } from '../tabs/Tabs';

export interface TabProps {
  id: string;
  children: ReactNode;
}

export function Tab(props: TabProps) {
  const currentTabId = useContext(TabContext);

  return <div>{currentTabId === props.id && props.children}</div>;
}
