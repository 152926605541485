import classNames from 'classnames';
import { ReactNode } from 'react';
import { ComponentTheme } from '../definitions';
import styles from './Button.module.scss';

/** Props for {@see Button} */
interface ButtonProps {
  /** Button theme. Supported all available {@see ComponentTheme}. */
  theme?: ComponentTheme;
  /** Handler that will be invoked when user clicked by button */
  onClick?: () => void;
  /** Type of button. In form you cat use `submit` for auto submit form. */
  type?: 'button' | 'submit';
  /** Flag that indicated that button is disabled */
  disabled?: boolean;
  children: ReactNode;
}

/** Button with different styles */
export function Button(props: ButtonProps) {
  return (
    <button
      className={classNames({
        [styles.button]: true,
        [styles.grayButton]: props.theme === ComponentTheme.Gray,
        [styles.redButton]: props.theme === ComponentTheme.Red,
        [styles.transparentButton]: props.theme === ComponentTheme.Transparent,
        [styles.transparentPrimaryButton]: props.theme === ComponentTheme.TransparentPrimary,
      })}
      onClick={props.onClick}
      type={props.type ?? 'button'}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
}
