import React, { MouseEvent } from 'react';
import ReactTooltip from 'react-tooltip';
import styles from './IconButton.module.scss';

interface IconButtonProps {
  children: string;
  tooltip?: string;
  onClick: () => void;
}

export function IconButton(props: IconButtonProps) {
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    props.onClick();
  };

  return (
    <>
      <button data-tip={props.tooltip} className={styles.button} onClick={handleClick}>
        {props.children}
      </button>

      <ReactTooltip place="bottom" className="st-tolltip-basic" effect="solid" delayShow={600} />
    </>
  );
}
