import { useDispatch, useSelector } from 'react-redux';
import { Validator } from '../../../../../../services';
import { setContacts } from '../../../../../../state/actions';
import { selectContacts } from '../../../../../../state/selectors';
import { AppState } from '../../../../../../state/types';
import { validateContacts } from '../../../../../../core/utils';
import { FormRow } from '../../../../../components';
import { Form, InputFormField } from '../../../../../form-components';
import { AccordionSection } from '../../../../../components';

interface FormConfig {
  companyName: string;
  phone: string;
  email: string;
  webSite: string;
}

function validateForm(values: FormConfig) {
  const errors: { [name in keyof typeof values]?: string } = {};

  if (!values.companyName) {
    errors['companyName'] = 'Enter company name';
  }

  if (!Validator.isEmail(values.email)) {
    errors['email'] = 'Enter valid email';
  }

  return errors;
}

function validateConfig(config: AppState['contacts']) {
  return validateContacts(config);
}

export function ContactsPanel() {
  const dispatch = useDispatch();
  const contacts = useSelector(selectContacts);

  const isValid = validateConfig(contacts);

  const handleChange = (values: AppState['contacts']) => {
    dispatch(setContacts(values));
  };

  return (
    <AccordionSection title="Contact settings" id="contacts" isHasError={!isValid}>
      <Form initialValues={contacts} onValuesChange={handleChange} validate={validateForm}>
        <FormRow>
          <InputFormField name="companyName" label="Company name" helperText="Your company name" />
        </FormRow>
        <FormRow>
          <InputFormField name="email" label="Email" helperText="Your email" />
        </FormRow>
      </Form>
    </AccordionSection>
  );
}
