import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';
import { AppState } from '../types';

/** Set part of application state from given remote state */
export const loadBackendState = createAction<Partial<AppState>>('loadBackendState');

/** Append reducer for {@see loadBackendState} action to Redux slice */
export const addLoadBackendStateReducer = (builder: ActionReducerMapBuilder<AppState>) => {
  builder.addCase(loadBackendState, (state, action) => {
    return {
      ...state,
      ...action.payload,
    };
  });
};
