import { Validator } from '../../services';
import { AppState } from '../../state/types';

/**
 * Validate `contacts` application state part. Returns `true`, if the contacts is valid.
 *
 * @param contacts Contacts application state part
 */
export function validateContacts(contacts: AppState['contacts']): boolean {
  return (
    !!contacts.companyName &&
    Validator.isPhone(contacts.phone) &&
    Validator.isEmail(contacts.email) &&
    Validator.isURL(contacts.webSite)
  );
}
