import {
  ClickActionType,
  FontFamily,
  FontWeight,
  HorizontalAlign,
  ImageFit,
  PoliciesLinkType,
  SmsType,
  WidgetType,
} from '../core/widgets/definitions';
import {
  CommonWidgetIds,
  DismissBehavior,
  FirstExperience,
  Template,
  TemplateTag,
  TimerType,
} from '../core/template';

export const modern: Template = {
  triggerButtonCloseButtonWidget: {
    widgetStyle: {
      borderRadius: 20,
      backgroundColor: '#FFFFFF',
      color: 'rgba(0, 27, 72, 0.31)',
      size: 14,
      margin: {
        top: -8,
        right: -8,
        left: -8,
      },
      shadow: {
        radius: 8,
        color: '#00000029',
      },
      padding: {
        left: 4,
        top: 4,
        right: 4,
        bottom: 4,
      },
    },
  },
  widgets: [
    {
      fileName: 'InnerSenseSquare.jpg',
      type: WidgetType.Image,
      id: CommonWidgetIds.LogoImage,
      localUrl: null,
      onClick: {
        url: 'https://innersensebeauty.com',
        type: ClickActionType.OpenLink,
      },
      widgetStyle: {
        align: HorizontalAlign.Center,
        fit: ImageFit.Contain,
        size: {
          width: 70,
          height: 70,
        },
      },
      url:
        'https://firebasestorage.googleapis.com/v0/b/simple-texting-tools.appspot.com/o/configs%2FWbVVHyGiUynHXlTrsJqzj%2Fimages%2FUuFoj7TD6wEPO0qkUu_zs.png?alt=media',
    },
    {
      id: CommonWidgetIds.PreHeadline,
      text: 'Learn more about the',
      widgetStyle: {
        fontSize: 15,
        margin: {
          top: 30,
        },
        fontFamily: FontFamily.Nunito,
        fontWeight: FontWeight.w400,
        color: '#75849C',
        textAlign: HorizontalAlign.Center,
      },
      type: WidgetType.Text,
    },
    {
      widgetStyle: {
        textAlign: HorizontalAlign.Center,
        fontSize: 49,
        color: '#502C1E',
        fontFamily: FontFamily.Lato,
        margin: {
          top: 10,
          bottom: 10,
        },
        fontWeight: FontWeight.w300,
      },
      text: '14 Day Detox',
      id: CommonWidgetIds.Headline,
      type: WidgetType.Text,
    },
    {
      text: 'through daily texts',
      id: CommonWidgetIds.SubHeadline,
      type: WidgetType.Text,
      widgetStyle: {
        color: '#75849C',
        textAlign: HorizontalAlign.Center,
        margin: {
          bottom: 48,
        },
        fontSize: 15,
        fontFamily: FontFamily.Nunito,
        fontWeight: FontWeight.w400,
      },
    },
    {
      onClick: {
        smsType: SmsType.Keyword,
        phone: '(844) 928-4252',
        text: '',
        type: ClickActionType.SendMessage,
      },
      type: WidgetType.Button,
      text: 'SIGN UP NOW',
      id: CommonWidgetIds.MainButton,
      widgetStyle: {
        padding: {
          right: 16,
          bottom: 13,
          top: 13,
          left: 16,
        },
        secondRowStyle: {
          fontWeight: FontWeight.w500,
          fontSize: 10,
          color: '#FFF',
          fontFamily: FontFamily.OpenSans,
        },
        firstRowStyle: {
          fontSize: 16,
          textAlign: HorizontalAlign.Center,
          color: '#FFFFFF',
          fontWeight: FontWeight.w700,
          fontFamily: FontFamily.Nunito,
        },
        borderRadius: 0,
        isExpanded: true,
        margin: {
          bottom: 0,
          right: null,
          top: 0,
          left: null,
        },
        border: {
          color: '',
          width: 0,
        },
        backgroundColor: '#846E4E',
      },
    },
    {
      text: 'No Thanks',
      onClick: {
        type: ClickActionType.ClosePopup,
      },
      widgetStyle: {
        isExpanded: true,
        backgroundColor: '#FFf',
        padding: {
          top: 13,
          bottom: 13,
          left: 16,
          right: 16,
        },
        margin: {
          top: 9,
          right: null,
          bottom: 32,
          left: null,
        },
        borderRadius: 0,
        firstRowStyle: {
          fontWeight: FontWeight.w600,
          fontSize: 12,
          fontFamily: FontFamily.Nunito,
          color: '#3D3D3D',
          textAlign: HorizontalAlign.Center,
        },
        secondRowStyle: {
          fontSize: 10,
          color: '#000',
          fontWeight: FontWeight.w500,
          fontFamily: FontFamily.OpenSans,
        },
        border: {
          width: 0,
          color: '#846E4E',
        },
      },
      id: CommonWidgetIds.DismissButton,
      type: WidgetType.Button,
    },
    {
      id: CommonWidgetIds.LegalText,
      smsRate: 4,
      type: WidgetType.LegalText,
      widgetStyle: {
        margin: {
          bottom: 16,
        },
        textAlign: HorizontalAlign.Center,
        fontWeight: FontWeight.w400,
        fontFamily: FontFamily.Rubik,
        color: '#75849C',
        fontSize: 9,
      },
    },
    {
      link: {
        type: PoliciesLinkType.AutoGenerated,
        complianceEmail: 'stephen+innersense@simpletexting.net',
        companyName: 'Innersense',
      },
      type: WidgetType.PoliciesText,
      widgetStyle: {
        fontWeight: FontWeight.w400,
        fontSize: 9,
        textAlign: HorizontalAlign.Center,
        margin: {
          bottom: 16,
        },
        fontFamily: FontFamily.Rubik,
        color: '#75849C',
      },
      id: CommonWidgetIds.PoliciesText,
    },
    {
      widgetStyle: {
        fontSize: 13,
        fontFamily: FontFamily.Rubik,
        textAlign: HorizontalAlign.Center,
        color: '#75849C',
        margin: {
          top: 16,
        },
        fontWeight: FontWeight.w400,
      },
      type: WidgetType.SimpleTextingCopyrightText,
      id: CommonWidgetIds.SimpleTextingCopyright,
    },
  ],
  popupCloseButtonWidget: {
    widgetStyle: {
      size: 20,
      color: 'rgba(0, 27, 72, 0.31)',
      padding: {
        left: 8,
        top: 8,
        bottom: 8,
        right: 8,
      },
    },
  },
  widgetPresets: {
    buttons: {
      rounded: {
        borderRadius: 4,
      },
      ghost: {
        borderRadius: 0,
        border: {
          width: 1,
        },
      },
      singleLine: {
        padding: {
          top: 13,
          right: 16,
          bottom: 13,
          left: 16,
        },
      },
      doubleLine: {
        padding: {
          left: 16,
          right: 16,
          bottom: 8,
          top: 8,
        },
      },
    },
    popup: {
      overlay: {
        color: '#33333320',
        blurRadius: 2,
      },
    },
  },
  info: {
    tags: [TemplateTag.FullSize],
    name: 'Modern',
    previewImageUrl: '/assets/themes/modern.png',
  },
  userEngagement: {
    timer: {
      type: TimerType.Immediately,
    },
    firstExperience: FirstExperience.TriggerButton,
    dismissBehavior: DismissBehavior.ShowTriggerButton,
  },
  horizontalPosition: HorizontalAlign.Right,
  mainContainerWidget: {
    widgetStyle: {
      backgroundColor: '#FFFFFF',
      margin: {
        bottom: 12,
        left: 12,
        right: 12,
        top: 12,
      },
      padding: {
        left: 32,
        right: 32,
        bottom: 32,
        top: 32,
      },
      shadow: {
        color: '#00000029',
        radius: 40,
      },
      borderRadius: 12,
    },
  },
  triggerButtonWidget: {
    text: 'Detox me',
    widgetStyle: {
      secondRowStyle: {
        color: '#FFF',
        fontWeight: FontWeight.w500,
        fontFamily: FontFamily.OpenSans,
        fontSize: 10,
      },
      border: {
        width: 0,
        color: '',
      },
      backgroundColor: '#846E4E',
      padding: {
        right: 16,
        bottom: 13,
        left: 16,
        top: 13,
      },
      borderRadius: 0,
      isExpanded: true,
      firstRowStyle: {
        color: '#FFF',
        fontWeight: FontWeight.w500,
        fontFamily: FontFamily.OpenSans,
        fontSize: 16,
      },
      margin: {
        right: 12,
        left: 12,
        bottom: 12,
        top: 12,
      },
    },
  },
};
