import { Accordion, EditPanel, EditPanelMainSection } from '../../../components';
import {
  ContactSettingsPanel,
  CopyPanel,
  ImagesPanel,
  LegalPanel,
  TemplatePanel,
  StylingPanel,
  UserEngagementPanel,
} from './panels';

export function BaseWidgetsConfigPanel() {
  return (
    <EditPanel>
      <EditPanelMainSection title="General" noMargins={true}>
        <Accordion>
          <ContactSettingsPanel />
          <UserEngagementPanel />
        </Accordion>
      </EditPanelMainSection>

      <EditPanelMainSection title="Appearance">
        <Accordion>
          <TemplatePanel />
          <StylingPanel />
        </Accordion>
      </EditPanelMainSection>

      <EditPanelMainSection title="Content">
        <Accordion>
          <ImagesPanel />
          <CopyPanel />
          <LegalPanel />
        </Accordion>
      </EditPanelMainSection>
    </EditPanel>
  );
}
