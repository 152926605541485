import classNames from 'classnames';
import { useField } from 'formik';
import { ReactNode } from 'react';
import { ComponentTheme } from '../../components';
import { FormField } from '../form-field/FormField';
import styles from './TextareaFormField.module.scss';

interface InputFormFieldProps {
  name: string;
  label: string;
  rows?: number;
  theme?: ComponentTheme;
  placeholder?: string;
  helperText?: ReactNode;
  disabled?: boolean;
}

export function TextareaFormField(props: InputFormFieldProps) {
  const [field, meta] = useField(props as any);

  return (
    <FormField
      label={props.label}
      theme={props.theme}
      error={meta.error}
      helperText={props.helperText}
      disabled={props.disabled}
    >
      <textarea
        {...field}
        placeholder={props.placeholder}
        rows={props.rows}
        className={classNames({
          [styles.input]: true,
          [styles.inputTransparent]: props.theme === ComponentTheme.Transparent,
          [styles.hasError]: meta.error,
          [styles.isDisabled]: props.disabled,
        })}
        disabled={props.disabled}
      />
    </FormField>
  );
}
