import { hexToRgb } from './hexToRgb';

/**
 * Return a contrast color to the color written in HEX format like `#000`
 *
 * @param hex Color written in HEX format
 * @param opacity Color opacity
 */
export function contrastToHexColor(hex?: string | null, opacity?: number) {
  if (!hex) {
    return '#000';
  }

  const rgb = hexToRgb(hex);

  return calculateContrastColor(rgb, opacity);
}

/**
 * Returns color that contrast to color written in RGB format
 *
 * @param rgb Array with [red, green, blue] RGB color parts
 * @param opacity Color opacity
 */
function calculateContrastColor(rgb: [number, number, number], opacity?: number) {
  const sum = Math.round((rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000);
  if (opacity === undefined) {
    return sum > 128 ? '#000' : '#FFF';
  } else {
    return sum > 128 ? `rgba(0, 0, 0, ${opacity})` : `rgba(225, 225, 225, ${opacity})`;
  }
}
