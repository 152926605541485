import { CSSProperties } from 'react';
import { useDispatch } from 'react-redux';
import { openEditPanelAccordionSection } from '../../../state/actions';
import { ContainerWidget } from '../release/ContainerWidget';
import { ContainerWidgetConfig } from '../types';
import { DebugWidget } from './DebugWidget';
import styles from './DebugWidget.module.css';

/** Pros of {@see DebugContainerWidget} */
type DebugContainerWidgetProps = ContainerWidgetConfig & {
  /** Custom CSS styles */
  style?: CSSProperties;
};

/**
 * Implementation of container widget that used in development. Support selections and custom
 * CSS styles
 */
export function DebugContainerWidget(props: DebugContainerWidgetProps) {
  const dispatch = useDispatch();

  const handleClick = () => {
    if (props.id) {
      dispatch(openEditPanelAccordionSection({ sectionId: props.id, useAutoScroll: true }));
    }
  };

  return (
    <div className={styles.debugWidgetContainer} style={props.style}>
      <div className={styles.debugWidgetInteractive} onClick={handleClick} />

      <ContainerWidget
        {...props}
        style={{
          transition: 'all 300ms ease',
        }}
        widgetRender={DebugWidget}
      />
    </div>
  );
}
