import { Validator } from '../../services';
import { ClickActionType } from '../widgets/definitions';
import { WithOnClick } from '../widgets/types';

/**
 * Validate `onClick` widget configuration. Returns `true` if configuration is valid.
 *
 * @param onClick Any `onClick` widget configuration.
 */
export function validateOnClick(onClick?: WithOnClick['onClick']): boolean {
  switch (onClick?.type) {
    case ClickActionType.SendMessage:
      return Validator.isPhone(onClick?.phone);
    case ClickActionType.OpenLink:
      return Validator.isURL(onClick?.url);
  }

  return true;
}
