import { CSSProperties, ReactNode } from 'react';
import { AppConfig } from '../../appConfig';
import { PoliciesLinkType, WidgetType } from '../definitions';
import { baseWidgetStyleToCss } from '../utils';
import {
  LegalTextWidgetConfig,
  PoliciesTextWidgetConfig,
  SimpleTextingCopyrightWidgetConfig,
  TextStyle,
  TextWidgetConfig,
} from '../types';

/** Props for {@see TextWidget} */
type TextWidgetProps = (
  | TextWidgetConfig
  | LegalTextWidgetConfig
  | PoliciesTextWidgetConfig
  | SimpleTextingCopyrightWidgetConfig
) & {
  /** Custom CSS styles */
  style?: CSSProperties;
};

/** Implementation of text widget */
export function TextWidget(props: TextWidgetProps) {
  let text: ReactNode = 'text' in props ? props.text : '';
  switch (props.type) {
    case WidgetType.LegalText:
      text = `By texting this number, you agree to receive promotional messages sent via an autodialer, and this agreement isn’t a condition of any purchase. Reply STOP to unsubscribe or HELP for help. Estim. ${props.smsRate} msgs/month. Msg&Data rates may apply.`;
      break;
    case WidgetType.PoliciesText:
      switch (props.link?.type) {
        case PoliciesLinkType.Manual:
          text = (
            <>
              <a
                style={{
                  color: 'inherit',
                  cursor: 'pointer',
                }}
                href={props.link?.termsConditionsLink}
              >
                Terms
              </a>{' '}
              &{' '}
              <a
                style={{
                  color: 'inherit',
                  cursor: 'pointer',
                }}
                href={props.link?.privacyPolicyLink}
              >
                Privacy Policy Information
              </a>
            </>
          );
          break;
        case PoliciesLinkType.AutoGenerated:
          const url = new URL(`${AppConfig.backendUrl}/privacy`);
          url.searchParams.append('email', props.link?.complianceEmail ?? '');
          url.searchParams.append('company', props.link?.companyName ?? '');

          text = (
            <a
              style={{
                color: 'inherit',
                cursor: 'pointer',
              }}
              href={url.toString()}
            >
              Terms & Privacy Policy Information
            </a>
          );
          break;
      }
      break;
    case WidgetType.SimpleTextingCopyrightText:
      text = (
        <>
          Powered by{' '}
          <a
            style={{
              color: 'inherit',
              cursor: 'pointer',
            }}
            href="https://simpletexting.com"
          >
            SimpleTexting, LLC
          </a>
        </>
      );
      break;
  }

  return (
    <>
      {text && (
        <div
          style={{
            ...textWidgetStyleToCss(props.widgetStyle),
            ...props.style,
          }}
        >
          {text}
        </div>
      )}
    </>
  );
}

function textWidgetStyleToCss(style?: TextStyle): CSSProperties {
  return {
    ...baseWidgetStyleToCss(style),
    textAlign: style?.textAlign ?? 'center',
    fontFamily: style?.fontFamily,
    lineHeight: style?.fontLineHeight ?? 1,
    fontSize: style?.fontSize ?? 12,
    fontWeight: style?.fontWeight as any, // We use numeric fontWeight instead of names
    color: style?.color ?? 'black',
  };
}
