import { CSSProperties, ReactNode } from 'react';
import { baseWidgetStyleToCss } from '../utils';
import { RowWidgetConfig, WidgetConfig } from '../types';

/** Function that used for render child widgets */
type WidgetRender = (props: WidgetConfig & { style?: CSSProperties }) => ReactNode;

/** Props for {@see RowWidget} */
interface RowWidgetProps extends RowWidgetConfig {
  /** Function that used for render child widgets */
  widgetRender: WidgetRender;
  /** Custom CSS styles */
  style?: CSSProperties;
  /** Custom CSS styles of every children */
  childrenStyle?: CSSProperties;
}

/** Implementation of row widget */
export function RowWidget(props: RowWidgetProps) {
  return (
    <ul
      style={{
        ...baseWidgetStyleToCss(props.widgetStyle),
        display: 'flex',
        flexDirection: 'row',
        margin: 0,
        padding: 0,
        listStyle: 'none',
        ...props.style,
      }}
    >
      {props.children.map((childProps, index) => {
        return (
          <li key={childProps.id ?? index.toString()}>
            {props.widgetRender({ ...childProps, style: props.childrenStyle })}
          </li>
        );
      })}
    </ul>
  );
}
