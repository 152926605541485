import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setConfigId } from '../../state/actions';
import { GalleryPage, CustomizerPage, PreviewPage } from '../pages';
import styles from './Layout.module.scss';
import { Header } from './header/Header';
import { Switch, Route, useLocation, useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';

export function Layout() {
  const dispatch = useDispatch();
  const { configId } = useParams<{ configId?: string }>();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (configId) {
      dispatch(setConfigId(configId));
    }
  }, [configId, dispatch]);

  useEffect(() => {
    if (location.pathname !== '/') {
      const parts = location.pathname.split('/');
      if (parts.length === 2) {
        history.replace(`${parts[1]}/customizer`);
      }
    }
  }, [history, location.pathname]);

  return (
    <main className={styles.layout}>
      <Header />

      <Switch>
        <Route path="/:configId/customizer">
          <CustomizerPage />
        </Route>
        <Route path="/:configId/gallery">
          <GalleryPage />
        </Route>
        <Route path="/:configId/preview">
          <PreviewPage />
        </Route>
        <Route path="/">
          <GalleryPage />
        </Route>
      </Switch>
    </main>
  );
}
