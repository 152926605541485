import { WidgetConfig, WithMargin } from '../../../../../../core/widgets/types';
import { EditPanelSection } from '../../../../../components';
import { Form, InputFormField, InputType } from '../../../../../form-components';
import { useStyleConfigHelpers } from '../../hooks';
import { WidgetStyleConfigPanelProps } from '../types';
import styles from './MarginConfigSection.module.scss';

interface WithMarginConfig {
  marginLeft: string;
  marginRight: string;
  marginTop: string;
  marginBottom: string;
}

export function MarginConfigSection<T extends WidgetConfig>(
  props: WidgetStyleConfigPanelProps<WithMargin, T> & { negativeAccept?: boolean }
) {
  const [widgetStyle, onChange] = useStyleConfigHelpers(props);

  const initialValue: WithMarginConfig = {
    marginLeft: widgetStyle?.margin?.left?.toFixed(0) ?? '',
    marginRight: widgetStyle?.margin?.right?.toFixed(0) ?? '',
    marginTop: widgetStyle?.margin?.top?.toFixed(0) ?? '',
    marginBottom: widgetStyle?.margin?.bottom?.toFixed(0) ?? '',
  };

  const handleChange = (formValues: WithMarginConfig) => {
    onChange({
      margin: {
        left: formValues.marginLeft !== '' ? Number(formValues.marginLeft) : null,
        right: formValues.marginRight !== '' ? Number(formValues.marginRight) : null,
        top: formValues.marginTop !== '' ? Number(formValues.marginTop) : null,
        bottom: formValues.marginBottom !== '' ? Number(formValues.marginBottom) : null,
      },
    });
  };

  const inputType = props.negativeAccept ? InputType.Numbers : InputType.PositiveNumbers;

  return (
    <EditPanelSection title="Margin">
      <Form initialValues={initialValue} onValuesChange={handleChange}>
        <div className={styles.margin}>
          <div className={styles.top}>
            <InputFormField name="marginTop" label="Top" type={inputType} />
          </div>
          <div className={styles.leftAndRight}>
            <div className={styles.left}>
              <InputFormField name="marginLeft" label="Left" type={inputType} />
            </div>
            <div className={styles.right}>
              <InputFormField name="marginRight" label="Right" type={inputType} />
            </div>
          </div>
          <div className={styles.bottom}>
            <InputFormField name="marginBottom" label="Bottom" placeholder="0" type={inputType} />
          </div>
        </div>
      </Form>
    </EditPanelSection>
  );
}
