/** Supported widget types */
export enum WidgetType {
  /** Container that cat contains one child widget */
  Container = 'Container',
  /** Widget that display child widgets in row */
  Row = 'Row',
  /** Widget that display child widgets in column */
  Column = 'Column',
  /** Clickable text button */
  Button = 'Button',
  /** Simple text */
  Text = 'Text',
  /** Clickable image */
  Image = 'Image',
  /** A clickable button with icon */
  IconButton = 'IconButton',
  /** Standard legal text */
  LegalText = 'LegalText',
  /** Standard policies text */
  PoliciesText = 'PoliciesText',
  /** Standard simple texting copyright */
  SimpleTextingCopyrightText = 'SimpleTextingCopyrightText',
}

/**
 * Supported text fonts.
 *
 * All fonts will be loaded from Google Fonts.
 */
export enum FontFamily {
  Arvo = "'Arvo', serif",
  CrimsonText = "'Crimson Text', serif",
  HeptaSlab = "'Hepta Slab', serif",
  KumbhSans = "Kumbh Sans', sans-serif",
  Lato = "'Lato', sans-serif",
  Lora = "'Lora', serif",
  Merriweather = "'Merriweather', serif",
  Montserrat = "'Montserrat', sans-serif",
  Nunito = "'Nunito', sans-serif",
  OpenSans = "'Open Sans', sans-serif",
  Oswald = "'Oswald', sans-serif",
  PlayfairDisplay = "'Playfair Display', serif",
  Poppins = "'Poppins', sans-serif",
  Roboto = "'Roboto', sans-serif",
  Rubik = "'Rubik', sans-serif",
  SourceSerifPro = "'Source Serif Pro', serif",
}

/** Supported font weights */
export enum FontWeight {
  /** Thin */
  w100 = '100',
  /** Extra light */
  w200 = '200',
  /** Light */
  w300 = '300',
  /** Normal */
  w400 = '400',
  /** Medium */
  w500 = '500',
  /** Semi bold */
  w600 = '600',
  /** Bold */
  w700 = '700',
  /** Extra bold */
  w800 = '800',
  /** Black */
  w900 = '900',
}

/** Supported horizontal alignment */
export enum HorizontalAlign {
  Center = 'center',
  Left = 'left',
  Right = 'right',
}

// export enum ButtonShape {
//   Rounded,
//   Square,
//   Pill,
// }

/** Supported CSS image fit */
export enum ImageFit {
  Contain = 'contain',
  Cover = 'cover',
  Fill = 'fill',
}

/**
 * Supported widget click action types.
 *
 * Some widget (buttons or images) supported clicks.
 */
export enum ClickActionType {
  /** Click will be ignored */
  None = 'none',
  /** By click some URL will be opened */
  OpenLink = 'openLink',
  /** By click some SMS will be prepared to send */
  SendMessage = 'sendMessage',
  /** By click popup will be closed */
  ClosePopup = 'closePopup',
}

/** Type of SMS */
export enum SmsType {
  /** SimpleTexting keyword */
  Keyword = 'keyword',
  /** Any text */
  Text = 'text',
}

/** Policies text link strategy */
export enum PoliciesLinkType {
  /** Use link to standard auto policies */
  AutoGenerated = 'auto',
  /** Use link to custom user policies */
  Manual = 'manual',
}
