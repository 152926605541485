import { Dispatch } from 'react';
import { useDispatch } from 'react-redux';
import { Validator } from '../../../../../../services';
import { CommonWidgetIds, Template } from '../../../../../../core/template';
import { findTemplateWidget, validateOnClick } from '../../../../../../core/utils';
import { ClickActionType, WidgetType } from '../../../../../../core/widgets/definitions';
import { mergeWidgetConfig } from '../../../../../../state/actions';
import { UseFormResult } from '../../types';

interface FormConfig {
  logoUrl: string;
  logoFileName: string;
  logoLocalUrl: string;
  logoLink: string;
  heroUrl: string;
  heroFileName: string;
  heroLocalUrl: string;
  backgroundUrl: string;
  backgroundFileName: string;
  backgroundLocalUrl: string;
}

function getWidgets(template: Template) {
  const mainContainer = findTemplateWidget(
    template,
    CommonWidgetIds.MainContainer,
    WidgetType.Container
  );
  const logoImage = findTemplateWidget(template, CommonWidgetIds.LogoImage, WidgetType.Image);
  const heroImage = findTemplateWidget(template, CommonWidgetIds.HeroImage, WidgetType.Image);

  return {
    mainContainer,
    logoImage,
    heroImage,
  };
}

function formInitialValue(template: Template): FormConfig {
  const { mainContainer, logoImage, heroImage } = getWidgets(template);

  return {
    logoUrl: logoImage?.url ?? '',
    logoLocalUrl: logoImage?.localUrl ?? '',
    logoFileName: logoImage?.fileName ?? '',
    logoLink:
      (logoImage?.onClick?.type === ClickActionType.OpenLink ? logoImage.onClick.url : '') ?? '',
    heroUrl: heroImage?.url ?? '',
    heroLocalUrl: heroImage?.localUrl ?? '',
    heroFileName: heroImage?.fileName ?? '',
    backgroundUrl: mainContainer?.widgetStyle?.backgroundImage?.url ?? '',
    backgroundLocalUrl: mainContainer?.widgetStyle?.backgroundImage?.localUrl ?? '',
    backgroundFileName: mainContainer?.widgetStyle?.backgroundImage?.fileName ?? '',
  };
}

const saveFormValues = (template: Template, dispatch: Dispatch<any>) => (values: FormConfig) => {
  const { mainContainer, logoImage, heroImage } = getWidgets(template);

  const onClick = values.logoLink
    ? {
        type: ClickActionType.OpenLink,
        url: values.logoLink,
      }
    : {
        type: ClickActionType.None,
      };

  if (mainContainer) {
    dispatch(
      mergeWidgetConfig({
        ...mainContainer,
        widgetStyle: {
          backgroundImage: {
            url: values.backgroundUrl || undefined,
            localUrl: values.backgroundLocalUrl || null,
            fileName: values.backgroundFileName || undefined,
            opacity: 30,
          },
        },
      })
    );
  }

  if (logoImage) {
    dispatch(
      mergeWidgetConfig({
        ...logoImage,
        onClick,
        url: values.logoUrl || undefined,
        localUrl: values.logoLocalUrl || null,
        fileName: values.logoFileName || undefined,
      })
    );
  }

  if (heroImage) {
    dispatch(
      mergeWidgetConfig({
        ...heroImage,
        url: values.heroUrl || undefined,
        localUrl: values.heroLocalUrl || null,
        fileName: values.heroFileName || undefined,
      })
    );
  }
};

function validateForm(values: FormConfig): { [name in keyof FormConfig]?: string } {
  const errors: { [name in keyof FormConfig]?: string } = {};

  if (values.logoLink && !Validator.isURL(values.logoLink)) {
    errors['logoLink'] = 'Enter valid URL';
  }

  return errors;
}

function isValid(template: Template) {
  const { logoImage } = getWidgets(template);

  if (logoImage) {
    return validateOnClick(logoImage?.onClick);
  }

  return true;
}

export function useForm(template: Template): UseFormResult<FormConfig> {
  const dispatch = useDispatch();
  const initialValue = formInitialValue(template);

  return {
    initialValues: initialValue,
    saveFormValues: saveFormValues(template, dispatch),
    validateForm,
    isValid: isValid(template),
  };
}
