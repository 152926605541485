import {
  Form,
  InputFormField,
  InputType,
  SelectFormField,
  SelectValue,
  HorizontalAlignFormField,
} from '../../../../../form-components';
import { HorizontalAlign, ImageFit } from '../../../../../../core/widgets/definitions';
import { ImageStyle, WidgetConfig } from '../../../../../../core/widgets/types';
import { useStyleConfigHelpers } from '../../hooks/useStyleConfigHelpers';
import { FormRow } from '../../../../../components/form-row/FormRow';
import { WidgetStyleConfigPanelProps } from '../types';

const fitOptions: SelectValue[] = [
  { value: ImageFit.Contain, label: 'Shrink proportionally' },
  { value: ImageFit.Cover, label: 'Fill in proportionally' },
  { value: ImageFit.Fill, label: 'Fill in not proportionally' },
];

export interface FormConfig {
  sizeWidth: string;
  sizeHeight: string;
  align: HorizontalAlign;
  fit: ImageFit;
}

export function ImageConfig<T extends WidgetConfig>(
  props: WidgetStyleConfigPanelProps<ImageStyle, T>
) {
  const [widgetStyle, onChange] = useStyleConfigHelpers(props);

  const initialValue: FormConfig = {
    sizeWidth: widgetStyle?.size?.width?.toFixed(0) ?? '',
    sizeHeight: widgetStyle?.size?.height?.toFixed(0) ?? '',
    align: widgetStyle?.align ?? HorizontalAlign.Center,
    fit: widgetStyle?.fit ?? ImageFit.Contain,
  };

  const handleChange = (formValues: FormConfig) => {
    onChange({
      size: {
        width: formValues.sizeWidth !== '' ? Number(formValues.sizeWidth) : null,
        height: formValues.sizeHeight !== '' ? Number(formValues.sizeHeight) : null,
      },
      align: formValues.align,
      fit: formValues.fit,
    });
  };

  return (
    <Form initialValues={initialValue} onValuesChange={handleChange}>
      <FormRow>
        <InputFormField
          name="sizeWidth"
          label="Width"
          type={InputType.PositiveNumbers}
          placeholder="Auto"
        />
        <InputFormField
          name="sizeHeight"
          label="Height"
          type={InputType.PositiveNumbers}
          placeholder="Auto"
        />
      </FormRow>
      <FormRow>
        <SelectFormField name="fit" label="Size mode" options={fitOptions} />
      </FormRow>
      <FormRow>
        <HorizontalAlignFormField name="align" label="Horizontal align" />
      </FormRow>
    </Form>
  );
}
