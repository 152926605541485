import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';
import { AppState } from '../types';

/** Set current preview URL to given */
export const setPreviewUrl = createAction<string>('setPreviewUrl');

/** Append reducer for {@see setPreviewUrl} action to Redux slice */
export const addSetPreviewUrlReducer = (builder: ActionReducerMapBuilder<AppState>) => {
  builder.addCase(setPreviewUrl, (state, action) => {
    state.preview.url = action.payload;
  });
};
