import classNames from 'classnames';
import { useState, MouseEvent as ReactMouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import {
  selectIsTemplateChanged,
  selectIsTemplateLoaded,
  selectIsTemplateSaved,
} from '../../../state/selectors';
import { downloadTemplate } from '../../../state/thunks/downloadTemplate';
import { PublishedModal, PublishModal, RevertTemplateModal } from '../../modals';
import logoImage from './st-logo.svg';
import styles from './Header.module.scss';

export function Header() {
  const dispatch = useDispatch();

  const isTemplateLoaded = useSelector(selectIsTemplateLoaded);
  const isTemplateChanged = useSelector(selectIsTemplateChanged);
  const isTemplateSaved = useSelector(selectIsTemplateSaved);

  const draftText = isTemplateChanged ? 'Unpublished draft' : 'Published version';

  const [isRevertModalVisible, setRevertModalVisible] = useState(false);
  const [isPublishModalVisible, setPublishModalVisible] = useState(false);
  const [isPublishedModalVisible, setPublishedModalVisible] = useState(false);

  const handleRevertTemplateClick = () => {
    setRevertModalVisible(true);
  };

  const handlePublishClick = (event: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (event.altKey && event.shiftKey) {
      dispatch(downloadTemplate());
      return;
    }

    if (isTemplateChanged) {
      setPublishModalVisible(true);
    } else {
      setPublishedModalVisible(true);
    }
  };

  return (
    <header className={styles.header}>
      <a href="https://simpletexting.com/">
        <img className={styles.logo} src={logoImage} alt="SimpleTexting" />
      </a>

      <nav className={styles.nav}>
        <ul className={styles.tabs}>
          <li className={styles.tab}>
            <NavLink className={styles.link} activeClassName={styles.activeLink} to="gallery">
              Templates
            </NavLink>
          </li>
          <li className={styles.tab}>
            {isTemplateLoaded ? (
              <NavLink className={styles.link} activeClassName={styles.activeLink} to="customizer">
                Customizer
              </NavLink>
            ) : (
              <div className={classNames(styles.link, styles.disabledLink)}>Customizer</div>
            )}
          </li>
          <li className={styles.tab}>
            {isTemplateLoaded ? (
              <>
                <NavLink className={styles.link} activeClassName={styles.activeLink} to="preview">
                  Preview
                </NavLink>
              </>
            ) : (
              <div className={classNames(styles.link, styles.disabledLink)}>Preview</div>
            )}
          </li>
        </ul>
      </nav>

      <div className={styles.rightPart}>
        <div className={styles.unpublishedTooltip}>
          {draftText}
          {isTemplateChanged && isTemplateSaved && (
            <button className={styles.revertButton} onClick={handleRevertTemplateClick}>
              Revert
            </button>
          )}
        </div>
        <button
          className={styles.publishButton}
          onClick={handlePublishClick}
          disabled={!isTemplateLoaded}
        >
          Save & Publish
        </button>
      </div>

      {isRevertModalVisible && (
        <RevertTemplateModal onRequestClose={() => setRevertModalVisible(false)} />
      )}
      {isPublishModalVisible && (
        <PublishModal
          onRequestClose={() => setPublishModalVisible(false)}
          onPublished={() => {
            setPublishModalVisible(false);
            setPublishedModalVisible(true);
          }}
        />
      )}
      {isPublishedModalVisible && (
        <PublishedModal onRequestClose={() => setPublishedModalVisible(false)} />
      )}
    </header>
  );
}
