import ReactModal from 'react-modal';
import { ReactNode } from 'react';
import { IconButton } from '../../../components/icon-button/IconButton';
import styles from './Modal.module.scss';

interface ModalProps {
  title?: string;
  width?: number;
  isOpen?: boolean;
  onRequestClose: () => void;
  children: ReactNode;
  disableClose?: boolean;
}

export function Modal(props: ModalProps) {
  const handleRequestClose = () => {
    if (props.disableClose) {
      return;
    }

    if (props.onRequestClose) {
      props.onRequestClose();
    }
  };

  return (
    <ReactModal
      className={styles.modal}
      overlayClassName={styles.overlay}
      isOpen={props.isOpen ?? false}
      onRequestClose={handleRequestClose}
      style={{
        content: {
          width: props.width,
        },
      }}
    >
      <div className={styles.titleRow}>
        {props.title && <h1 className={styles.title}>{props.title}</h1>}
        {!props.disableClose && (
          <div className={styles.close}>
            <IconButton onClick={props.onRequestClose} tooltip="Close modal">
              close
            </IconButton>
          </div>
        )}
      </div>

      <div className={styles.content}>{props.children}</div>
    </ReactModal>
  );
}
