import { createSlice } from '@reduxjs/toolkit';
import {
  addAddWidgetReducer,
  addInitializeAppReducer,
  addLoadBackendStateReducer,
  addLoadTemplateReducer,
  addMergeWidgetConfigReducer,
  addMoveWidgetToIndexReducer,
  addOpenEditPanelAccordionSectionReducer,
  addOpenEditPanelTabReducer,
  addRemoveWidgetReducer,
  addReplaceTemplateReducer,
  addRevertTemplateReducer,
  addSetConfigIdReducer,
  addSetContactsReducer,
  addSetExpertModeEnabledReducer,
  addSetMainHorizontalPositionReducer,
  addSetPreviewDeviceReducer,
  addSetPreviewUrlReducer,
  addSetUserEngagementReducer,
  addToggleWidgetVisibilityReducer,
} from './actions';
import {
  addPublishFulfilledReducer,
  addPublishPendingReducer,
  addPublishRejectedReducer,
} from './thunks/publish';
import { AppState } from './types';

/** Initial state of general application Redux store */
const initialState: AppState = {
  configId: '',
  isTemplateChanged: false,
  contacts: {
    companyName: '',
    phone: '',
    email: '',
    webSite: '',
  },
  preview: {
    deviceScreen: '360x640', // Galaxy S5
    url: '',
  },
  editPanel: {
    isExpertModeEnabled: false,
    isAccordionAutoScrollEnabled: false,
    expandedAccordionSectionId: '',
    activeTabId: '',
  },
};

/** General application Redux toolkit slice */
export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    addInitializeAppReducer(builder);
    addLoadBackendStateReducer(builder);
    addLoadTemplateReducer(builder);
    addReplaceTemplateReducer(builder);
    addMoveWidgetToIndexReducer(builder);
    addSetPreviewDeviceReducer(builder);
    addSetPreviewUrlReducer(builder);
    addSetContactsReducer(builder);
    addSetUserEngagementReducer(builder);
    addOpenEditPanelAccordionSectionReducer(builder);
    addMergeWidgetConfigReducer(builder);
    addRemoveWidgetReducer(builder);
    addAddWidgetReducer(builder);
    addSetExpertModeEnabledReducer(builder);
    addOpenEditPanelTabReducer(builder);
    addToggleWidgetVisibilityReducer(builder);
    addSetConfigIdReducer(builder);
    addRevertTemplateReducer(builder);
    addPublishPendingReducer(builder);
    addPublishFulfilledReducer(builder);
    addPublishRejectedReducer(builder);
    addSetMainHorizontalPositionReducer(builder);
  },
});
