import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectTemplate } from '../../../../state/selectors';
import { CommonWidgetIds } from '../../../../core/template';
import {
  Button,
  Accordion,
  EditPanel,
  ComponentTheme,
  EditPanelMainSection,
} from '../../../components';
import { WidgetType } from '../../../../core/widgets/definitions';
import { AddWidgetModal } from '../../../modals';
import {
  ButtonWidgetPanel,
  ContactsPanel,
  ContainerWidgetPanel,
  IconButtonWidgetPanel,
  ImageWidgetPanel,
  LegalTextWidgetPanel,
  PoliciesTextWidgetPanel,
  SimpleTextingCopyrightTextWidgetPanel,
  TextWidgetPanel,
  UserEngagementPanel,
} from './panels';
import styles from './ExpertWidgetsConfigPanel.module.scss';

export function ExpertWidgetsConfigPanel() {
  const template = useSelector(selectTemplate);

  const [addWidgetModalVisible, setAddWidgetModalVisible] = useState(false);

  const handleAddWidgetClick = () => {
    setAddWidgetModalVisible(true);
  };

  return (
    <EditPanel>
      <EditPanelMainSection title="General" noMargins={true}>
        <Accordion>
          <ContactsPanel />
          <UserEngagementPanel />
        </Accordion>
      </EditPanelMainSection>

      <EditPanelMainSection title="Base widgets">
        <Accordion>
          <ButtonWidgetPanel id={CommonWidgetIds.TriggerButton} />
          <IconButtonWidgetPanel
            id={CommonWidgetIds.TriggerButtonCloseButton}
            type="Trigger close button"
          />
          <ContainerWidgetPanel id={CommonWidgetIds.MainContainer} type="Popup" />
          <IconButtonWidgetPanel id={CommonWidgetIds.PopupCloseButton} type="Popup close button" />
        </Accordion>
      </EditPanelMainSection>

      <EditPanelMainSection title="Content widgets">
        <Accordion>
          {template.widgets.map((widget) => {
            switch (widget.type) {
              case WidgetType.LegalText:
                return <LegalTextWidgetPanel key={widget.id} id={widget.id} />;
              case WidgetType.PoliciesText:
                return <PoliciesTextWidgetPanel key={widget.id} id={widget.id} />;
              case WidgetType.SimpleTextingCopyrightText:
                return <SimpleTextingCopyrightTextWidgetPanel key={widget.id} id={widget.id} />;
              case WidgetType.Text:
                return <TextWidgetPanel key={widget.id} id={widget.id} />;
              case WidgetType.Button:
                return <ButtonWidgetPanel key={widget.id} id={widget.id} />;
              case WidgetType.Image:
                return <ImageWidgetPanel key={widget.id} id={widget.id} />;
            }

            return null;
          })}
        </Accordion>

        <div className={styles.addWidget}>
          <Button theme={ComponentTheme.Transparent} onClick={handleAddWidgetClick}>
            Add element
          </Button>
        </div>
      </EditPanelMainSection>

      {addWidgetModalVisible && (
        <AddWidgetModal onRequestClose={() => setAddWidgetModalVisible(false)} />
      )}
    </EditPanel>
  );
}
