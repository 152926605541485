import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';
import { AppState } from '../types';

/** Set contacts data to given */
export const setContacts = createAction<AppState['contacts']>('setContacts');

/** Append reducer for {@see setContacts} action to Redux slice */
export const addSetContactsReducer = (builder: ActionReducerMapBuilder<AppState>) => {
  builder.addCase(setContacts, (state, action) => {
    state.contacts = action.payload;
  });
};
