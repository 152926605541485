import { WidgetConfig, WithBackgroundImage } from '../../../../../../core/widgets/types';
import { EditPanelSection, FormRow } from '../../../../../components';
import { Form, ImageFormField, InputFormField, InputType } from '../../../../../form-components';
import { useStyleConfigHelpers } from '../../hooks';
import { WidgetStyleConfigPanelProps } from '../types';

interface WithBackgroundColorConfig {
  imageFileName: string;
  opacity: string;
}

export function BackgroundImageConfigSection<T extends WidgetConfig>(
  props: WidgetStyleConfigPanelProps<WithBackgroundImage, T>
) {
  const [widgetStyle, onChange] = useStyleConfigHelpers(props);

  const initialValue: WithBackgroundColorConfig = {
    imageFileName: widgetStyle?.backgroundImage?.fileName ?? '',
    opacity: widgetStyle?.backgroundImage?.opacity?.toFixed() ?? '',
  };

  const handleChange = (formValues: WithBackgroundColorConfig) => {
    if (widgetStyle?.backgroundImage) {
      onChange({
        backgroundImage: {
          ...widgetStyle?.backgroundImage,
          opacity: formValues.opacity ? Number(formValues.opacity) : null,
        },
      });
    }
  };

  return (
    <EditPanelSection title="Background image">
      <Form initialValues={initialValue} onValuesChange={handleChange}>
        <FormRow>
          <ImageFormField
            name="imageFileName"
            label="Image"
            onClear={() => onChange({ backgroundImage: null })}
            onChanged={(backgroundImage) => onChange({ backgroundImage: backgroundImage as any })}
          />
        </FormRow>
        <FormRow>
          <InputFormField
            name="opacity"
            label="Image opacity"
            helperText="In percentage"
            type={InputType.PositiveNumbers}
            max={100}
            placeholder="100"
          />
        </FormRow>
      </Form>
    </EditPanelSection>
  );
}
