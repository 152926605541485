import { ActionReducerMapBuilder, createAction, nanoid } from '@reduxjs/toolkit';
import { AppConfig } from '../../core/appConfig';
import {
  ClickActionType,
  FontFamily,
  FontWeight,
  HorizontalAlign,
  ImageFit,
  WidgetType,
} from '../../core/widgets/definitions';
import { WidgetConfig } from '../../core/widgets/types';
import { AppState } from '../types';

/** Append widget with given type to the end */
export const addWidget = createAction<WidgetType.Button | WidgetType.Text | WidgetType.Image>(
  'addWidget'
);

/** Append reducer for {@see addWidget} action to Redux slice */
export const addAddWidgetReducer = (builder: ActionReducerMapBuilder<AppState>) => {
  builder.addCase(addWidget, (state, action) => {
    if (!state.template) {
      throw new Error('Template is not loaded');
    }

    state.isTemplateChanged = true;

    let widget: WidgetConfig;
    switch (action.payload) {
      case WidgetType.Text:
        widget = {
          id: nanoid(),
          type: WidgetType.Text,
          text: 'New text',
          widgetStyle: {
            textAlign: HorizontalAlign.Center,
            fontFamily: FontFamily.OpenSans,
            fontWeight: FontWeight.w700,
            color: '#2399F0',
            fontSize: 21,
          },
        };
        break;
      case WidgetType.Image:
        widget = {
          id: nanoid(),
          type: WidgetType.Image,
          fileName: 'new_image.png',
          url: `${AppConfig.frontendUrl}/assets/themes/CTA-SimpleTexting_hero.png`,
          onClick: {
            type: ClickActionType.None,
          },
          widgetStyle: {
            fit: ImageFit.Contain,
            align: HorizontalAlign.Center,
          },
        };
        break;
      case WidgetType.Button:
        widget = {
          id: nanoid(),
          type: WidgetType.Button,
          text: ['New button', 'Configure it'],
          onClick: {
            type: ClickActionType.ClosePopup,
          },
          widgetStyle: {
            isExpanded: true,
            padding: {
              left: 16,
              right: 16,
              top: 8,
              bottom: 8,
            },
            borderRadius: 4,
            backgroundColor: '#2399f0',
            firstRowStyle: {
              color: '#FFFFFF',
              fontFamily: FontFamily.Rubik,
              fontSize: 16,
              fontWeight: FontWeight.w400,
            },
            secondRowStyle: {
              color: '#FFFFFF',
              fontFamily: FontFamily.Rubik,
              fontSize: 10,
              fontWeight: FontWeight.w400,
            },
          },
        };
        break;
    }

    state.template.widgets.push(widget);
  });
};
