import { useField } from 'formik';
import React, { CSSProperties, ReactNode, useEffect, useRef, useState } from 'react';
import { Transition } from 'react-transition-group';
import { measureDomNode } from '../../../core/utils';
import styles from './RadioButton.module.scss';

interface RadioButtonProps<T> {
  name: string;
  label: ReactNode;
  value: T;
  children?: ReactNode;
  maxWidth?: number;
}

export function RadioButton<T>(props: RadioButtonProps<T>) {
  const [field, , helpers] = useField(props as any);

  if (props.children && !props.maxWidth) {
    throw new Error('RadioButton children allowed with maxWidth only');
  }

  return (
    <div>
      <label className={styles.radio}>
        <input
          className={styles.input}
          type="radio"
          name={field.name}
          checked={field.value === props.value}
          onClick={() => {
            helpers.setTouched(true);
          }}
          onChange={() => {
            helpers.setValue(props.value);
          }}
        />
        {props.label}
      </label>

      {props.children && props.maxWidth && (
        <>
          <CollapsableChildren isOpened={field.value === props.value} maxWidth={props.maxWidth}>
            {props.children}
          </CollapsableChildren>
        </>
      )}
    </div>
  );
}

function CollapsableChildren(props: { isOpened: boolean; children: ReactNode; maxWidth: number }) {
  const [contentHeight, setContentHeight] = useState<number | undefined>(undefined);

  const contentTransitionStyles: { [status: string]: CSSProperties } = {
    entering: { overflow: 'hidden', maxHeight: contentHeight },
    entered: { overflow: 'visible', maxHeight: contentHeight },
    exiting: { overflow: 'hidden', maxHeight: 0 },
    exited: { overflow: 'hidden', maxHeight: 0 },
  };

  const contentTransitionChildRef = useRef<HTMLDivElement>(null);
  const contentChildRef = useRef<HTMLDivElement>(null);

  const getContentHeight = () => {
    if (!contentChildRef.current) {
      return 0;
    }

    const size = measureDomNode(contentChildRef.current, (element) => {
      element.style.width = `${props.maxWidth - 30}px`;
      return element;
    });

    return size.height;
  };

  const updateContentHeight = () => {
    const newContentHeight = getContentHeight();
    if (contentHeight !== newContentHeight) {
      setContentHeight(newContentHeight);
    }
  };

  useEffect(() => {
    setTimeout(updateContentHeight, 0);
    // eslint-disable-next-line
  }, []);

  return (
    <Transition
      in={props.isOpened}
      nodeRef={contentTransitionChildRef}
      timeout={300}
      mountOnEnter={true}
      unmountOnExit={true}
      // appear={true}
      onEnter={updateContentHeight}
    >
      {(state) => (
        <div
          ref={contentTransitionChildRef}
          className={styles.childrenTransition}
          style={{ ...contentTransitionStyles[state] }}
        >
          <div ref={contentChildRef} className={styles.children}>
            {props.children}
          </div>
        </div>
      )}
    </Transition>
  );
}
