import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';
import { CommonWidgetIds } from '../../core/template';
import { AppState } from '../types';

/** Toggle visible of widget with given ID */
export const toggleWidgetVisibility = createAction<string>('toggleWidgetVisibility');

/** Append reducer for {@see toggleWidgetVisibility} action to Redux slice */
export const addToggleWidgetVisibilityReducer = (builder: ActionReducerMapBuilder<AppState>) => {
  builder.addCase(toggleWidgetVisibility, (state, action) => {
    if (!state.template) {
      throw new Error('Template is not loaded');
    }

    state.isTemplateChanged = true;

    switch (action.payload) {
      case CommonWidgetIds.TriggerButton:
        state.template.triggerButtonWidget.hidden = !state.template.triggerButtonWidget.hidden;
        break;
      case CommonWidgetIds.MainContainer:
        state.template.mainContainerWidget.hidden = !state.template.mainContainerWidget.hidden;
        break;
      case CommonWidgetIds.PopupCloseButton:
        state.template.popupCloseButtonWidget.hidden = !state.template.popupCloseButtonWidget
          .hidden;
        break;
      default:
        const widgetIndex = state.template.widgets.findIndex(
          (widget) => widget.id === action.payload
        );
        if (widgetIndex === -1) {
          throw new Error('Not found widget');
        }
        state.template.widgets[widgetIndex].hidden = !state.template.widgets[widgetIndex].hidden;
        break;
    }
  });
};
