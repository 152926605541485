import { useSelector } from 'react-redux';
import { findTemplateWidget } from '../../../../../../core/utils';
import { selectTemplate } from '../../../../../../state/selectors';
import { CommonWidgetIds } from '../../../../../../core/template';
import {
  FormRow,
  EditPanelDescription,
  EditPanelSection,
  AccordionSection,
  Tab,
  Tabs,
} from '../../../../../components';
import { Form, InputFormField, TextareaFormField } from '../../../../../form-components';
import { WidgetType } from '../../../../../../core/widgets/definitions';
import { useButtonsForm } from './useButtonsForm';
import { useTextForm } from './useTextForm';

export function CopyPanel() {
  const template = useSelector(selectTemplate);

  const buttonForm = useButtonsForm(template);
  const textForm = useTextForm(template);

  const hasPreHeadline = !!findTemplateWidget(
    template,
    CommonWidgetIds.PreHeadline,
    WidgetType.Text
  );
  const hasHeadline = !!findTemplateWidget(template, CommonWidgetIds.Headline, WidgetType.Text);
  const hasSubHeadline = !!findTemplateWidget(
    template,
    CommonWidgetIds.SubHeadline,
    WidgetType.Text
  );
  const hasDismissButton = !!findTemplateWidget(
    template,
    CommonWidgetIds.DismissButton,
    WidgetType.Button
  );

  return (
    <AccordionSection id="base_copy" title="Copy">
      <Tabs
        tabs={[
          { label: 'Buttons', id: 'buttons' },
          { label: 'Text', id: 'text' },
        ]}
      >
        <Tab id="buttons">
          <Form initialValues={buttonForm.initialValues} onValuesChange={buttonForm.saveFormValues}>
            <EditPanelSection title="Trigger button">
              <EditPanelDescription>Floats on your webpage.</EditPanelDescription>

              <FormRow>
                <InputFormField name="triggerButtonText" label="Trigger text" />
              </FormRow>
            </EditPanelSection>

            <EditPanelSection title="Main button">
              <EditPanelDescription>Opens users's SMS app.</EditPanelDescription>

              <FormRow>
                <InputFormField name="mainButtonPrimaryText" label="Primary action" />
              </FormRow>
              <FormRow>
                <InputFormField name="mainButtonSecondLineText" label="Second Line" />
              </FormRow>
            </EditPanelSection>

            <EditPanelSection title="Dismiss button">
              {hasDismissButton ? (
                <>
                  <EditPanelDescription>Close popup.</EditPanelDescription>

                  <FormRow>
                    <InputFormField name="dismissButtonPrimaryText" label="Primary action" />
                  </FormRow>
                  <FormRow>
                    <InputFormField name="dismissButtonSecondLineText" label="Second Line" />
                  </FormRow>
                </>
              ) : (
                <EditPanelDescription>
                  This template does not use a dismiss button. Switch to a different theme to use a
                  dismiss button.
                </EditPanelDescription>
              )}
            </EditPanelSection>
          </Form>
        </Tab>

        <Tab id="text">
          <Form initialValues={textForm.initialValues} onValuesChange={textForm.saveFormValues}>
            <EditPanelSection title="Pre-headline">
              {hasPreHeadline ? (
                <FormRow>
                  <TextareaFormField name="preHeadline" label="Text" rows={4} />
                </FormRow>
              ) : (
                <EditPanelDescription>
                  This template does not use a pre-headline. Switch to a different theme to use a
                  pre-headline.
                </EditPanelDescription>
              )}
            </EditPanelSection>

            <EditPanelSection title="Headline">
              {hasHeadline ? (
                <FormRow>
                  <TextareaFormField name="headline" label="Text" rows={4} />
                </FormRow>
              ) : (
                <EditPanelDescription>
                  This template does not use a headline. Switch to a different theme to use a
                  headline.
                </EditPanelDescription>
              )}
            </EditPanelSection>

            <EditPanelSection title="Subheadline">
              {hasSubHeadline ? (
                <FormRow>
                  <TextareaFormField name="subHeadline" label="Text" rows={4} />
                </FormRow>
              ) : (
                <EditPanelDescription>
                  This template does not use a subheadline. Switch to a different theme to use a
                  subheadline.
                </EditPanelDescription>
              )}
            </EditPanelSection>
          </Form>
        </Tab>
      </Tabs>
    </AccordionSection>
  );
}
