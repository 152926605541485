import { useField } from 'formik';
import { FormEvent, useRef } from 'react';
import { uploadImage } from '../../../services';
import { Button } from '../../components';
import { ComponentTheme } from '../../components';
import { InputFormField } from '../input-form-field/InputFormField';
import styles from './ImageFormField.module.scss';

interface ImageFormFieldProps {
  name: string;
  label: string;
  onChanged: (values: { fileName?: string; localUrl?: string; url?: string }) => void;
  onClear?: () => void;
}

export function ImageFormField(props: ImageFormFieldProps) {
  const [field] = useField(props);

  const lastUploadedFileName = useRef('');

  const handleClearClick = () => {
    if (props.onClear) {
      props.onClear();
    }

    lastUploadedFileName.current = '';
  };

  const uploadFileRef = useRef<HTMLInputElement>(null);
  const handleUploadClick = () => {
    uploadFileRef.current?.click();
  };

  const handleImageChange = async (event: FormEvent<HTMLInputElement>) => {
    if (!event.currentTarget.value || !event.currentTarget.files) {
      return;
    }
    const file = event.currentTarget.files[0];
    if (!file) {
      return;
    }
    event.currentTarget.value = '';

    const oldFileName = field.value;

    props.onChanged({
      fileName: file.name,
      localUrl: URL.createObjectURL(file),
    });

    try {
      lastUploadedFileName.current = file.name;

      const imageUrl = await uploadImage({
        image: file,
      });

      if (lastUploadedFileName.current === file.name) {
        props.onChanged({
          localUrl: '',
          url: imageUrl,
        });
      }
    } catch (error) {
      console.warn(`Error un upload image`, error);

      props.onChanged({
        localUrl: '',
        fileName: oldFileName,
      });
    }
  };

  return (
    <>
      <InputFormField name={props.name} label={props.label} disabled={true} />

      {props.onClear && (
        <div className={styles.button}>
          <Button onClick={handleClearClick} theme={ComponentTheme.Gray} disabled={!field.value}>
            Clear
          </Button>
        </div>
      )}

      <div className={styles.button}>
        <input
          ref={uploadFileRef}
          onChange={handleImageChange}
          type="file"
          multiple={false}
          accept="image/jpeg,image/png"
          hidden
        />
        <Button onClick={handleUploadClick}>Upload</Button>
      </div>
    </>
  );
}
