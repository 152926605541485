import { ReactNode } from 'react';
import styles from './Accordion.module.scss';

/** Props for {@see Accordion} */
interface AccordionProps {
  children: ReactNode;
}

/**
 * Component that can contains one ore more children sections. But only one of
 * children section can be expanded.
 *
 * Only {@see AccordionSection} must be used as children sections.
 */
export function Accordion(props: AccordionProps) {
  return <div className={styles.accordion}>{props.children}</div>;
}
