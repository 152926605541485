import { useDispatch } from 'react-redux';
import { revertTemplate } from '../../../state/actions';
import { Button, ComponentTheme } from '../../components';
import { ModalActionButtons, ModalDescription, Modal } from '../components';

interface RevertTemplateModalProps {
  onRequestClose: () => void;
}

export function RevertTemplateModal(props: RevertTemplateModalProps) {
  const dispatch = useDispatch();

  const handleRevert = () => {
    props.onRequestClose();
    dispatch(revertTemplate());
  };

  return (
    <Modal title="Abandon unsaved changes?" isOpen={true} onRequestClose={props.onRequestClose}>
      <ModalDescription>
        Reverting to the published version will forget the current changes.
      </ModalDescription>

      <ModalActionButtons>
        <Button onClick={props.onRequestClose} theme={ComponentTheme.TransparentPrimary}>
          Cancel
        </Button>
        <Button onClick={handleRevert}>Discard changes</Button>
      </ModalActionButtons>
    </Modal>
  );
}
