import { Dispatch } from 'react';
import { useDispatch } from 'react-redux';
import { CommonWidgetIds, Template } from '../../../../../../core/template';
import { findTemplateWidget, validateOnClick } from '../../../../../../core/utils';
import { ClickActionType, SmsType, WidgetType } from '../../../../../../core/widgets/definitions';
import { Validator } from '../../../../../../services';
import { mergeWidgetConfig } from '../../../../../../state/actions';
import { UseFormResult } from '../../types';

interface FormConfig {
  phone: string;
  smsType: SmsType;
  message: string;
}

function formInitialValue(template: Template): FormConfig {
  const mainButton = findTemplateWidget(template, CommonWidgetIds.MainButton, WidgetType.Button);
  if (!mainButton) {
    throw new Error('Not found Main Button widget');
  }

  return {
    phone: mainButton.onClick?.type === ClickActionType.SendMessage ? mainButton.onClick.phone : '',
    smsType:
      mainButton.onClick?.type === ClickActionType.SendMessage
        ? mainButton.onClick.smsType
        : SmsType.Keyword,
    message:
      mainButton.onClick?.type === ClickActionType.SendMessage ? mainButton.onClick.text : '',
  };
}

const saveFormValues = (template: Template, dispatch: Dispatch<any>) => (values: FormConfig) => {
  const mainButton = findTemplateWidget(template, CommonWidgetIds.MainButton, WidgetType.Button);
  if (!mainButton) {
    throw new Error('Not found Main Button widget');
  }

  dispatch(
    mergeWidgetConfig({
      ...mainButton,
      onClick: {
        type: ClickActionType.SendMessage,
        smsType: values.smsType,
        phone: values.phone,
        text: values.message,
      },
    })
  );
};

function validateForm(values: FormConfig): { [name in keyof FormConfig]?: string } {
  const errors: { [name in keyof FormConfig]?: string } = {};

  if (!Validator.isPhone(values.phone)) {
    errors['phone'] = 'Enter valid phone';
  }

  return errors;
}

function isValid(template: Template) {
  const mainButton = findTemplateWidget(template, CommonWidgetIds.MainButton, WidgetType.Button);
  if (!mainButton) {
    throw new Error('Not found Main Button widget');
  }

  return validateOnClick(mainButton.onClick);
}

export function useSmsForm(template: Template): UseFormResult<FormConfig> {
  const dispatch = useDispatch();
  const initialValue = formInitialValue(template);

  return {
    initialValues: initialValue,
    saveFormValues: saveFormValues(template, dispatch),
    validateForm,
    isValid: isValid(template),
  };
}
