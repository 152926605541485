import { CSSProperties } from 'react';
import { useDispatch } from 'react-redux';
import { openEditPanelAccordionSection } from '../../../state/actions';
import { CommonWidgetIds } from '../../template';
import { WidgetType } from '../definitions';
import { RowWidget } from '../release/RowWidget';
import { Widget } from '../release/Widget';
import { WidgetConfig } from '../types';
import { DebugContainerWidget } from './DebugContainerWidget';
import styles from './DebugWidget.module.css';
import { omit, pick } from 'lodash';
import { DebugColumnWidget } from './DebugColumnWidget';

/** Props for {@see DebugWidget} */
type DebugWidgetProps = WidgetConfig & {
  /** Custom CSS styles */
  style?: CSSProperties;
};

/** Implementation of widget builder that support selections and custom CSS styles. */
export function DebugWidget(props: DebugWidgetProps) {
  const dispatch = useDispatch();

  const handleClick = () => {
    if (props.id) {
      dispatch(openEditPanelAccordionSection({ sectionId: props.id, useAutoScroll: true }));
    }
  };

  switch (props.type) {
    case WidgetType.Container:
      return <DebugContainerWidget {...props} />;
    case WidgetType.Row:
      return <RowWidget {...props} children={props.children} widgetRender={DebugWidget} />;
    case WidgetType.Column:
      return <DebugColumnWidget {...props} children={props.children} />;
  }

  const popProperties: (keyof CSSProperties)[] = [
    'position',
    'top',
    'left',
    'right',
    'bottom',
    'zIndex',
  ];

  const interactiveStyles: CSSProperties = {
    top: `${-4 + (props.widgetStyle?.margin?.top ?? 0)}px`,
    bottom: `${-4 + (props.widgetStyle?.margin?.bottom ?? 0)}px`,
    left: `${-4 + (props.widgetStyle?.margin?.left ?? 0)}px`,
    right: `${-4 + (props.widgetStyle?.margin?.right ?? 0)}px`,
  };

  let containerWidth: 'auto' | undefined;
  switch (props.id) {
    case CommonWidgetIds.TriggerButtonCloseButton:
    case CommonWidgetIds.PopupCloseButton:
      containerWidth = 'auto';
      break;
  }

  return (
    <>
      {!props.hidden && (
        <div
          className={styles.debugWidgetContainer}
          style={{
            ...pick(props.style, popProperties),
            width: containerWidth,
          }}
        >
          <div
            className={styles.debugWidgetInteractive}
            style={interactiveStyles}
            onClick={handleClick}
          />

          <Widget
            {...props}
            style={{
              ...omit(props.style, popProperties),
              transition: 'all 300ms ease',
              position: 'relative',
              pointerEvents: 'none',
              userSelect: 'none',
            }}
          />
        </div>
      )}
    </>
  );
}
