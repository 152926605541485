import { Template } from '../../template';
import { RenderTarget, TemplateRender } from '../TemplateRender';
import './ReleaseTemplateRender.css';
import { Widget } from '../../widgets/release/Widget';
import { ReleasePopupContainer } from './ReleasePopupContainer';

/** Props for {@see ReleaseTemplateRender} */
interface ReleaseTemplateRenderProps {
  /** Current template */
  template: Template;
  /** What must be rendered (popup or trigger button) */
  renderTarget?: RenderTarget;
  /**
   * If this flag sets to `true` that popup will be use parent `div` as container,
   * else popup will be use `body` ad parent container.
   *
   * In production this flag must be `false`.
   */
  fitToDiv?: boolean;
  /**
   * If this prop exist, than when user clicked by Main button this method will be executed, else
   * default system SMS application will be shown.
   */
  onShowSmsApplication?: () => void;
}

/** Template render for production or preview. Return template with show/hide animation and other features */
export function ReleaseTemplateRender(props: ReleaseTemplateRenderProps) {
  return (
    <TemplateRender widgetRender={Widget} popupContainerRender={ReleasePopupContainer} {...props} />
  );
}
