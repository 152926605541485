import { CSSProperties, ReactNode } from 'react';
import { baseWidgetStyleToCss } from '../utils';
import { ContainerStyle, ContainerWidgetConfig, WidgetConfig } from '../types';

/** Function that used for render child widget */
type WidgetRender = (props: WidgetConfig & { style?: CSSProperties }) => ReactNode;

/** Props for {@see ContainerWidgetWidget} */
interface ContainerWidgetProps extends ContainerWidgetConfig {
  /** Function that used for render child widget */
  widgetRender: WidgetRender;
  /** Custom CSS styles */
  style?: CSSProperties;
}

/** Implementation of container widget */
export function ContainerWidget(props: ContainerWidgetProps) {
  const backgroundUrl =
    props.widgetStyle?.backgroundImage?.localUrl || props.widgetStyle?.backgroundImage?.url
      ? `url(${
          props.widgetStyle?.backgroundImage?.localUrl || props.widgetStyle?.backgroundImage?.url
        })`
      : undefined;

  return (
    <div
      style={{
        ...containerWidgetStyleToCss(props.widgetStyle),
        width: 'unset',
        ...props.style,
      }}
    >
      {backgroundUrl && (
        <div
          style={{
            borderRadius: props.widgetStyle?.borderRadius ?? 0,
            backgroundImage: backgroundUrl,
            position: 'absolute',
            pointerEvents: 'none',
            left: 0,
            top: 0,
            bottom: 0,
            right: 0,
            backgroundSize: props.widgetStyle?.backgroundImage?.fit ?? 'cover',
            backgroundPosition: 'center',
            opacity: props.widgetStyle?.backgroundImage?.opacity
              ? props.widgetStyle.backgroundImage.opacity / 100
              : undefined,
          }}
        />
      )}

      <div style={{ position: 'relative' }}>{props.widgetRender(props.child)}</div>
    </div>
  );
}

function containerWidgetStyleToCss(style?: ContainerStyle): CSSProperties {
  return baseWidgetStyleToCss(style);
}
