import omit from 'lodash/omit';
import { CSSProperties, ReactNode, useState } from 'react';
import { CommonWidgetIds, Template } from '../template';
import { TemplateContext, TemplateEvent } from '../TemplateContext';
import { ClickActionType, WidgetType } from '../widgets/definitions';
import { WidgetConfig } from '../widgets/types';

/**
 * Target for rendering.
 *
 * Debug render can render trigger button or popup separately.
 */
export enum RenderTarget {
  TriggerButton,
  PopUp,
}

/** Props for {@see WidgetRender} */
type WidgetRenderProps = WidgetConfig & {
  /** Standard CSS styles */
  style?: CSSProperties;
};

/** Function that can render any template widget */
type WidgetRender = (props: WidgetRenderProps) => JSX.Element | null;

/** Props for {@see PopupContainerRender} */
export interface PopupContainerProps {
  /** Current template */
  template: Template;
  /** Popup component */
  popup: ReactNode;
  /** Trigger button component */
  triggerButton: ReactNode;
  /** Last emitted template event */
  lastEvent: TemplateEvent | undefined;
  /**
   * If this flag sets to `true` that popup will be use parent `div` as container,
   * else popup will be use `body` ad parent container.
   *
   * In production this flag must be `false`.
   */
  fitToDiv: boolean;
  /**
   * What must be rendered.
   *
   * Used only in debug template render. In production is not used.
   */
  renderTarget: RenderTarget | undefined;
}

/** Function that can render any template popup container */
type PopupContainerRender = (props: PopupContainerProps) => JSX.Element | null;

/** Props for {@see TemplateRender} */
interface TemplateRenderProps {
  /** Current template */
  template: Template;
  /** Function that can render any template widget */
  widgetRender: WidgetRender;
  /** Function that can render any template popup container */
  popupContainerRender: PopupContainerRender;
  /**
   * What must be rendered.
   *
   * Used only in debug template render. In production is not used.
   */
  renderTarget?: RenderTarget;
  /**
   * If this flag sets to `true` that popup will be use parent `div` as container,
   * else popup will be use `body` ad parent container.
   *
   * In production this flag must be `false`.
   */
  fitToDiv?: boolean;
  /**
   * Flag that enable all CSS transitions. Must be used only in debug render.
   */
  enableTransitions?: boolean;
  /**
   * If this prop exist, than when user clicked by Main button this method will be executed, else
   * default system SMS application will be shown.
   */
  onShowSmsApplication?: () => void;
}

/**
 * Common template render component. Render popup and trigger button, wrap them in popup container
 * and return result.
 *
 * This component must not be used directly. Use {@see DebugTemplateRender} or
 * {@see ReleaseTemplateRender} instead.
 */
export function TemplateRender(props: TemplateRenderProps) {
  const widgetColumnConfig: WidgetConfig = {
    type: WidgetType.Container,
    id: CommonWidgetIds.MainContainer,
    widgetStyle: omit(props.template.mainContainerWidget.widgetStyle, 'margin'),
    child: {
      id: '__mainColumn',
      type: WidgetType.Column,
      children: props.template.widgets,
    },
  };

  const PopupContainer = props.popupContainerRender;
  const Widget = props.widgetRender;

  const popup = (
    <>
      <Widget
        type={WidgetType.IconButton}
        id={CommonWidgetIds.PopupCloseButton}
        icon="close"
        onClick={{ type: ClickActionType.ClosePopup }}
        widgetStyle={props.template.popupCloseButtonWidget.widgetStyle}
        style={{
          transition: props.enableTransitions ? 'all 300ms ease' : undefined,
          zIndex: 1,
          position: 'absolute',
          top: 0,
          right: 0,
        }}
      />
      <Widget {...widgetColumnConfig} />
    </>
  );

  const triggerButton = (
    <>
      <Widget
        type={WidgetType.IconButton}
        id={CommonWidgetIds.TriggerButtonCloseButton}
        icon="close"
        widgetStyle={props.template.triggerButtonCloseButtonWidget.widgetStyle}
        style={{
          transition: props.enableTransitions ? 'all 300ms ease' : undefined,
          zIndex: 1,
          position: 'absolute',
          top: 0,
          right: 0,
        }}
      />
      <Widget
        type={WidgetType.Button}
        id={CommonWidgetIds.TriggerButton}
        {...props.template.triggerButtonWidget}
        widgetStyle={omit(props.template.triggerButtonWidget.widgetStyle, 'margin')}
      />
    </>
  );

  const [lastEvent, setLastEvent] = useState<TemplateEvent | undefined>();

  const context = {
    emmitEvent: (event: TemplateEvent) => {
      if (event === TemplateEvent.OpenSmsApplicationClick && props.onShowSmsApplication) {
        props.onShowSmsApplication();
      } else {
        setLastEvent(event);
      }
    },
    useFakeSmsApplication: !!props.onShowSmsApplication,
  };

  return (
    <TemplateContext.Provider value={context}>
      <PopupContainer
        template={props.template}
        popup={popup}
        triggerButton={triggerButton}
        fitToDiv={props.fitToDiv ?? false}
        lastEvent={lastEvent}
        renderTarget={props.renderTarget}
      />
    </TemplateContext.Provider>
  );
}
