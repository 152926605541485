import { Dispatch } from 'react';
import { useDispatch } from 'react-redux';
import { findTemplateWidget } from '../../../../../../core/utils';
import { mergeWidgetConfig } from '../../../../../../state/actions';
import { CommonWidgetIds, Template } from '../../../../../../core/template';
import { WidgetType } from '../../../../../../core/widgets/definitions';
import { UseFormResult } from '../../types';

interface FormConfig {
  headline: string;
  preHeadline: string;
  subHeadline: string;
}

function validateForm(values: FormConfig) {
  const errors: { [name in keyof FormConfig]?: string } = {};

  return errors;
}

function getWidgets(template: Template) {
  const headlineText = findTemplateWidget(template, CommonWidgetIds.Headline, WidgetType.Text);
  const preHeadlineText = findTemplateWidget(
    template,
    CommonWidgetIds.PreHeadline,
    WidgetType.Text
  );
  const subHeadlineText = findTemplateWidget(
    template,
    CommonWidgetIds.SubHeadline,
    WidgetType.Text
  );

  return {
    headlineText,
    preHeadlineText,
    subHeadlineText,
  };
}

function formInitialValue(template: Template): FormConfig {
  const { headlineText, preHeadlineText, subHeadlineText } = getWidgets(template);

  return {
    headline: headlineText?.text ?? '',
    preHeadline: preHeadlineText?.text ?? '',
    subHeadline: subHeadlineText?.text ?? '',
  };
}

const saveFormValues = (template: Template, dispatch: Dispatch<any>) => (values: FormConfig) => {
  const { headlineText, preHeadlineText, subHeadlineText } = getWidgets(template);

  if (headlineText) {
    dispatch(
      mergeWidgetConfig({
        ...headlineText,
        text: values.headline,
      })
    );
  }

  if (preHeadlineText) {
    dispatch(
      mergeWidgetConfig({
        ...preHeadlineText,
        text: values.preHeadline,
      })
    );
  }

  if (subHeadlineText) {
    dispatch(
      mergeWidgetConfig({
        ...subHeadlineText,
        text: values.subHeadline,
      })
    );
  }
};

export function useTextForm(template: Template): UseFormResult<FormConfig> {
  const dispatch = useDispatch();
  const initialValue = formInitialValue(template);

  return {
    initialValues: initialValue,
    saveFormValues: saveFormValues(template, dispatch),
    validateForm: validateForm,
    isValid: true,
  };
}
