import { ComponentTheme } from '../../components';
import { FontWeight } from '../../../core/widgets/definitions';
import { SelectFormField, SelectValue } from '../select-form-field/SelectFormFiled';

const fontWeights: SelectValue[] = [
  { value: FontWeight.w100, label: '100' },
  { value: FontWeight.w200, label: '200' },
  { value: FontWeight.w300, label: '300' },
  { value: FontWeight.w400, label: '400' },
  { value: FontWeight.w500, label: '500' },
  { value: FontWeight.w600, label: '600' },
  { value: FontWeight.w700, label: '700' },
  { value: FontWeight.w800, label: '800' },
  { value: FontWeight.w900, label: '900' },
];

interface FontFamilyFormProps {
  name: string;
  label: string;
  theme?: ComponentTheme;
  menuPortalTarget?: HTMLElement | null;
}

export function FontWeightSelectFormField(props: FontFamilyFormProps) {
  return (
    <SelectFormField
      label={props.label}
      name={props.name}
      options={fontWeights}
      styles={{
        option: (styles, { data }) => {
          return {
            ...styles,
            fontWeight: data.value,
          };
        },
      }}
    />
  );
}
