import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';
import { Template } from '../../core/template';
import { AppState } from '../types';

/** Change current edited template main horizontal alignment to given */
export const setMainHorizontalPosition = createAction<Template['horizontalPosition']>(
  'setMainHorizontalPosition'
);

/** Append reducer for {@see setMainHorizontalPosition} action to Redux slice */
export const addSetMainHorizontalPositionReducer = (builder: ActionReducerMapBuilder<AppState>) => {
  builder.addCase(setMainHorizontalPosition, (state, action) => {
    if (!state.template) {
      throw new Error('Not found template');
    }
    state.template.horizontalPosition = action.payload;
  });
};
