import { Form, ColorFormField, normalizeColor } from '../../../../../form-components';
import { WidgetConfig, WithBackground } from '../../../../../../core/widgets/types';
import { useStyleConfigHelpers } from '../../hooks/useStyleConfigHelpers';
import { WidgetStyleConfigPanelProps } from '../types';

interface WithBackgroundColorConfig {
  backgroundColor: string;
}

export function BackgroundColorConfig<T extends WidgetConfig>(
  props: WidgetStyleConfigPanelProps<WithBackground, T>
) {
  const [widgetStyle, onChange] = useStyleConfigHelpers(props);

  const initialValue: WithBackgroundColorConfig = {
    backgroundColor: normalizeColor(widgetStyle?.backgroundColor),
  };

  const handleChange = (formValues: WithBackgroundColorConfig) => {
    onChange({
      backgroundColor: formValues.backgroundColor || null,
    });
  };

  return (
    <Form initialValues={initialValue} onValuesChange={handleChange}>
      <ColorFormField name="backgroundColor" label="Background color" />
    </Form>
  );
}
