import { CSSProperties, useContext } from 'react';
import { CommonWidgetIds } from '../../template';
import { TemplateContext, TemplateEvent } from '../../TemplateContext';
import { baseWidgetStyleToCss } from '../utils';
import { IconButtonStyle, IconButtonWidgetConfig } from '../types';

/** Props for {@see IconButtonWidget} */
interface IconButtonWidgetProps extends IconButtonWidgetConfig {
  /** Custom CSS styles */
  style?: CSSProperties;
}

/** Implementation of icon button */
export function IconButtonWidget(props: IconButtonWidgetProps) {
  const context = useContext(TemplateContext);

  const handleClick = () => {
    switch (props.id) {
      case CommonWidgetIds.PopupCloseButton:
        context.emmitEvent(TemplateEvent.PopupDismissClick);
        break;
      case CommonWidgetIds.TriggerButtonCloseButton:
        context.emmitEvent(TemplateEvent.TriggerButtonRemoveClick);
        break;
    }
  };

  return (
    <button
      style={{
        ...iconButtonWidgetStyleToCss(props.widgetStyle),
        ...props.style,
      }}
      onClick={handleClick}
    >
      {props.icon}
    </button>
  );
}

function iconButtonWidgetStyleToCss(style?: IconButtonStyle): CSSProperties {
  return {
    ...baseWidgetStyleToCss(style),
    width: 'auto',
    maxWidth: 'unset',
    appearance: 'none',
    borderStyle: 'none',
    display: 'block',
    cursor: 'pointer',
    fontFamily: 'Material Icons',
    fontFeatureSettings: 'liga',
    fontSize: style?.size ?? 24,
  };
}
