import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import { moveWidgetToIndex } from '../../../state/actions';
import { Template } from '../../template';
import { Widget } from '../../widgets/release/Widget';
import { RenderTarget, TemplateRender } from '../TemplateRender';
import { DebugWidget } from '../../widgets/debug/DebugWidget';
import '../release/ReleaseTemplateRender.css';
import { DebugPopupContainer } from './DebugPopupContainer';

/** Props for {@see DebugTemplateRender} */
interface DebugTemplateRenderProps {
  /** Current template */
  template: Template;
  /** What must be rendered (popup or trigger button) */
  renderTarget?: RenderTarget;
  /** Flag that indicate that production widget builder must be used */
  useReleaseWidgets?: boolean;
}

/** Template render with development features like drag and drop and widget selection */
export function DebugTemplateRender(props: DebugTemplateRenderProps) {
  const dispatch = useDispatch();

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    dispatch(
      moveWidgetToIndex({ widgetId: result.draggableId, newIndex: result.destination.index })
    );
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <TemplateRender
        widgetRender={props.useReleaseWidgets ? Widget : DebugWidget}
        popupContainerRender={DebugPopupContainer}
        {...props}
        enableTransitions={true}
      />
    </DragDropContext>
  );
}
