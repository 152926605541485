import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';
import { CommonWidgetIds, Template } from '../../core/template';
import { findTemplateWidget } from '../../core/utils';
import {
  ClickActionType,
  PoliciesLinkType,
  SmsType,
  WidgetType,
} from '../../core/widgets/definitions';
import {
  ButtonWidgetConfig,
  ImageWidgetConfig,
  LegalTextWidgetConfig,
  PoliciesTextWidgetConfig,
  TextWidgetConfig,
} from '../../core/widgets/types';
import { AppState } from '../types';
import { generateWidgetIds } from '../utils/generateWidgetIds';
import { original } from 'immer';

/**
 * Replace current edited template with given.
 *
 * Unlike {@see loadTemplate} this action try save some changes that user make for previous template
 * like text, images and etc.
 */
export const replaceTemplate = createAction<Template>('replaceTemplate');

/** Append reducer for {@see replaceTemplate} action to Redux slice */
export const addReplaceTemplateReducer = (builder: ActionReducerMapBuilder<AppState>) => {
  builder.addCase(replaceTemplate, (state, action) => {
    const originalTemplate = original(state.template);
    if (!originalTemplate) {
      throw new Error('Template not loaded');
    }

    state.template = generateWidgetIds(action.payload);
    state.isTemplateChanged = true;

    const oldTriggerButton = findTemplateWidget(
      originalTemplate,
      CommonWidgetIds.TriggerButton,
      WidgetType.Button
    );
    const oldMainButton = findTemplateWidget(
      originalTemplate,
      CommonWidgetIds.MainButton,
      WidgetType.Button
    );
    const oldDismissButton = findTemplateWidget(
      originalTemplate,
      CommonWidgetIds.DismissButton,
      WidgetType.Button
    );
    const oldLogoImage = findTemplateWidget(
      originalTemplate,
      CommonWidgetIds.LogoImage,
      WidgetType.Image
    );
    const oldHeroImage = findTemplateWidget(
      originalTemplate,
      CommonWidgetIds.HeroImage,
      WidgetType.Image
    );
    const oldPreHeadline = findTemplateWidget(
      originalTemplate,
      CommonWidgetIds.PreHeadline,
      WidgetType.Text
    );
    const oldHeadline = findTemplateWidget(
      originalTemplate,
      CommonWidgetIds.Headline,
      WidgetType.Text
    );
    const oldSubHeadline = findTemplateWidget(
      originalTemplate,
      CommonWidgetIds.SubHeadline,
      WidgetType.Text
    );
    const oldLegalText = findTemplateWidget(
      originalTemplate,
      CommonWidgetIds.LegalText,
      WidgetType.LegalText
    );
    const oldPoliciesText = findTemplateWidget(
      originalTemplate,
      CommonWidgetIds.PoliciesText,
      WidgetType.PoliciesText
    );

    const mainButton = findTemplateWidget(
      state.template,
      CommonWidgetIds.MainButton,
      WidgetType.Button
    );
    mergeButtonStyles(oldMainButton, mainButton, state);

    const triggerButton = findTemplateWidget(
      state.template,
      CommonWidgetIds.TriggerButton,
      WidgetType.Button
    );
    mergeButtonStyles(oldTriggerButton, triggerButton, state);

    const dismissButton = findTemplateWidget(
      state.template,
      CommonWidgetIds.DismissButton,
      WidgetType.Button
    );
    mergeButtonStyles(oldDismissButton, dismissButton, state);

    const logoImage = findTemplateWidget(
      state.template,
      CommonWidgetIds.LogoImage,
      WidgetType.Image
    );
    mergeImageStyles(oldLogoImage, logoImage);

    const heroImage = findTemplateWidget(
      state.template,
      CommonWidgetIds.HeroImage,
      WidgetType.Image
    );
    mergeImageStyles(oldHeroImage, heroImage);

    const preHeadline = findTemplateWidget(
      state.template,
      CommonWidgetIds.PreHeadline,
      WidgetType.Text
    );
    mergeTextStyles(oldPreHeadline, preHeadline);

    const headline = findTemplateWidget(state.template, CommonWidgetIds.Headline, WidgetType.Text);
    mergeTextStyles(oldHeadline, headline);

    const subHeadline = findTemplateWidget(
      state.template,
      CommonWidgetIds.SubHeadline,
      WidgetType.Text
    );
    mergeTextStyles(oldSubHeadline, subHeadline);

    const legalText = findTemplateWidget(
      state.template,
      CommonWidgetIds.LegalText,
      WidgetType.LegalText
    );
    mergeLegalTextStyles(oldLegalText, legalText);

    const policiesText = findTemplateWidget(
      state.template,
      CommonWidgetIds.PoliciesText,
      WidgetType.PoliciesText
    );
    mergePoliciesTextStyles(oldPoliciesText, policiesText, state);
  });
};

function mergeButtonStyles(
  oldButton: ButtonWidgetConfig | undefined,
  button: ButtonWidgetConfig | undefined,
  state: AppState
) {
  if (button) {
    button.text = oldButton?.text ?? button.text;
    button.onClick = oldButton?.onClick ?? {
      type: ClickActionType.SendMessage,
      smsType: SmsType.Keyword,
      phone: state.contacts.phone,
      text: '',
    };

    if (button.widgetStyle) {
      button.widgetStyle.backgroundColor =
        oldButton?.widgetStyle?.backgroundColor ?? button.widgetStyle.backgroundColor;

      if (button.widgetStyle.firstRowStyle) {
        button.widgetStyle.firstRowStyle.color =
          oldButton?.widgetStyle?.firstRowStyle?.color ?? button.widgetStyle.firstRowStyle.color;
        button.widgetStyle.firstRowStyle.fontFamily =
          oldButton?.widgetStyle?.firstRowStyle?.fontFamily ??
          button.widgetStyle.firstRowStyle.fontFamily;
      }

      if (button.widgetStyle.secondRowStyle) {
        button.widgetStyle.secondRowStyle.color =
          oldButton?.widgetStyle?.secondRowStyle?.color ?? button.widgetStyle.secondRowStyle.color;
        button.widgetStyle.secondRowStyle.fontFamily =
          oldButton?.widgetStyle?.secondRowStyle?.fontFamily ??
          button.widgetStyle.secondRowStyle.fontFamily;
      }

      if (button.widgetStyle.border) {
        button.widgetStyle.border.color =
          oldButton?.widgetStyle?.border?.color ?? button.widgetStyle.border.color;
      }
    }
  }
}

function mergeImageStyles(
  oldImage: ImageWidgetConfig | undefined,
  image: ImageWidgetConfig | undefined
) {
  if (image) {
    image.url = oldImage?.url ?? image.url;
    image.onClick = oldImage?.onClick ?? {
      type: ClickActionType.None,
    };
  }
}

function mergeTextStyles(
  oldText: TextWidgetConfig | undefined,
  text: TextWidgetConfig | undefined
) {
  if (text) {
    text.text = oldText?.text ?? text.text;

    if (text.widgetStyle) {
      text.widgetStyle.color = oldText?.widgetStyle?.color ?? text.widgetStyle.color;
      text.widgetStyle.fontFamily = oldText?.widgetStyle?.fontFamily ?? text.widgetStyle.fontFamily;
    }
  }
}

function mergeLegalTextStyles(
  oldLegalText: LegalTextWidgetConfig | undefined,
  legalText: LegalTextWidgetConfig | undefined
) {
  if (legalText) {
    legalText.smsRate = oldLegalText?.smsRate ?? legalText.smsRate;

    if (legalText.widgetStyle) {
      legalText.widgetStyle.color = oldLegalText?.widgetStyle?.color ?? legalText.widgetStyle.color;
      legalText.widgetStyle.fontFamily =
        oldLegalText?.widgetStyle?.fontFamily ?? legalText.widgetStyle.fontFamily;
    }
  }
}

function mergePoliciesTextStyles(
  oldPoliciesText: PoliciesTextWidgetConfig | undefined,
  policiesText: PoliciesTextWidgetConfig | undefined,
  state: AppState
) {
  if (policiesText) {
    policiesText.link = oldPoliciesText?.link ?? {
      type: PoliciesLinkType.AutoGenerated,
      companyName: state.contacts.companyName,
      complianceEmail: state.contacts.email,
    };

    if (policiesText.widgetStyle) {
      policiesText.widgetStyle.color =
        oldPoliciesText?.widgetStyle?.color ?? policiesText.widgetStyle.color;
      policiesText.widgetStyle.fontFamily =
        oldPoliciesText?.widgetStyle?.fontFamily ?? policiesText.widgetStyle.fontFamily;
    }
  }
}
