import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';
import { AppState } from '../types';

/** Payload for {@see moveWidgetToIndex} action */
interface MoveWidgetToIndexPayload {
  /** ID of moved widget */
  widgetId: string;
  /** New widget position index */
  newIndex: number;
}

/** Move given widget to another position */
export const moveWidgetToIndex = createAction<MoveWidgetToIndexPayload>('moveWidgetToIndex');

/** Append reducer for {@see moveWidgetToIndex} action to Redux slice */
export const addMoveWidgetToIndexReducer = (builder: ActionReducerMapBuilder<AppState>) => {
  builder.addCase(moveWidgetToIndex, (state, action) => {
    if (!state.template) {
      throw new Error('Template is not loaded');
    }

    state.isTemplateChanged = true;

    const currentWidgetIndex = state.template.widgets.findIndex(
      (widget) => widget.id === action.payload.widgetId
    );
    if (currentWidgetIndex === -1) {
      throw new Error(`Not found a widget with ID = ${action.payload.widgetId}`);
    }
    state.template.widgets.splice(
      action.payload.newIndex,
      0,
      state.template.widgets.splice(currentWidgetIndex, 1)[0]
    );
  });
};
