import { CommonWidgetIds } from '../../../../../../core/template';
import { WidgetType } from '../../../../../../core/widgets/definitions';
import { AccordionSection, FormRow } from '../../../../../components';
import { useWidgetConfig } from '../../hooks';
import {
  BackgroundColorConfig,
  BorderRadiusConfig,
  ColorConfig,
  IconSizeConfig,
  MarginConfigSection,
  ShadowConfig,
  StylingSection,
} from '../../panel-components';

interface IconButtonWidgetPanelProps {
  id: string;
  type?: string;
}

export function IconButtonWidgetPanel(props: IconButtonWidgetPanelProps) {
  const [widget, handleChanges] = useWidgetConfig(props.id, WidgetType.IconButton);

  return (
    <AccordionSection id={props.id} title={props.type ?? 'Icon button'}>
      <StylingSection>
        <FormRow>
          <BackgroundColorConfig widget={widget} onChange={handleChanges} />
          <BorderRadiusConfig widget={widget} onChange={handleChanges} />
        </FormRow>
        <ShadowConfig widget={widget} onChange={handleChanges} />
        <FormRow>
          <ColorConfig widget={widget} onChange={handleChanges} label="Icon color" />
          <IconSizeConfig widget={widget} onChange={handleChanges} />
        </FormRow>
      </StylingSection>

      <MarginConfigSection
        widget={widget}
        onChange={handleChanges}
        negativeAccept={props.id === CommonWidgetIds.TriggerButtonCloseButton}
      />
    </AccordionSection>
  );
}
