import { Dispatch } from 'react';
import { useDispatch } from 'react-redux';
import { Validator } from '../../../../../../services';
import { mergeWidgetConfig } from '../../../../../../state/actions';
import { CommonWidgetIds, Template } from '../../../../../../core/template';
import { findTemplateWidget, validatePolices } from '../../../../../../core/utils';
import { PoliciesLinkType, WidgetType } from '../../../../../../core/widgets/definitions';
import { UseFormResult } from '../../types';

interface FormConfig {
  messagePerMount: string;
  linkType: PoliciesLinkType;
  companyName: string;
  complianceEmail: string;
  termsConditionsLink: string;
  privacyPolicyLink: string;
}

function getWidgets(template: Template) {
  const legalText = findTemplateWidget(template, CommonWidgetIds.LegalText, WidgetType.LegalText);
  const policiesText = findTemplateWidget(
    template,
    CommonWidgetIds.PoliciesText,
    WidgetType.PoliciesText
  );

  return {
    legalText,
    policiesText,
  };
}

function formInitialValue(template: Template): FormConfig {
  const { legalText, policiesText } = getWidgets(template);

  return {
    messagePerMount: legalText?.smsRate ? legalText.smsRate.toFixed() : '',
    linkType: policiesText?.link?.type ?? PoliciesLinkType.AutoGenerated,
    companyName:
      (policiesText?.link && 'companyName' in policiesText.link
        ? policiesText.link.companyName
        : '') ?? '',
    complianceEmail:
      (policiesText?.link && 'complianceEmail' in policiesText.link
        ? policiesText.link.complianceEmail
        : '') ?? '',
    termsConditionsLink:
      (policiesText?.link && 'termsConditionsLink' in policiesText.link
        ? policiesText.link.termsConditionsLink
        : '') ?? '',
    privacyPolicyLink:
      (policiesText?.link && 'privacyPolicyLink' in policiesText.link
        ? policiesText.link.privacyPolicyLink
        : '') ?? '',
  };
}

const saveFormValues = (template: Template, dispatch: Dispatch<any>) => (values: FormConfig) => {
  const { legalText, policiesText } = getWidgets(template);

  if (legalText) {
    dispatch(
      mergeWidgetConfig({
        ...legalText,
        smsRate: values.messagePerMount ? Number(values.messagePerMount) : 0,
      })
    );
  }

  if (policiesText) {
    dispatch(
      mergeWidgetConfig({
        ...policiesText,
        link: {
          type: values.linkType,
          companyName: values.companyName,
          complianceEmail: values.complianceEmail,
          termsConditionsLink: values.termsConditionsLink,
          privacyPolicyLink: values.privacyPolicyLink,
        },
      })
    );
  }
};

function validateForm(values: FormConfig): { [name in keyof FormConfig]?: string } {
  const errors: { [name in keyof FormConfig]?: string } = {};

  switch (values.linkType) {
    case PoliciesLinkType.AutoGenerated: {
      if (!values.companyName) {
        errors['companyName'] = 'Enter your company name';
      }
      if (!Validator.isEmail(values.complianceEmail)) {
        errors['complianceEmail'] = 'Enter valid email';
      }
      break;
    }
    case PoliciesLinkType.Manual: {
      if (!Validator.isURL(values.privacyPolicyLink)) {
        errors['privacyPolicyLink'] = 'Enter valid URL';
      }
      if (!Validator.isURL(values.termsConditionsLink)) {
        errors['termsConditionsLink'] = 'Enter valid URL';
      }
      break;
    }
  }

  return errors;
}

function isValid(template: Template) {
  const { policiesText } = getWidgets(template);

  return validatePolices(policiesText);
}

export function useForm(template: Template): UseFormResult<FormConfig> {
  const dispatch = useDispatch();
  const initialValue = formInitialValue(template);

  return {
    initialValues: initialValue,
    saveFormValues: saveFormValues(template, dispatch),
    validateForm,
    isValid: isValid(template),
  };
}
