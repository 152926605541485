import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';
import { AppState } from '../types';

/** Set flag that Customizer editor panel must be show if given value is `true` */
export const setExpertModeEnabled = createAction<boolean>('setExpertModeEnabled');

/** Append reducer for {@see setExpertModeEnabled} action to Redux slice */
export const addSetExpertModeEnabledReducer = (builder: ActionReducerMapBuilder<AppState>) => {
  builder.addCase(setExpertModeEnabled, (state, action) => {
    state.editPanel.isExpertModeEnabled = action.payload;
  });
};
