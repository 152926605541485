import { CSSProperties } from 'react';
import { ButtonWidget } from './ButtonWidget';
import { ColumnWidget } from './ColumnWidget';
import { ContainerWidget } from './ContainerWidget';
import { IconButtonWidget } from './IconButtonWidget';
import { RowWidget } from './RowWidget';
import { TextWidget } from './TextWidget';
import { ImageWidget } from './ImageWidget';
import { WidgetType } from '../definitions';
import { WidgetConfig } from '../types';

/** Props for {@see Widget} */
type WidgetProps = WidgetConfig & {
  /** Custom CSS styles */
  style?: CSSProperties;
};

/** Implementation of widget buildr */
export function Widget(props: WidgetProps) {
  if (props.hidden) {
    return null;
  }

  switch (props.type) {
    case WidgetType.Container:
      return <ContainerWidget {...props} widgetRender={Widget} />;
    case WidgetType.Row:
      return <RowWidget {...props} children={props.children} widgetRender={Widget} />;
    case WidgetType.Column:
      return <ColumnWidget {...props} children={props.children} widgetRender={Widget} />;
    case WidgetType.Image:
      return <ImageWidget {...props} />;
    case WidgetType.Button:
      return <ButtonWidget {...props} />;
    case WidgetType.LegalText:
    case WidgetType.PoliciesText:
    case WidgetType.SimpleTextingCopyrightText:
    case WidgetType.Text:
      return <TextWidget {...props} />;
    case WidgetType.IconButton:
      return <IconButtonWidget {...props} />;
  }
}
