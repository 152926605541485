import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';
import { CommonWidgetIds } from '../../core/template';
import { DeepPartial } from '../../types';
import { IconButtonWidgetConfig, WidgetConfig } from '../../core/widgets/types';
import { AppState } from '../types';
import { merge } from 'lodash';

/** Merge current configuration of given widget in application state with given configuration */
export const mergeWidgetConfig = createAction<DeepPartial<WidgetConfig>>('mergeWidgetConfig');

/** Append reducer for {@see mergeWidgetConfig} action to Redux slice */
export const addMergeWidgetConfigReducer = (builder: ActionReducerMapBuilder<AppState>) => {
  builder.addCase(mergeWidgetConfig, (state, action) => {
    if (!state.template) {
      throw new Error('Template is not loaded');
    }

    state.isTemplateChanged = true;

    switch (action.payload.id) {
      case CommonWidgetIds.TriggerButton:
        state.template.triggerButtonWidget = merge(
          state.template.triggerButtonWidget,
          action.payload
        );
        break;
      case CommonWidgetIds.MainContainer:
        state.template.mainContainerWidget = merge(
          state.template.mainContainerWidget,
          action.payload
        );
        break;
      case CommonWidgetIds.PopupCloseButton:
        state.template.popupCloseButtonWidget = merge(
          state.template.popupCloseButtonWidget,
          action.payload
        ) as IconButtonWidgetConfig;
        break;
      case CommonWidgetIds.TriggerButtonCloseButton:
        state.template.triggerButtonCloseButtonWidget = merge(
          state.template.triggerButtonCloseButtonWidget,
          action.payload
        ) as IconButtonWidgetConfig;
        break;
      default:
        const widgetIndex = state.template.widgets.findIndex(
          (widget) => widget.id === action.payload.id
        );
        if (widgetIndex === -1) {
          throw new Error('Not found widget');
        }
        state.template.widgets[widgetIndex] = merge(
          state.template.widgets[widgetIndex],
          action.payload
        );
        break;
    }
  });
};
