import validator from 'validator';

/** Set of common validators */
export class Validator {
  /** Check that `phone` is valid */
  static isPhone(phone?: string) {
    return !!(
      phone &&
      (validator.matches(phone, /^\(\d{3}\) \d{3}-\d{4}$/i) ||
        validator.matches(phone, /^\d{3}-\d{2,3}$/i))
    );
  }

  /** Check that `email` is valid */
  static isEmail(email?: string) {
    return !!(email && validator.isEmail(email));
  }

  /** Check that `url` is valid */
  static isURL(url?: string) {
    return !!(url && validator.isURL(url));
  }
}
