import { bold } from './bold';
import { bright } from './bright';
import { colorful } from './colorful';
import { Template } from '../core/template';
import { dynamic } from './dynamic';
import { evening } from './evening';
import { modern } from './modern';
import { sophisticated } from './sophisticated';
import { traditional } from './traditional';

export const templates: Template[] = [
  bold,
  colorful,
  evening,
  modern,
  bright,
  dynamic,
  traditional,
  sophisticated,
];
