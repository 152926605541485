/**
 * Theme of components.
 *
 * Each component can support one or more themes. Every components support {@see Default} theme.
 */
export enum ComponentTheme {
  /** Default theme */
  Default,
  /** Theme in gray background colors */
  Gray,
  /** Theme in reg background colors */
  Red,
  /** Theme in transparent background colors */
  Transparent,
  /** Theme in transparent background and primary foreground colors */
  TransparentPrimary,
}
