import classNames from 'classnames';
import { ReactNode } from 'react';
import styles from './FormRow.module.scss';

interface RowProps {
  children: ReactNode;
  noPadding?: boolean;
}

export function FormRow(props: RowProps) {
  return (
    <div className={classNames({ [styles.row]: true, [styles.noPadding]: props.noPadding })}>
      {props.children}
    </div>
  );
}
