import { createContext } from 'react';

/** Events that can be emitted by template components */
export enum TemplateEvent {
  /** User clicked by one of popup close buttons */
  PopupDismissClick,
  /** User clicked by trigger button */
  TriggerButtonClick,
  /** User clicked by trigger button hide cross */
  TriggerButtonRemoveClick,
  /** User clicked by Main button */
  OpenSmsApplicationClick,
}

/** Template context type */
interface ContextType {
  /** Function that emit and process some `event` */
  emmitEvent: (event: TemplateEvent) => void;
  /** Flag that indicated, than popup must use face SMS application instead real */
  useFakeSmsApplication: boolean;
}

/** Template context that can available in all template */
export const TemplateContext = createContext<ContextType>({
  emmitEvent: (event: TemplateEvent) => {},
  useFakeSmsApplication: false,
});
