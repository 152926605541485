import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';
import { AppState } from '../types';

/** Initialize application with given application state */
export const initializeApp = createAction<AppState>('initializeApp');

/** Append reducer for {@see initializeApp} action to Redux slice */
export const addInitializeAppReducer = (builder: ActionReducerMapBuilder<AppState>) => {
  builder.addCase(initializeApp, (state, action) => {
    return action.payload;
  });
};
