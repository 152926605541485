import classNames from 'classnames';
import { createContext, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openEditPanelTab } from '../../../../state/actions/openEdtiPanelTab';
import { selectEditPanelState } from '../../../../state/selectors/selectEditPanelState';
import styles from './Tabs.module.scss';

export const TabContext = createContext('');

interface TabsProps {
  tabs: {
    label: string;
    id: string;
    hasError?: boolean;
  }[];
  children: ReactNode;
}

export function Tabs(props: TabsProps) {
  const dispatch = useDispatch();
  const editPanelState = useSelector(selectEditPanelState);

  const handleTabClick = (id: string) => () => {
    dispatch(openEditPanelTab(id));
  };

  const currentTabId =
    props.tabs.find((tab) => tab.id === editPanelState.activeTabId)?.id || props.tabs[0].id;

  return (
    <div>
      <ul className={styles.list}>
        {props.tabs.map((tab) => (
          <li className={styles.tabElement} key={tab.id}>
            <button
              className={classNames({
                [styles.tab]: true,
                [styles.activeTab]: tab.id === currentTabId,
                [styles.hasError]: tab.hasError,
              })}
              onClick={handleTabClick(tab.id)}
            >
              {tab.label}
            </button>
          </li>
        ))}
      </ul>

      <TabContext.Provider value={currentTabId}>{props.children}</TabContext.Provider>
    </div>
  );
}
