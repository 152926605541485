import {
  ClickActionType,
  FontFamily,
  FontWeight,
  HorizontalAlign,
  ImageFit,
  PoliciesLinkType,
  SmsType,
  WidgetType,
} from '../core/widgets/definitions';
import {
  CommonWidgetIds,
  DismissBehavior,
  FirstExperience,
  Template,
  TemplateTag,
  TimerType,
} from '../core/template';

export const traditional: Template = {
  userEngagement: {
    dismissBehavior: DismissBehavior.ShowTriggerButton,
    timer: {
      type: TimerType.Immediately,
    },
    firstExperience: FirstExperience.TriggerButton,
  },
  triggerButtonWidget: {
    text: 'Get 10% Off',
    widgetStyle: {
      borderRadius: 4,
      backgroundColor: '#9A9A9A',
      isExpanded: true,
      margin: {
        left: 12,
        bottom: 12,
        right: 12,
        top: 12,
      },
      padding: {
        top: 13,
        bottom: 13,
        right: 16,
        left: 16,
      },
      secondRowStyle: {
        fontSize: 10,
        fontWeight: FontWeight.w400,
        color: '#FFFFFF',
        fontFamily: FontFamily.Rubik,
      },
      firstRowStyle: {
        fontFamily: FontFamily.CrimsonText,
        color: '#FFFFFF',
        textAlign: HorizontalAlign.Center,
        fontWeight: FontWeight.w600,
        fontSize: 18,
      },
    },
  },
  triggerButtonCloseButtonWidget: {
    widgetStyle: {
      shadow: {
        radius: 8,
        color: '#00000029',
      },
      backgroundColor: '#FFFFFF',
      borderRadius: 20,
      size: 14,
      color: 'rgba(0, 27, 72, 0.31)',
      margin: {
        right: -8,
        top: -8,
        left: -8,
      },
      padding: {
        right: 4,
        bottom: 4,
        left: 4,
        top: 4,
      },
    },
  },
  popupCloseButtonWidget: {
    widgetStyle: {
      size: 20,
      padding: {
        bottom: 8,
        right: 8,
        top: 8,
        left: 8,
      },
      color: 'rgba(0, 27, 72, 0.31)',
    },
  },
  widgets: [
    {
      widgetStyle: {
        fit: ImageFit.Contain,
        align: HorizontalAlign.Center,
        size: {
          height: 70,
          width: 131,
        },
      },
      onClick: {
        url: 'https://southernmadetees.com',
        type: ClickActionType.OpenLink,
      },
      id: CommonWidgetIds.LogoImage,
      url:
        'https://firebasestorage.googleapis.com/v0/b/simple-texting-tools.appspot.com/o/configs%2FqS6CoU7xCitUEFJ_H2xrn%2Fimages%2FiOU08roKmvnFrqna1n2U9.png?alt=media',
      type: WidgetType.Image,
      localUrl: null,
      fileName: 'logo.png',
    },
    {
      type: WidgetType.Text,
      text: '10% Off',
      id: CommonWidgetIds.Headline,
      widgetStyle: {
        color: '#4D4D4D',
        textAlign: HorizontalAlign.Center,
        fontWeight: FontWeight.w600,
        fontFamily: FontFamily.CrimsonText,
        fontSize: 55,
        margin: {
          left: null,
          right: null,
          bottom: 10,
          top: 32,
        },
      },
    },
    {
      type: WidgetType.Text,
      id: CommonWidgetIds.SubHeadline,
      text: 'You Order',
      widgetStyle: {
        fontFamily: FontFamily.CrimsonText,
        fontWeight: FontWeight.w400,
        margin: {
          bottom: 48,
        },
        fontSize: 15,
        textAlign: HorizontalAlign.Center,
        color: '#75849C',
      },
    },
    {
      widgetStyle: {
        borderRadius: 4,
        margin: {
          bottom: 61,
          right: null,
          left: null,
          top: null,
        },
        isExpanded: true,
        firstRowStyle: {
          textAlign: HorizontalAlign.Center,
          fontFamily: FontFamily.CrimsonText,
          fontWeight: FontWeight.w600,
          fontSize: 18,
          color: '#FFFFFF',
        },
        secondRowStyle: {
          fontFamily: FontFamily.CrimsonText,
          fontSize: 12,
          color: '#FFFFFF',
          textAlign: HorizontalAlign.Center,
          fontWeight: FontWeight.w400,
        },
        backgroundColor: '#9A9A9A',
        padding: {
          left: 16,
          right: 16,
          bottom: 8,
          top: 8,
        },
      },
      type: WidgetType.Button,
      id: CommonWidgetIds.MainButton,
      text: ['Sign up for texts', 'to receive a discount code'],
      onClick: {
        type: ClickActionType.SendMessage,
        smsType: SmsType.Keyword,
        text: '',
        phone: '(844) 928-4252',
      },
    },
    {
      widgetStyle: {
        color: '#75849C',
        textAlign: HorizontalAlign.Center,
        fontSize: 9,
        fontWeight: FontWeight.w400,
        margin: {
          bottom: 16,
        },
        fontFamily: FontFamily.Rubik,
      },
      smsRate: 4,
      type: WidgetType.LegalText,
      id: CommonWidgetIds.LegalText,
    },
    {
      id: CommonWidgetIds.PoliciesText,
      widgetStyle: {
        margin: {
          bottom: 16,
        },
        color: '#75849C',
        fontWeight: FontWeight.w400,
        textAlign: HorizontalAlign.Center,
        fontFamily: FontFamily.Rubik,
        fontSize: 9,
      },
      link: {
        type: PoliciesLinkType.AutoGenerated,
        complianceEmail: 'stephen+tees@simpletexting.net',
        companyName: 'Southern Made Tees',
      },
      type: WidgetType.PoliciesText,
    },
    {
      widgetStyle: {
        color: '#75849C',
        margin: {
          top: 16,
        },
        fontWeight: FontWeight.w400,
        fontSize: 13,
        textAlign: HorizontalAlign.Center,
        fontFamily: FontFamily.Rubik,
      },
      id: CommonWidgetIds.SimpleTextingCopyright,
      type: WidgetType.SimpleTextingCopyrightText,
    },
  ],
  info: {
    tags: [TemplateTag.FullSize, TemplateTag.BackgroundImage, TemplateTag.SquareLogo],
    previewImageUrl: '/assets/themes/traditional.png',
    name: 'Traditional',
  },
  widgetPresets: {
    buttons: {
      rounded: {
        borderRadius: 4,
      },
      ghost: {
        border: {
          width: 1,
        },
        borderRadius: 0,
      },
      singleLine: {
        padding: {
          top: 13,
          bottom: 13,
          left: 16,
          right: 16,
        },
      },
      doubleLine: {
        padding: {
          bottom: 8,
          top: 8,
          left: 16,
          right: 16,
        },
      },
    },
    popup: {
      overlay: {
        color: '#33333320',
        blurRadius: 2,
      },
    },
  },
  horizontalPosition: HorizontalAlign.Right,
  mainContainerWidget: {
    widgetStyle: {
      backgroundColor: '#FFFFFF',
      backgroundImage: {
        opacity: 20,
        fit: ImageFit.Cover,
        localUrl: null,
        fileName: 'Southern_dollyFull.jpg',
        url:
          'https://firebasestorage.googleapis.com/v0/b/simple-texting-tools.appspot.com/o/configs%2FqS6CoU7xCitUEFJ_H2xrn%2Fimages%2FhyBT4wwlpSZ3n_zvMLDKQ.png?alt=media',
      },
      shadow: {
        radius: 40,
        color: '#00000029',
      },
      padding: {
        right: 32,
        bottom: 32,
        left: 32,
        top: 32,
      },
      borderRadius: 12,
      margin: {
        left: 12,
        right: 12,
        bottom: 12,
        top: 12,
      },
    },
  },
};
