import firebase from 'firebase/app';
import React from 'react';
import { Provider } from 'react-redux';
import './App.scss';
import { startBackendSync } from './services';
import { store } from './state/store';
import Modal from 'react-modal';
import { Layout } from './ui/layout';
import 'firebase/analytics';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Preview } from './ui/preview';

firebase.initializeApp({
  apiKey: 'AIzaSyDF_bSpET-p-dxlpwjW9aeIuAmLsC6Nedg',
  authDomain: 'tools-dev-303013.firebaseapp.com',
  projectId: 'tools-dev-303013',
  storageBucket: 'tools-dev-303013.appspot.com',
  messagingSenderId: '809619962905',
  appId: '1:809619962905:web:23d0d22edab6a5e3cdfe88',
});
firebase.analytics();
startBackendSync();

// react-modal library configuration
const root = document.getElementById('root');
if (!root) {
  throw new Error('Not found root element');
}
Modal.setAppElement(root);

/** Main application */
function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Route path="/preview/:configId?">
            <Preview />
          </Route>
          <Route path="/:configId?">
            <Layout />
          </Route>
        </Switch>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
