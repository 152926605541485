import { nanoid } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import Resizer from 'react-image-file-resizer';
import { selectConfigId } from '../state/selectors';
import { store } from '../state/store';
import 'firebase/storage';

/** Input for {@see uploadImage} */
interface UploadImageInput {
  /** Image BLOB */
  image: Blob;
  /** Upload options */
  config?: {
    /** Image maximum width */
    maxWidth?: number;
    /** Image maximum height */
    maxHeight?: number;
  };
}

/**
 * Resize image from `input.image` to size described in `input.config` and upload it
 * to remote server. Returns image URL.
 */
export async function uploadImage(input: UploadImageInput): Promise<string> {
  const configId = selectConfigId(store.getState());

  const resizedFile: Blob = await new Promise((resolve) => {
    Resizer.imageFileResizer(
      input.image,
      input.config?.maxWidth ?? 500,
      input.config?.maxHeight ?? 1000,
      'PNG',
      100,
      0,
      (uri) => {
        resolve(uri as Blob);
      },
      'blob'
    );
  });

  const storageRef = firebase.storage().ref();
  const imageRef = storageRef.child(`configs/${configId}/images/${nanoid()}.png`);
  const metadata = {
    cacheControl: 'public,max-age=300',
    contentType: 'image/png',
  };
  await imageRef.put(resizedFile, metadata);

  const fullImageUrl = await imageRef.getDownloadURL();
  const parsedImageUrl = new URL(fullImageUrl);
  parsedImageUrl.searchParams.delete('token');
  return parsedImageUrl.toString();
}
