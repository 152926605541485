import { nanoid } from '@reduxjs/toolkit';
import { WidgetConfig } from '../../core/widgets/types';
import { Template } from '../../core/template';

/**
 * Check widget and it children for existing of ID. If ID for widget or any it children not set
 * generate random ID and set it.
 */
function generateKeyForWidget<T extends WidgetConfig>(widgetConfig: T): T {
  if ('children' in widgetConfig) {
    return {
      ...widgetConfig,
      id: widgetConfig.id ?? nanoid(),
      children: (widgetConfig as any).children.map(generateKeyForWidget),
    };
  }

  if ('child' in widgetConfig) {
    return {
      ...widgetConfig,
      id: widgetConfig.id ?? nanoid(),
      child: generateKeyForWidget((widgetConfig as any).child),
    };
  }

  return {
    ...widgetConfig,
    id: widgetConfig.id ?? nanoid(),
  };
}

export function generateWidgetIds(template: Template): Template {
  const widgets = template.widgets.map(generateKeyForWidget);

  return {
    ...template,
    widgets,
  };
}
