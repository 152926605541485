import {
  Form,
  InputFormField,
  InputType,
  ColorFormField,
  normalizeColor,
} from '../../../../../form-components';
import { WidgetConfig, WithShadow } from '../../../../../../core/widgets/types';
import { useStyleConfigHelpers } from '../../hooks/useStyleConfigHelpers';
import { FormRow } from '../../../../../components/form-row/FormRow';
import { WidgetStyleConfigPanelProps } from '../types';

interface WithShadowConfig {
  dropShadowColor: string;
  dropShadowRadius: string;
}

export function ShadowConfig<T extends WidgetConfig>(
  props: WidgetStyleConfigPanelProps<WithShadow, T>
) {
  const [widgetStyle, onChange] = useStyleConfigHelpers(props);

  const initialValue: WithShadowConfig = {
    dropShadowColor: normalizeColor(widgetStyle?.shadow?.color),
    dropShadowRadius: widgetStyle?.shadow?.radius?.toFixed(0) ?? '',
  };

  const handleChange = (formValues: WithShadowConfig) => {
    onChange({
      shadow: {
        color: formValues.dropShadowColor || null,
        radius: formValues.dropShadowRadius !== '' ? Number(formValues.dropShadowRadius) : null,
      },
    });
  };

  return (
    <Form initialValues={initialValue} onValuesChange={handleChange}>
      <FormRow>
        <ColorFormField name="dropShadowColor" label="Shadow color" />

        <InputFormField
          name="dropShadowRadius"
          label="Shadow radius"
          type={InputType.PositiveNumbers}
        />
      </FormRow>
    </Form>
  );
}
