import { Form, InputFormField, InputType } from '../../../../../form-components';
import { WidgetConfig, WithPadding } from '../../../../../../core/widgets/types';
import { useStyleConfigHelpers } from '../../hooks';
import { EditPanelSection } from '../../../../../components';
import { WidgetStyleConfigPanelProps } from '../types';
import styles from './PaddingConfigSection.module.scss';

interface WithPaddingConfig {
  paddingLeft: string;
  paddingRight: string;
  paddingTop: string;
  paddingBottom: string;
}

export function PaddingConfigSection<T extends WidgetConfig>(
  props: WidgetStyleConfigPanelProps<WithPadding, T>
) {
  const [widgetStyle, onChange] = useStyleConfigHelpers(props);

  const initialValue: WithPaddingConfig = {
    paddingLeft: widgetStyle?.padding?.left?.toFixed(0) ?? '',
    paddingRight: widgetStyle?.padding?.right?.toFixed(0) ?? '',
    paddingTop: widgetStyle?.padding?.top?.toFixed(0) ?? '',
    paddingBottom: widgetStyle?.padding?.bottom?.toFixed(0) ?? '',
  };

  const handleChange = (formValues: WithPaddingConfig) => {
    onChange({
      padding: {
        left: formValues.paddingLeft !== '' ? Number(formValues.paddingLeft) : null,
        right: formValues.paddingRight !== '' ? Number(formValues.paddingRight) : null,
        top: formValues.paddingTop !== '' ? Number(formValues.paddingTop) : null,
        bottom: formValues.paddingBottom !== '' ? Number(formValues.paddingBottom) : null,
      },
    });
  };

  return (
    <EditPanelSection title="Padding">
      <Form initialValues={initialValue} onValuesChange={handleChange}>
        <div className={styles.padding}>
          <div className={styles.top}>
            <InputFormField name="paddingTop" label="Top" type={InputType.PositiveNumbers} />
          </div>
          <div className={styles.leftAndRight}>
            <div className={styles.left}>
              <InputFormField name="paddingLeft" label="Left" type={InputType.PositiveNumbers} />
            </div>
            <div className={styles.right}>
              <InputFormField name="paddingRight" label="Right" type={InputType.PositiveNumbers} />
            </div>
          </div>
          <div className={styles.bottom}>
            <InputFormField name="paddingBottom" label="Bottom" type={InputType.PositiveNumbers} />
          </div>
        </div>
      </Form>
    </EditPanelSection>
  );
}
