import { Template } from '../../../../../../core/template';
import { HorizontalAlign } from '../../../../../../core/widgets/definitions';
import { EditPanelDescription, EditPanelSection } from '../../../../../components';
import { Form, HorizontalPositionFormField } from '../../../../../form-components';

interface FormConfig {
  position: HorizontalAlign;
}

interface PositionConfigSectionProps {
  template: Template;
  onChange: (template: Partial<Template>) => void;
}

export function PositionConfigSection(props: PositionConfigSectionProps) {
  const initialValue: FormConfig = {
    position: props.template.horizontalPosition ?? HorizontalAlign.Right,
  };

  const handleChange = (formValues: FormConfig) => {
    props.onChange({
      horizontalPosition: formValues.position,
    });
  };

  return (
    <EditPanelSection title="Position">
      <Form initialValues={initialValue} onValuesChange={handleChange}>
        <EditPanelDescription>
          Chose a position of the trigger button and popup.
        </EditPanelDescription>

        <HorizontalPositionFormField name="position" />
      </Form>
    </EditPanelSection>
  );
}
