import classNames from 'classnames';
import { CSSProperties } from 'react';
import { HorizontalAlign } from '../../widgets/definitions';
import { PopupContainerProps, RenderTarget } from '../TemplateRender';
import styles from './DebugPopup.module.scss';

/** Popup container with development features like drag and drop and widget selection */
export function DebugPopupContainer(props: PopupContainerProps): JSX.Element | null {
  let alignItems: CSSProperties['alignItems'] = 'flex-end';
  switch (props.template.horizontalPosition) {
    case HorizontalAlign.Left:
      alignItems = 'flex-start';
      break;
    case HorizontalAlign.Center:
      alignItems = 'center';
      break;
    case HorizontalAlign.Right:
      alignItems = 'flex-end';
      break;
  }

  return (
    <div
      className={classNames('st-tools', styles.stTools)}
      style={{
        backgroundColor:
          props.renderTarget === RenderTarget.PopUp
            ? props.template.mainContainerWidget.widgetStyle?.overlay?.color ?? 'transparent'
            : 'transparent',
        backdropFilter:
          props.renderTarget === RenderTarget.PopUp &&
          props.template.mainContainerWidget.widgetStyle?.overlay?.blurRadius
            ? `blur(${props.template.mainContainerWidget.widgetStyle.overlay.blurRadius}px)`
            : undefined,
      }}
    >
      {props.renderTarget === RenderTarget.PopUp ? (
        <div
          className={styles.overlay}
          style={{
            top: props.template.mainContainerWidget.widgetStyle?.margin?.top ?? 0,
            right: props.template.mainContainerWidget.widgetStyle?.margin?.right ?? 0,
            bottom: props.template.mainContainerWidget.widgetStyle?.margin?.bottom ?? 0,
            left: props.template.mainContainerWidget.widgetStyle?.margin?.left ?? 0,
            alignItems,
          }}
        >
          <div className={styles.popup}>{props.popup}</div>
        </div>
      ) : (
        <div
          className={styles.overlay}
          style={{
            top: props.template.triggerButtonWidget.widgetStyle?.margin?.top ?? 0,
            right: props.template.triggerButtonWidget.widgetStyle?.margin?.right ?? 0,
            bottom: props.template.triggerButtonWidget.widgetStyle?.margin?.bottom ?? 0,
            left: props.template.triggerButtonWidget.widgetStyle?.margin?.left ?? 0,
            alignItems,
          }}
        >
          <div className={styles.triggerButton}>{props.triggerButton}</div>
        </div>
      )}
    </div>
  );
}
