import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Validator } from '../../../services';
import { setPreviewDevice, setPreviewUrl } from '../../../state/actions';
import { selectPreviewConfig } from '../../../state/selectors';
import { Form, InputFormField, SelectFormField, SelectValue } from '../../form-components';
import styles from './PreviewConfigForm.module.scss';
import { Button } from '../button/Button';
import { ComponentTheme } from '../definitions';

export const devices: SelectValue[] = [
  { label: 'Galaxy S5', value: '360x640' },
  { label: 'Pixel 2', value: '411x731' },
  { label: 'Pixel 2 XL', value: '411x823' },
  { label: 'iPhone 5/SE', value: '320x568' },
  { label: 'iPhone 6/7/8', value: '375x667' },
  { label: 'iPhone 6/7/8 Plus', value: '414x736' },
  { label: 'iPhone X', value: '375x812' },
  { label: 'Galaxy Fold', value: '280x653' },
  // { label: 'Template Preview', value: '410x860' }, // Used for generate template preview
];

export function PreviewConfigForm() {
  const dispatch = useDispatch();

  const config = useSelector(selectPreviewConfig);
  const device = devices.find((element) => element.value === config.deviceScreen) ?? devices[0];

  const [hasError, setHasError] = useState(false);
  const [url, setUrl] = useState(config.url);

  useEffect(() => {
    setTimeout(() => setUrl(config.url));
  }, [config.url]);

  const handleValuesChange = (values: { device: string; url: string }) => {
    if (device.value !== values.device) {
      dispatch(setPreviewDevice(values.device));
    }
    setUrl(values.url);
  };

  const handleApplyClick = () => {
    if (!hasError) {
      dispatch(setPreviewUrl(url));
    }
  };

  return (
    <div className={styles.controls}>
      <Form
        className={styles.form}
        initialValues={{
          device: device.value,
          url,
        }}
        onValuesChange={handleValuesChange}
        onIsValidChange={(valid) => setHasError(!valid)}
        validate={(values) => {
          const errors: { [name: string]: string } = {};
          if (!Validator.isURL(values.url)) {
            errors['url'] = 'Enter valid URL';
          }

          return errors;
        }}
      >
        <div className={styles.deviceSelect}>
          <SelectFormField
            theme={ComponentTheme.Transparent}
            label="Device"
            name="device"
            options={devices}
          />
        </div>
        <div className={styles.urlInput}>
          <InputFormField theme={ComponentTheme.Transparent} label="Site URL" name="url" />
        </div>
      </Form>
      <div className={styles.applyButton}>
        <Button theme={ComponentTheme.Transparent} onClick={handleApplyClick}>
          Apply
        </Button>
      </div>
    </div>
  );
}
