import { useDispatch, useSelector } from 'react-redux';
import { CommonWidgetIds, Template } from '../../../../../../core/template';
import { HorizontalAlign, WidgetType } from '../../../../../../core/widgets/definitions';
import { ButtonWidgetConfig } from '../../../../../../core/widgets/types';
import { setMainHorizontalPosition } from '../../../../../../state/actions';
import { selectTemplate } from '../../../../../../state/selectors';
import {
  AccordionSection,
  EditPanelDescription,
  EditPanelSection,
  FormRow,
} from '../../../../../components';
import { InputFormField } from '../../../../../form-components';
import { useWidgetConfig } from '../../hooks';
import {
  BackgroundColorConfig,
  BorderConfig,
  BorderRadiusConfig,
  ClickActionConfigSection,
  FontConfig,
  MarginConfigSection,
  PaddingConfigSection,
  PositionConfigSection,
  SetupConfigSection,
  StylingSection,
  validateOnClickConfig,
} from '../../panel-components';

interface ButtonWidgetPanelProps {
  id: string;
}

interface FormConfig {
  firstText: string;
  secondText: string;
}

function fromWidgetConfigToFormConfig(config: ButtonWidgetConfig): FormConfig {
  const firstRowText = (Array.isArray(config.text) ? config.text[0] : config.text) ?? '';
  const secondRowText = (Array.isArray(config.text) ? config.text[1] : '') ?? '';

  return {
    firstText: firstRowText,
    secondText: secondRowText,
  };
}

function fromFormConfigToWidgetConfig(config: FormConfig): Partial<ButtonWidgetConfig> {
  return {
    text: [config.firstText, config.secondText] as [string, string],
  };
}

export function ButtonWidgetPanel(props: ButtonWidgetPanelProps) {
  const dispatch = useDispatch();

  const template = useSelector(selectTemplate);
  const [widget, handleChanges] = useWidgetConfig(props.id, WidgetType.Button);

  const isValid = validateOnClickConfig(widget);

  let title: string;
  let subTitle: string;
  let isHiddenEnabled: boolean;
  let isRemoveEnabled: boolean;
  let isClickActionVisible: boolean;
  let isDismissActionVisible: boolean;
  let isPositionVisible: boolean;

  switch (props.id) {
    case CommonWidgetIds.TriggerButton:
      title = 'Trigger button';
      subTitle = '';
      isHiddenEnabled = false;
      isRemoveEnabled = false;
      isClickActionVisible = false;
      isDismissActionVisible = false;
      isPositionVisible = true;
      break;
    case CommonWidgetIds.MainButton:
      title = 'Main button';
      subTitle = '';
      isHiddenEnabled = true;
      isRemoveEnabled = false;
      isClickActionVisible = true;
      isDismissActionVisible = false;
      isPositionVisible = false;
      break;
    case CommonWidgetIds.DismissButton:
      title = 'Dismiss button';
      subTitle = '';
      isHiddenEnabled = false;
      isRemoveEnabled = true;
      isClickActionVisible = false;
      isDismissActionVisible = true;
      isPositionVisible = false;
      break;
    default:
      title = 'Button';
      subTitle = Array.isArray(widget.text) ? widget.text[0] : widget.text;
      isHiddenEnabled = false;
      isRemoveEnabled = true;
      isClickActionVisible = true;
      isDismissActionVisible = false;
      isPositionVisible = false;
      break;
  }

  const handlePositionChange = (values: Partial<Template>) => {
    dispatch(setMainHorizontalPosition(values.horizontalPosition ?? HorizontalAlign.Right));
  };

  return (
    <AccordionSection
      id={props.id}
      title={title}
      subTitle={subTitle}
      isHasError={!isValid}
      isControlledWidgetCanBeRemoved={isRemoveEnabled}
      isControlledWidgetVisible={widget.hidden}
      isControlledWidgetCanChangeVisible={isHiddenEnabled}
    >
      <SetupConfigSection
        widget={widget}
        onChange={handleChanges}
        fromWidgetConfigToFormConfig={fromWidgetConfigToFormConfig}
        fromFormConfigToWidgetConfig={fromFormConfigToWidgetConfig}
      >
        <FormRow>
          <InputFormField name="firstText" label="First text line" placeholder="eg: Get 10% Off" />
        </FormRow>
        <FormRow>
          <InputFormField
            name="secondText"
            label="Second text line"
            placeholder="eg: When you sign up for texts"
          />
        </FormRow>
      </SetupConfigSection>

      {isPositionVisible && (
        <PositionConfigSection template={template} onChange={handlePositionChange} />
      )}

      {isClickActionVisible && (
        <ClickActionConfigSection
          widget={widget}
          onChange={handleChanges}
          openUrlActionEnabled={widget.id !== CommonWidgetIds.MainButton}
          closePopupActionEnabled={widget.id !== CommonWidgetIds.MainButton}
        />
      )}

      {isDismissActionVisible && (
        <EditPanelSection title="Click action">
          <EditPanelDescription>
            The popup will be closed by clicking on this button.
          </EditPanelDescription>
        </EditPanelSection>
      )}

      <StylingSection>
        <FormRow>
          <BackgroundColorConfig widget={widget} onChange={handleChanges} />
          <BorderRadiusConfig widget={widget} onChange={handleChanges} />
        </FormRow>
        <BorderConfig widget={widget} onChange={handleChanges} />
      </StylingSection>

      <EditPanelSection title="First text line styling">
        <FontConfig
          widget={widget}
          onChange={handleChanges}
          styleFromWidgetConfig={(widget) => widget.widgetStyle?.firstRowStyle}
          styleToWidgetConfig={(styles) => ({ widgetStyle: { firstRowStyle: styles } })}
        />
      </EditPanelSection>

      <EditPanelSection title="Second text line styling">
        <FontConfig
          widget={widget}
          onChange={handleChanges}
          styleFromWidgetConfig={(widget) => widget.widgetStyle?.secondRowStyle}
          styleToWidgetConfig={(styles) => ({ widgetStyle: { secondRowStyle: styles } })}
        />
      </EditPanelSection>

      <MarginConfigSection widget={widget} onChange={handleChanges} />
      <PaddingConfigSection widget={widget} onChange={handleChanges} />
    </AccordionSection>
  );
}
