import { useSelector } from 'react-redux';
import { CommonWidgetIds } from '../../../../../../core/template';
import { findTemplateWidget } from '../../../../../../core/utils';
import { WidgetType } from '../../../../../../core/widgets/definitions';
import { selectTemplate } from '../../../../../../state/selectors';
import {
  AccordionSection,
  EditPanelDescription,
  EditPanelSection,
  FormRow,
  Tab,
  Tabs,
} from '../../../../../components';
import {
  ColorFormField,
  FontFamilySelectFormField,
  FontWeightSelectFormField,
  Form,
  HorizontalAlignFormField,
  HorizontalPositionFormField,
  InputFormField,
  InputType,
  SelectFormField,
  SelectValue,
  SwitchFormField,
} from '../../../../../form-components';
import styles from './StylingPanel.module.scss';
import { ButtonShape, useButtonsForm } from './useButtonsForm';
import { useGeneralForm } from './useGeneralForm';
import { useTextForm } from './useTextForm';

const buttonShapeOptions: SelectValue[] = [
  { value: ButtonShape.Rounded, label: 'Rounded button' },
  { value: ButtonShape.Square, label: 'Square button' },
  { value: ButtonShape.Pill, label: 'Pill button' },
  { value: ButtonShape.Ghost, label: 'Ghost button' },
];

export function StylingPanel() {
  const template = useSelector(selectTemplate);

  const generalForm = useGeneralForm(template);
  const buttonsForm = useButtonsForm(template);
  const textForm = useTextForm(template);

  const hasDismissButton = !!findTemplateWidget(
    template,
    CommonWidgetIds.DismissButton,
    WidgetType.Button
  );
  const hasCopyright = !!findTemplateWidget(
    template,
    CommonWidgetIds.SimpleTextingCopyright,
    WidgetType.SimpleTextingCopyrightText
  );
  const hasPreHeadline = !!findTemplateWidget(
    template,
    CommonWidgetIds.PreHeadline,
    WidgetType.Text
  );
  const hasHeadline = !!findTemplateWidget(template, CommonWidgetIds.Headline, WidgetType.Text);
  const hasSubHeadline = !!findTemplateWidget(
    template,
    CommonWidgetIds.SubHeadline,
    WidgetType.Text
  );
  let preAndSubHeadlineSectionTitle = 'Pre & subheadline';
  if (hasPreHeadline && hasSubHeadline) {
    preAndSubHeadlineSectionTitle = 'Pre & subheadline';
  } else if (hasPreHeadline) {
    preAndSubHeadlineSectionTitle = 'Pre-headline';
  } else if (hasSubHeadline) {
    preAndSubHeadlineSectionTitle = 'Subheadline';
  }

  return (
    <AccordionSection id="base_styling" title="Styling">
      <Tabs
        tabs={[
          { label: 'General', id: 'general' },
          { label: 'Buttons', id: 'buttons' },
          { label: 'Text', id: 'text' },
        ]}
      >
        <Tab id="general">
          <Form
            initialValues={generalForm.initialValues}
            onValuesChange={generalForm.saveFormValues}
          >
            <EditPanelSection title="Position">
              <EditPanelDescription>Choose position of the trigger button.</EditPanelDescription>

              <HorizontalPositionFormField name="position" />
            </EditPanelSection>

            <EditPanelSection title="Popup settings">
              <FormRow>
                <ColorFormField name="backgroundColor" label="Background color" />
              </FormRow>

              <FormRow noPadding={true}>
                <SwitchFormField name="overlayVisible" label="Fade background" />
              </FormRow>

              {hasCopyright && (
                <FormRow noPadding={true}>
                  <SwitchFormField
                    name="simpleTextingCopyrightEnabled"
                    label="Powered By SimpleTexting.com"
                  />
                </FormRow>
              )}
            </EditPanelSection>
          </Form>
        </Tab>

        <Tab id="buttons">
          <Form
            initialValues={buttonsForm.initialValues}
            onValuesChange={buttonsForm.saveFormValues}
          >
            <EditPanelSection title="Button style">
              <FormRow>
                <SelectFormField
                  name="buttonShape"
                  label="Look & Feel"
                  options={buttonShapeOptions}
                />
              </FormRow>
              <FormRow>
                <FontFamilySelectFormField name="buttonFontFamily" label="Font" />
              </FormRow>
            </EditPanelSection>
            <EditPanelSection title="Main and trigger buttons">
              <FormRow>
                <ColorFormField
                  name="buttonBackgroundColor"
                  label={
                    buttonsForm.initialValues.buttonShape === ButtonShape.Ghost
                      ? 'Foreground color'
                      : 'Background color'
                  }
                />
                <FontWeightSelectFormField name="buttonFontWeight" label="Font weight" />
              </FormRow>
              {buttonsForm.initialValues.buttonShape !== ButtonShape.Ghost && (
                <EditPanelDescription>
                  Buttons text color is calculated based on background color to meet accessibilty
                  standards.
                </EditPanelDescription>
              )}
              {buttonsForm.initialValues.buttonShape === ButtonShape.Ghost && (
                <EditPanelDescription>
                  Button background color will be the same as the pop-up background color.
                </EditPanelDescription>
              )}
            </EditPanelSection>
            <EditPanelSection title="Dismiss button">
              {hasDismissButton ? (
                <>
                  <FormRow>
                    <ColorFormField name="dismissButtonBackgroundColor" label="Background color" />
                    <FontWeightSelectFormField name="dismissButtonFontWeight" label="Font weight" />
                  </FormRow>
                  <EditPanelDescription>
                    Button text color is calculated based on background color to meet accessibilty
                    standards.
                  </EditPanelDescription>
                </>
              ) : (
                <EditPanelDescription>
                  This template does not use a dismiss button. Switch to a different theme to use a
                  dismiss button.
                </EditPanelDescription>
              )}
            </EditPanelSection>
          </Form>
        </Tab>

        <Tab id="text">
          <Form initialValues={textForm.initialValues} onValuesChange={textForm.saveFormValues}>
            <EditPanelSection title="Headline">
              {hasHeadline ? (
                <>
                  <FormRow>
                    <FontFamilySelectFormField name="headlineFontFamily" label="Font" />
                  </FormRow>
                  <FormRow>
                    <ColorFormField name="headlineColor" label="Color" />
                    <div className={styles.fontWeightAndAlign}>
                      <FontWeightSelectFormField name="headlineFontWight" label="Font weight" />
                    </div>
                  </FormRow>
                  <FormRow>
                    <InputFormField
                      name="headlineFontSize"
                      label="Font size"
                      type={InputType.NotNullPositiveNumbers}
                    />
                    <div className={styles.fontWeightAndAlign}>
                      <HorizontalAlignFormField name="headlineAlign" label="Text align" />
                    </div>
                  </FormRow>
                </>
              ) : (
                <EditPanelDescription>
                  This template does not use a headline. Switch to a different theme to use a
                  headline.
                </EditPanelDescription>
              )}
            </EditPanelSection>

            <EditPanelSection title={preAndSubHeadlineSectionTitle}>
              {hasPreHeadline || hasSubHeadline ? (
                <>
                  <FormRow>
                    <FontFamilySelectFormField name="preAndSubHeadlineFontFamily" label="Font" />
                  </FormRow>
                  <FormRow>
                    <ColorFormField name="preAndSubHeadlineColor" label="Color" />
                    <div className={styles.fontWeightAndAlign}>
                      <FontWeightSelectFormField
                        name="preAndSubHeadlineFontWight"
                        label="Font weight"
                      />
                    </div>
                  </FormRow>
                  <FormRow>
                    <InputFormField
                      name="preAndSubHeadlineFontSize"
                      label="Font size"
                      type={InputType.NotNullPositiveNumbers}
                    />
                    <div className={styles.fontWeightAndAlign}>
                      <HorizontalAlignFormField name="preAndSubHeadlineAlign" label="Text align" />
                    </div>
                  </FormRow>
                </>
              ) : (
                <EditPanelDescription>
                  This template does not use a pre & subheadline. Switch to a different theme to use
                  a pre & subheadline.
                </EditPanelDescription>
              )}
            </EditPanelSection>

            <EditPanelSection title="Paddings">
              {hasPreHeadline || hasHeadline || hasSubHeadline ? (
                <>
                  {hasPreHeadline && (
                    <FormRow>
                      <InputFormField
                        name="preHeadlineTopPadding"
                        label="Pre-headline top padding"
                        type={InputType.PositiveNumbers}
                      />
                    </FormRow>
                  )}

                  {hasHeadline && (
                    <>
                      <FormRow>
                        <InputFormField
                          name="headlineTopPadding"
                          label="Headline top padding"
                          type={InputType.PositiveNumbers}
                        />
                      </FormRow>
                      <FormRow>
                        <InputFormField
                          name="headlineBottomPadding"
                          label="Headline bottom padding"
                          type={InputType.PositiveNumbers}
                        />
                      </FormRow>
                    </>
                  )}

                  {hasSubHeadline && (
                    <FormRow>
                      <InputFormField
                        name="subHeadlineBottomPadding"
                        label="Subheadline bottom padding"
                        type={InputType.PositiveNumbers}
                      />
                    </FormRow>
                  )}
                </>
              ) : (
                <EditPanelDescription>
                  This template does not use headlines. Switch to a different theme to use
                  headlines.
                </EditPanelDescription>
              )}
            </EditPanelSection>
          </Form>
        </Tab>
      </Tabs>
    </AccordionSection>
  );
}
