import { useDispatch } from 'react-redux';
import { removeWidget } from '../../../state/actions';
import { Button, ComponentTheme } from '../../components';
import { ModalActionButtons, ModalDescription, Modal } from '../components';

interface RemoveWidgetModalProps {
  widgetId: string;
  onRequestClose: () => void;
}

export function RemoveWidgetModal(props: RemoveWidgetModalProps) {
  const dispatch = useDispatch();
  const handleRemoveClick = () => {
    props.onRequestClose();
    dispatch(removeWidget(props.widgetId));
  };

  return (
    <Modal
      title="Are you sure want to remove this widget?"
      isOpen={true}
      onRequestClose={props.onRequestClose}
    >
      <ModalDescription>This operation can't be undone!</ModalDescription>

      <ModalActionButtons>
        <Button theme={ComponentTheme.TransparentPrimary} onClick={props.onRequestClose}>
          Cancel
        </Button>
        <Button onClick={handleRemoveClick}>Remove</Button>
      </ModalActionButtons>
    </Modal>
  );
}
