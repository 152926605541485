import classNames from 'classnames';
import { useField } from 'formik';
import { RefObject, useEffect, useRef } from 'react';
import MaskedInput from 'react-text-mask';
import { ComponentTheme } from '../../components';
import { FormField } from '../form-field/FormField';
import styles from './ColorFormField.module.scss';
import Pickr from '@simonwep/pickr';

const colorMaskPart = /[\dABCDEFabcdef]/;
const colorMask = [
  '#',
  colorMaskPart,
  colorMaskPart,
  colorMaskPart,
  colorMaskPart,
  colorMaskPart,
  colorMaskPart,
  colorMaskPart,
  colorMaskPart,
];

export function normalizeColor(color?: string | null): string {
  if (!color) {
    return '';
  }

  let result = color.toUpperCase();

  if (color.length === 4) {
    result = `#${color[1]}${color[1]}${color[2]}${color[2]}${color[3]}${color[3]}`;
  }

  return result;
}

function usePickr(ref: RefObject<HTMLElement>, value: string, setValue: (color: string) => void) {
  useEffect(() => {
    if (!ref.current) {
      throw new Error('Not found ref for Pickr');
    }

    const pickerBG = Pickr.create({
      el: ref.current,
      theme: 'monolith',
      default: value || '#FFFFFF',
      useAsButton: true,
      swatches: [
        'rgba(255, 255, 255, 1)',
        'rgba(0, 0, 0, 1)',
        'rgba(244, 67, 54, 1)',
        'rgba(233, 30, 99, 1)',
        'rgba(156, 39, 176, 1)',
        'rgba(103, 58, 183, 1)',
        'rgba(63, 81, 181, 1)',
        'rgba(33, 150, 243, 1)',
        'rgba(3, 169, 244, 1)',
        'rgba(0, 188, 212, 1)',
        'rgba(0, 150, 136, 1)',
        'rgba(76, 175, 80, 1)',
        'rgba(139, 195, 74, 1)',
        'rgba(205, 220, 57, 1)',
        'rgba(255, 235, 59, 1)',
        'rgba(255, 193, 7, 1)',
      ],
      components: {
        preview: true,
        opacity: true,
        hue: true,
        interaction: {
          input: true,
          clear: true,
          save: true,
        },
      },
      // strings: {
      //   save: 'Save',
      // },
    });

    pickerBG.on('change', (color: Pickr.HSVaColor | null) => {
      setValue(color ? color.toHEXA().toString() : '');
    });

    pickerBG.on('save', (color: Pickr.HSVaColor | null) => {
      pickerBG.hide();
      setValue(color ? color.toHEXA().toString() : '');
    });

    pickerBG.on('clear', () => {
      setValue('');
    });

    return () => pickerBG.destroyAndRemove();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

interface ColorFormFieldProps {
  name: string;
  label: string;
  theme?: ComponentTheme;
  placeholder?: string;
  helperText?: string;
}

export function ColorFormField(props: ColorFormFieldProps) {
  const [field, meta, helpers] = useField(props as any);

  const pickerRef = useRef<HTMLDivElement>(null);

  usePickr(pickerRef, field.value, (color) => {
    helpers.setTouched(true);
    const value = color === '#FFFFFF00' ? '' : color;
    helpers.setValue(value);
  });

  return (
    <div className={styles.colorField}>
      <FormField
        label={props.label}
        theme={props.theme}
        error={meta.error}
        helperText={props.helperText}
      >
        <MaskedInput
          {...field}
          mask={colorMask}
          guide={false}
          showMask={false}
          className={classNames({
            [styles.input]: true,
            [styles.inputTransparent]: props.theme === ComponentTheme.Transparent,
            [styles.hasError]: meta.error,
          })}
          onInput={(event) => {
            // This is MaskedInput fix
            // Without it, when value deleted onChange is not emitted
            const target = event.currentTarget;
            setInterval(() => {
              helpers.setValue(target.value);
            });
          }}
          placeholder={props.placeholder ?? 'None'}
        />

        <div className={styles.colorPicker}>
          <div
            className={styles.colorPickerButton}
            ref={pickerRef}
            style={{ backgroundColor: field.value }}
          />
        </div>
      </FormField>
    </div>
  );
}
