import { WidgetType } from '../../../../../../core/widgets/definitions';
import { AccordionSection, EditPanelSection } from '../../../../../components';
import { useWidgetConfig } from '../../hooks';
import { FontConfig, MarginConfigSection } from '../../panel-components';

interface TextWidgetPanelProps {
  id: string;
}

export function SimpleTextingCopyrightTextWidgetPanel(props: TextWidgetPanelProps) {
  const [widget, handleChanges] = useWidgetConfig(props.id, WidgetType.SimpleTextingCopyrightText);

  return (
    <AccordionSection
      id={props.id}
      title="SimpleTexting copyright"
      isControlledWidgetVisible={widget.hidden}
      isControlledWidgetCanChangeVisible={true}
    >
      <EditPanelSection title="Styling">
        <FontConfig widget={widget} onChange={handleChanges} />
      </EditPanelSection>

      <MarginConfigSection widget={widget} onChange={handleChanges} />
    </AccordionSection>
  );
}
