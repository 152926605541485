import { Validator } from '../../../../../../services';
import { validatePolices } from '../../../../../../core/utils';
import { InputFormField, RadioButton } from '../../../../../form-components';
import { PoliciesLinkType, WidgetType } from '../../../../../../core/widgets/definitions';
import { PoliciesTextWidgetConfig } from '../../../../../../core/widgets/types';
import { AccordionSection, EditPanelDescription, FormRow } from '../../../../../components';
import { useWidgetConfig } from '../../hooks';
import {
  FontConfig,
  MarginConfigSection,
  SetupConfigSection,
  StylingSection,
} from '../../panel-components';

interface TextWidgetPanelProps {
  id: string;
}

interface FormConfig {
  setupLinkType: string;
  setupCompanyName: string;
  setupComplianceEmail: string;
  setupTermsConditionsLink: string;
  setupPrivacyPolicyLink: string;
}

function validateConfig(config: PoliciesTextWidgetConfig) {
  return validatePolices(config);
}

function validateForm(values: FormConfig) {
  const errors: { [name in keyof typeof values]?: string } = {};

  switch (values.setupLinkType) {
    case PoliciesLinkType.AutoGenerated: {
      if (!values.setupCompanyName) {
        errors['setupCompanyName'] = 'Enter your company name';
      }
      if (!Validator.isEmail(values.setupComplianceEmail)) {
        errors['setupComplianceEmail'] = 'Enter valid email';
      }
      break;
    }
    case PoliciesLinkType.Manual: {
      if (!Validator.isURL(values.setupPrivacyPolicyLink)) {
        errors['setupPrivacyPolicyLink'] = 'Enter valid URL';
      }
      if (!Validator.isURL(values.setupTermsConditionsLink)) {
        errors['setupTermsConditionsLink'] = 'Enter valid URL';
      }
      break;
    }
  }

  return errors;
}

function fromWidgetConfigToFormConfig(config: PoliciesTextWidgetConfig): FormConfig {
  return {
    setupLinkType: config.link?.type ?? PoliciesLinkType.AutoGenerated,
    setupCompanyName:
      config.link && 'companyName' in config.link ? config.link.companyName ?? '' : '',
    setupComplianceEmail:
      config.link && 'complianceEmail' in config.link ? config.link.complianceEmail ?? '' : '',
    setupTermsConditionsLink:
      config.link && 'termsConditionsLink' in config.link
        ? config.link.termsConditionsLink ?? ''
        : '',
    setupPrivacyPolicyLink:
      config.link && 'privacyPolicyLink' in config.link ? config.link.privacyPolicyLink ?? '' : '',
  };
}

function fromFormConfigToWidgetConfig(config: FormConfig): Partial<PoliciesTextWidgetConfig> {
  let link: PoliciesTextWidgetConfig['link'];
  if (config.setupLinkType === PoliciesLinkType.Manual) {
    link = {
      type: PoliciesLinkType.Manual,
      termsConditionsLink: config.setupTermsConditionsLink || '',
      privacyPolicyLink: config.setupPrivacyPolicyLink || '',
    };
  } else {
    link = {
      type: PoliciesLinkType.AutoGenerated,
      companyName: config.setupCompanyName || '',
      complianceEmail: config.setupComplianceEmail || '',
    };
  }

  return {
    link,
  };
}

export function PoliciesTextWidgetPanel(props: TextWidgetPanelProps) {
  const [widget, handleChanges] = useWidgetConfig(props.id, WidgetType.PoliciesText);

  const isValid = validateConfig(widget);

  return (
    <AccordionSection
      id={props.id}
      title="Privacy policy"
      isHasError={!isValid}
      isControlledWidgetVisible={widget.hidden}
      isControlledWidgetCanChangeVisible={true}
    >
      <SetupConfigSection
        widget={widget}
        onChange={handleChanges}
        validate={validateForm}
        fromWidgetConfigToFormConfig={fromWidgetConfigToFormConfig}
        fromFormConfigToWidgetConfig={fromFormConfigToWidgetConfig}
      >
        <EditPanelDescription>
          To help you comply with industry regulations, we can generate and host your Terms &
          Conditions and Privacy Policy pages.
        </EditPanelDescription>

        <RadioButton
          name="setupLinkType"
          label="Auto-generated compliance"
          value={PoliciesLinkType.AutoGenerated}
          maxWidth={292}
        >
          <FormRow>
            <InputFormField
              name="setupCompanyName"
              label="Company name"
              placeholder="eg: SimpleTexting"
            />
          </FormRow>
          <FormRow>
            <InputFormField
              name="setupComplianceEmail"
              label="Compliance email"
              placeholder="eg: hello@simpletexting.com"
            />
          </FormRow>
        </RadioButton>

        <RadioButton
          name="setupLinkType"
          label="Custom compliance links"
          value={PoliciesLinkType.Manual}
          maxWidth={292}
        >
          <FormRow>
            <InputFormField
              name="setupTermsConditionsLink"
              label="Terms & Conditions link"
              placeholder="eg: https://simpletexting.com"
            />
          </FormRow>
          <FormRow>
            <InputFormField
              name="setupPrivacyPolicyLink"
              label="Privacy Policy link"
              placeholder="eg: https://simpletexting.com"
            />
          </FormRow>
        </RadioButton>
      </SetupConfigSection>

      <StylingSection>
        <FontConfig widget={widget} onChange={handleChanges} />
      </StylingSection>

      <MarginConfigSection widget={widget} onChange={handleChanges} />
    </AccordionSection>
  );
}
