import classNames from 'classnames';
import { ReactNode } from 'react';
import styles from './EditPanelMainSection.module.scss';

interface SectionProps {
  title: string;
  children: ReactNode;
  noMargins?: boolean;
}

export function EditPanelMainSection(props: SectionProps) {
  return (
    <section className={styles.section}>
      <h1 className={classNames({ [styles.title]: true, [styles.noMargins]: props.noMargins })}>
        {props.title}
      </h1>

      {props.children}
    </section>
  );
}
