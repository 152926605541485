import { ReactNode } from 'react';
import styles from './ModalActionButtons.module.scss';

interface ActionButtonsProps {
  children: ReactNode;
}

export function ModalActionButtons(props: ActionButtonsProps) {
  return <div className={styles.actionButtons}>{props.children}</div>;
}
