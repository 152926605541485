import { Dispatch } from 'react';
import { useDispatch } from 'react-redux';
import { Validator } from '../../../../../../services';
import { setContacts } from '../../../../../../state/actions';
import { AppState } from '../../../../../../state/types';
import { UseFormResult } from '../../types';

interface FormConfig {
  companyName: string;
  phone: string;
  email: string;
  webSite: string;
}

function validateForm(values: FormConfig) {
  const errors: { [name in keyof FormConfig]?: string } = {};

  if (!values.companyName) {
    errors['companyName'] = 'Enter company name';
  }

  if (!Validator.isEmail(values.email)) {
    errors['email'] = 'Enter valid email';
  }

  return errors;
}

function formInitialValue(contacts: AppState['contacts']): FormConfig {
  return contacts;
}

const saveFormValues = (contacts: AppState['contacts'], dispatch: Dispatch<any>) => (
  values: FormConfig
) => {
  dispatch(setContacts(values));
};

export function useForm(contacts: AppState['contacts']): UseFormResult<FormConfig> {
  const dispatch = useDispatch();
  const initialValue = formInitialValue(contacts);

  return {
    initialValues: initialValue,
    saveFormValues: saveFormValues(contacts, dispatch),
    validateForm: validateForm,
    isValid: true,
  };
}
