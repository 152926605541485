import { Dispatch } from 'react';
import { useDispatch } from 'react-redux';
import { setUserEngagement } from '../../../../../../state/actions';
import {
  DismissBehavior,
  FirstExperience,
  Template,
  TimerType,
} from '../../../../../../core/template';
import { validateUserEngagement } from '../../../../../../core/utils';
import { UseFormResult } from '../../types';

interface FormConfig {
  firstExperience: FirstExperience;
  timer: TimerType;
  dismissBehavior: DismissBehavior;
  delaySec: string;
}

function formInitialValue(template: Template): FormConfig {
  return {
    firstExperience: template.userEngagement.firstExperience,
    timer: template.userEngagement.timer.type,
    dismissBehavior: template.userEngagement.dismissBehavior,
    delaySec:
      'delaySec' in template.userEngagement.timer
        ? template.userEngagement.timer.delaySec.toFixed()
        : '',
  };
}

const saveFormValues = (template: Template, dispatch: Dispatch<any>) => (values: FormConfig) => {
  dispatch(
    setUserEngagement({
      firstExperience: values.firstExperience,
      timer: {
        type: values.timer,
        delaySec: values.delaySec ? Number(values.delaySec) : 0,
      },
      dismissBehavior: values.dismissBehavior,
    })
  );
};

function validateForm(values: FormConfig): { [name in keyof FormConfig]?: string } {
  const errors: { [name in keyof FormConfig]?: string } = {};

  if (values.timer === TimerType.Delay && (!values.delaySec || values.delaySec === '0')) {
    errors['delaySec'] = 'Must be not empty';
  }
  return errors;
}

function isValid(template: Template) {
  return validateUserEngagement(template.userEngagement);
}

export function useUserEngagementForm(template: Template): UseFormResult<FormConfig> {
  const dispatch = useDispatch();
  const initialValue = formInitialValue(template);

  return {
    initialValues: initialValue,
    saveFormValues: saveFormValues(template, dispatch),
    validateForm,
    isValid: isValid(template),
  };
}
