import { CSSProperties, ReactNode } from 'react';
import { baseWidgetStyleToCss } from '../utils';
import { ColumnWidgetConfig, WidgetConfig } from '../types';

/** Function that used for render child widgets */
type WidgetRender = (props: WidgetConfig & { style?: CSSProperties }) => ReactNode;

/** Props {@see ColumnWidget} */
interface ColumnWidgetProps extends ColumnWidgetConfig {
  /** Function that used for render child widgets */
  widgetRender: WidgetRender;
  /** Custom CSS styles */
  style?: CSSProperties;
  /** Custom CSS styles of every children */
  childrenStyle?: CSSProperties;
}

/** Implementation of column widget */
export function ColumnWidget(props: ColumnWidgetProps) {
  return (
    <ul
      style={{
        ...baseWidgetStyleToCss(props.widgetStyle),
        display: 'flex',
        flexDirection: 'column',
        margin: 0,
        padding: 0,
        listStyle: 'none',
        ...props.style,
      }}
    >
      {props.children.map((childProps, index) => {
        return (
          <li key={childProps.id ?? index.toString()} style={{ display: 'flex' }}>
            {props.widgetRender({ ...childProps, style: props.childrenStyle })}
          </li>
        );
      })}
    </ul>
  );
}
