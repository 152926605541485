import { validateOnClick } from '../../../../../../core/utils';
import { ClickActionType, SmsType } from '../../../../../../core/widgets/definitions';
import { WidgetConfig, WithOnClick } from '../../../../../../core/widgets/types';
import { Validator } from '../../../../../../services';
import { EditPanelDescription, EditPanelSection, FormRow } from '../../../../../components';
import {
  Form,
  InputFormField,
  InputType,
  RadioButton,
  TextareaFormField,
} from '../../../../../form-components';
import { WidgetConfigPanelProps } from '../types';

interface FormConfig {
  actionType: string;
  phoneNumber: string;
  smsType: SmsType;
  message: string;
  url: string;
}

interface ClickActionConfigSectionProps<T extends WidgetConfig> extends WidgetConfigPanelProps<T> {
  noneActionEnabled?: boolean;
  openUrlActionEnabled?: boolean;
  closePopupActionEnabled?: boolean;
}

export function validateOnClickConfig(config: WithOnClick) {
  return validateOnClick(config.onClick);
}

function validateForm(values: FormConfig) {
  const errors: { [name in keyof typeof values]?: string } = {};

  if (values.actionType === ClickActionType.SendMessage && !Validator.isPhone(values.phoneNumber)) {
    errors['phoneNumber'] = 'Enter valid phone';
  }

  if (values.actionType === ClickActionType.OpenLink && !Validator.isURL(values.url)) {
    errors['url'] = 'Enter valid URL';
  }

  return errors;
}

export function ClickActionConfigSection<T extends WidgetConfig & WithOnClick>(
  props: ClickActionConfigSectionProps<T>
) {
  const initialValue: FormConfig = {
    actionType: props.widget.onClick?.type ?? ClickActionType.None,
    phoneNumber:
      (props.widget.onClick && 'phone' in props.widget.onClick ? props.widget.onClick.phone : '') ??
      '',
    smsType:
      (props.widget.onClick && 'smsType' in props.widget.onClick
        ? props.widget.onClick.smsType
        : SmsType.Keyword) ?? SmsType.Keyword,
    message:
      (props.widget.onClick && 'text' in props.widget.onClick ? props.widget.onClick.text : '') ??
      '',
    url:
      (props.widget.onClick && 'url' in props.widget.onClick ? props.widget.onClick.url : '') ?? '',
  };

  const handleChange = (formValues: FormConfig) => {
    let onClick: WithOnClick['onClick'];
    switch (formValues.actionType) {
      case ClickActionType.SendMessage:
        onClick = {
          type: ClickActionType.SendMessage,
          phone: formValues.phoneNumber,
          smsType: formValues.smsType,
          text: formValues.message,
        };
        break;
      case ClickActionType.OpenLink:
        onClick = {
          type: ClickActionType.OpenLink,
          url: formValues.url,
        };
        break;
      case ClickActionType.ClosePopup:
        onClick = {
          type: ClickActionType.ClosePopup,
        };
        break;
      case ClickActionType.None:
        onClick = {
          type: ClickActionType.None,
        };
        break;
    }

    props.onChange({
      onClick,
    });
  };

  const handleIsValidChange = (valid: boolean) => {
    if (props.onIsValidChange) {
      props.onIsValidChange(valid);
    }
  };

  const hasOnlySendMessageAction =
    !props.noneActionEnabled && !props.openUrlActionEnabled && !props.closePopupActionEnabled;

  const sendMessageForm = (
    <>
      <FormRow>
        <InputFormField
          name="phoneNumber"
          label="Texting phone number"
          type={InputType.PhoneNumber}
          placeholder="eg: (888) 617-2847"
          disabled={props.widget.onClick?.type !== ClickActionType.SendMessage}
        />
      </FormRow>
      <FormRow>
        <TextareaFormField
          name="message"
          label="Text message"
          placeholder="Keyword or default message..."
          rows={4}
          disabled={props.widget.onClick?.type !== ClickActionType.SendMessage}
        />
      </FormRow>
    </>
  );

  return (
    <EditPanelSection title="Click action">
      <Form
        initialValues={initialValue}
        onValuesChange={handleChange}
        onIsValidChange={handleIsValidChange}
        validate={validateForm}
      >
        {props.noneActionEnabled && (
          <RadioButton name="actionType" label="None" value={ClickActionType.None} maxWidth={292} />
        )}

        {/*{!hasOnlySendMessageAction && (*/}
        {/*  <RadioButton*/}
        {/*    name="actionType"*/}
        {/*    label="Send message"*/}
        {/*    value={ClickActionType.SendMessage}*/}
        {/*    maxWidth={292}*/}
        {/*  >*/}
        {/*    {sendMessageForm}*/}
        {/*  </RadioButton>*/}
        {/*)}*/}
        {hasOnlySendMessageAction && (
          <>
            <EditPanelDescription>
              Pre-populate the text message to your number
            </EditPanelDescription>
            {sendMessageForm}
          </>
        )}
        {(props.openUrlActionEnabled ?? true) && (
          <RadioButton
            name="actionType"
            label="Open URL"
            value={ClickActionType.OpenLink}
            maxWidth={292}
          >
            <FormRow>
              <InputFormField
                name="url"
                label="URL to open"
                placeholder="eg: https://simpletexting.com"
                disabled={props.widget.onClick?.type !== ClickActionType.OpenLink}
              />
            </FormRow>
          </RadioButton>
        )}
        {(props.closePopupActionEnabled ?? true) && (
          <RadioButton
            name="actionType"
            label="Close popup"
            value={ClickActionType.ClosePopup}
            maxWidth={292}
          />
        )}
      </Form>
    </EditPanelSection>
  );
}
