import { CSSProperties } from 'react';
import { HorizontalAlign } from '../definitions';
import { baseWidgetStyleToCss, useOnClick } from '../utils';
import { ImageStyle, ImageWidgetConfig } from '../types';
import omit from 'lodash/omit';

/** Props for {@see ImageWidget} */
interface ImageWidgetProps extends ImageWidgetConfig {
  /** Custom CSS styles */
  style?: CSSProperties;
}

/** Implementation of image widget */
export function ImageWidget(props: ImageWidgetProps) {
  const containerStyles = {
    display: 'block',
    width: '100%',
    marginTop: props.widgetStyle?.margin?.top ?? 0,
    marginLeft: props.widgetStyle?.margin?.left ?? 0,
    marginRight: props.widgetStyle?.margin?.right ?? 0,
    marginBottom: props.widgetStyle?.margin?.bottom ?? 0,
  };

  const img = (
    // eslint-disable-next-line jsx-a11y/img-redundant-alt
    <img
      src={props.localUrl || props.url}
      width={props.widgetStyle?.size?.width || undefined}
      height={props.widgetStyle?.size?.height || undefined}
      style={{
        ...imageWidgetStyleToCss(props.widgetStyle),
        ...props.style,
      }}
      alt="Popup image"
    />
  );

  const { href, handleClick } = useOnClick(props);

  if (href) {
    return (
      <a target='_parent' href={href} style={containerStyles}>
        {img}
      </a>
    );
  } else {
    return (
      <div style={containerStyles} onClick={handleClick}>
        {img}
      </div>
    );
  }
}

function imageWidgetStyleToCss(style?: ImageStyle): CSSProperties {
  const marginLeft = style?.align !== HorizontalAlign.Left ? 'auto' : undefined;
  const marginRight = style?.align !== HorizontalAlign.Right ? 'auto' : undefined;

  return {
    ...baseWidgetStyleToCss(omit(style, ['margin'] as any)),
    display: 'block',
    width: style?.size?.width ?? undefined,
    height: style?.size?.height ?? undefined,
    marginLeft,
    marginRight,
    objectFit: style?.fit as any, // We know that size is valid
  };
}
