const containerStyle = {
  display: 'inline-block',
  position: 'absolute',
  visibility: 'hidden',
  zIndex: -1,
};

/**
 * Returns DOM node width and height
 *
 * @param node DOM node
 * @param enhanceMeasurableNode Function for prepare the node to measuring
 */
export function measureDomNode(
  node: HTMLElement,
  enhanceMeasurableNode = (e: HTMLElement) => e
): {
  width: number;
  height: number;
} {
  const container: HTMLDivElement = document.createElement('div');
  (container as any).style = containerStyle;

  const clonedNode = node.cloneNode(true) as HTMLElement;
  const content = enhanceMeasurableNode(clonedNode);

  container.appendChild(content);

  document.body.appendChild(container);

  const height = content.clientHeight;
  const width = content.clientWidth;

  (container as any).parentNode.removeChild(container);
  return { height, width };
}
