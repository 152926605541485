import { useSelector } from 'react-redux';
import { findTemplateWidget } from '../../../../../../core/utils';
import { selectTemplate } from '../../../../../../state/selectors';
import { CommonWidgetIds } from '../../../../../../core/template';
import {
  FormRow,
  EditPanelDescription,
  EditPanelSection,
  AccordionSection,
} from '../../../../../components';
import { Form, InputFormField, ImageFormField } from '../../../../../form-components';
import { WidgetType } from '../../../../../../core/widgets/definitions';
import { useForm } from './useForm';

export function ImagesPanel() {
  const template = useSelector(selectTemplate);

  const form = useForm(template);

  const mainContainer = findTemplateWidget(
    template,
    CommonWidgetIds.MainContainer,
    WidgetType.Container
  );
  if (!mainContainer) {
    throw new Error('Not found main container');
  }
  const hasBackground = !!mainContainer.widgetStyle?.backgroundImage?.url;
  const hasLogo = !!findTemplateWidget(template, CommonWidgetIds.LogoImage, WidgetType.Image);
  const hasHero = !!findTemplateWidget(template, CommonWidgetIds.HeroImage, WidgetType.Image);

  return (
    <AccordionSection id="base_images" title="Logo & images" isHasError={!form.isValid}>
      <Form
        initialValues={form.initialValues}
        onValuesChange={form.saveFormValues}
        validate={form.validateForm}
      >
        <EditPanelSection title="Logo">
          {hasLogo ? (
            <>
              <FormRow>
                <ImageFormField
                  name="logoFileName"
                  label="File"
                  onChanged={(value) =>
                    form.saveFormValues({
                      ...form.initialValues,
                      logoUrl: value.url ?? '',
                      logoLocalUrl: value.localUrl ?? '',
                      logoFileName: value.fileName ?? '',
                    })
                  }
                />
              </FormRow>
              <FormRow>
                <InputFormField
                  name="logoLink"
                  label="Link on clicking the logo"
                  placeholder="Logo redirect url here"
                />
              </FormRow>
            </>
          ) : (
            <EditPanelDescription>
              This template does not use a logo image. Switch to a different theme to use a logo
              image.
            </EditPanelDescription>
          )}
        </EditPanelSection>

        <EditPanelSection title="Background image">
          {hasBackground ? (
            <>
              <FormRow>
                <ImageFormField
                  name="backgroundFileName"
                  label="File"
                  onChanged={(value) =>
                    form.saveFormValues({
                      ...form.initialValues,
                      backgroundUrl: value.url ?? '',
                      backgroundLocalUrl: value.localUrl ?? '',
                      backgroundFileName: value.fileName ?? '',
                    })
                  }
                />
              </FormRow>
            </>
          ) : (
            <EditPanelDescription>
              This template does not use a background image. Switch to a different theme to use a
              background image.
            </EditPanelDescription>
          )}
        </EditPanelSection>

        <EditPanelSection title="Feature image">
          {hasHero ? (
            <>
              <FormRow>
                <ImageFormField
                  name="heroFileName"
                  label="File"
                  onChanged={(value) =>
                    form.saveFormValues({
                      ...form.initialValues,
                      heroUrl: value.url ?? '',
                      heroLocalUrl: value.localUrl ?? '',
                      heroFileName: value.fileName ?? '',
                    })
                  }
                />
              </FormRow>
            </>
          ) : (
            <EditPanelDescription>
              This template does not use a feature image. Switch to a different theme to use a
              feature image.
            </EditPanelDescription>
          )}
        </EditPanelSection>
      </Form>
    </AccordionSection>
  );
}
