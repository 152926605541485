import { nanoid } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { Validator } from '../../../services';
import { initializeApp } from '../../../state/actions';
import { Button, FormRow } from '../../components';
import { Form, InputFormField, InputType } from '../../form-components';
import { ModalDescription, Modal } from '../components';
import { useHistory } from 'react-router-dom';
import step2Image from './step2-image.png';
import styles from './TwoTapsToTextModal.module.scss';

interface FormConfig {
  companyName: string;
  phone: string;
  webSite: string;
  email: string;
}

interface TwoTapsToTextModalProps {}

function validate(values: FormConfig) {
  const errors: { [name in keyof FormConfig]?: string } = {};

  if (!values.companyName) {
    errors['companyName'] = 'Enter company name';
  }

  if (!Validator.isPhone(values.phone)) {
    errors['phone'] = 'Enter valid text enabled number';
  }

  if (!Validator.isURL(values.webSite)) {
    errors['webSite'] = 'Enter valid URL';
  }

  if (!Validator.isEmail(values.email)) {
    errors['email'] = 'Enter valid email';
  }

  return errors;
}

export function TwoTapsToTextModal(props: TwoTapsToTextModalProps) {
  const dispatch = useDispatch();
  const history = useHistory();

  const initialValues: FormConfig = {
    companyName: '',
    phone: '',
    webSite: '',
    email: '',
  };

  const handleOnSubmit = (values: FormConfig) => {
    const configId = nanoid();

    dispatch(
      initializeApp({
        configId,
        isTemplateChanged: true,
        contacts: {
          companyName: values.companyName,
          phone: values.phone,
          email: values.email,
          webSite: values.webSite,
        },
        preview: {
          deviceScreen: '360x640', // Galaxy S5
          url: values.webSite,
        },
        editPanel: {
          isExpertModeEnabled: false,
          isAccordionAutoScrollEnabled: false,
          expandedAccordionSectionId: '',
          activeTabId: '',
        },
      })
    );

    history.push(`/${configId}/gallery`);
  };

  return (
    <Modal isOpen={true} onRequestClose={() => {}} disableClose={true} width={740}>
      <Form initialValues={initialValues} validate={validate} onSubmit={handleOnSubmit}>
        <div className={styles.modal}>
          <div className={styles.leftColumn}>
            <h1 className={styles.title}>Let's get your widget started</h1>

            <ModalDescription>
              You'll be capturing phone numbers in no time. Enter your info below to get started.
              You can edit this later.
            </ModalDescription>

            <div className={styles.form}>
              <FormRow>
                <InputFormField
                  name="companyName"
                  label="Company name"
                  placeholder="SimpleTexting"
                  showErrorOnTouched={true}
                />
              </FormRow>
              <FormRow>
                <InputFormField
                  name="phone"
                  label="SimpleTexting number"
                  placeholder="(888) 616-0525"
                  type={InputType.PhoneNumber}
                  showErrorOnTouched={true}
                />
              </FormRow>
              <FormRow>
                <InputFormField
                  name="webSite"
                  label="Company website"
                  placeholder="simpletexting.com"
                  showErrorOnTouched={true}
                />
              </FormRow>
              <FormRow>
                <InputFormField
                  name="email"
                  label="Contact email"
                  placeholder="hello@simpletexting.com"
                  showErrorOnTouched={true}
                />
              </FormRow>
            </div>
          </div>

          <div className={styles.rightColumn}>
            <img className={styles.image} src={step2Image} alt="Popups" />

            <div className={styles.button}>
              <Button type="submit">Pick a template</Button>
            </div>
          </div>
        </div>
      </Form>
    </Modal>
  );
}
