import { ReactNode } from 'react';
import { EditPanelSection } from '../../../../../components/edit-panel-section/EditPanelSection';

interface StylingSectionProps {
  children: ReactNode;
}

export function StylingSection(props: StylingSectionProps) {
  return <EditPanelSection title="Styling">{props.children}</EditPanelSection>;
}
