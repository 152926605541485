import { useDispatch, useSelector } from 'react-redux';
import { RenderTarget } from '../../../core/template-renders/TemplateRender';
import { selectContacts } from '../../../state/selectors';
import { publish } from '../../../state/thunks/publish';
import { Button, Device, RenderType } from '../../components';
import { Modal, ModalDescription } from '../components';
import styles from './PublishModal.module.scss';

interface PublishModalProps {
  onRequestClose: () => void;
  onPublished: () => void;
}

export function PublishModal(props: PublishModalProps) {
  const dispatch = useDispatch();

  const contacts = useSelector(selectContacts);

  const handlePublishClick = async () => {
    dispatch(publish());

    props.onPublished();
  };

  return (
    <Modal
      title="Publish changes to your widget"
      isOpen={true}
      onRequestClose={props.onRequestClose}
      width={500}
    >
      <div className={styles.content}>
        <div className={styles.preview}>
          <Device
            screenWidth={360}
            screenHeight={640}
            scale={0.4}
            renderTarget={RenderTarget.PopUp}
            renderType={RenderType.Static}
          />
        </div>

        <div>
          <ModalDescription>
            Hit publish and your changes will appear anywhere your widget is embedded.
          </ModalDescription>

          <div className={styles.publishButton}>
            <Button onClick={handlePublishClick}>Publish</Button>
          </div>

          <ModalDescription small={true}>
            Embed instructions and a return link will be sent to: {contacts.email}
          </ModalDescription>
        </div>
      </div>
    </Modal>
  );
}
