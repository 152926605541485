import { useDispatch, useSelector } from 'react-redux';
import { mergeWidgetConfig } from '../../../../../state/actions/mergeWidgetConfig';
import { selectWidgetConfig } from '../../../../../state/selectors/selectWidgetConfig';
import { DeepPartial } from '../../../../../types/deepPartial';
import { WidgetType } from '../../../../../core/widgets/definitions';
import { WidgetConfig } from '../../../../../core/widgets/types';

export function useWidgetConfig<WT extends WidgetType, T extends WidgetConfig & { type: WT }>(
  id: string,
  type: WT
): [T, (config: DeepPartial<T>) => void] {
  const dispatch = useDispatch();

  const widget = useSelector(selectWidgetConfig(id));
  if (widget?.type !== type) {
    throw new Error(`Widget ${id} is not ${type} widget`);
  }

  const handleChanges = (config: DeepPartial<WidgetConfig>) => {
    dispatch(mergeWidgetConfig({ ...config, id }));
  };

  return [widget, handleChanges] as any;
}
