import { HorizontalAlign } from './widgets/definitions';
import {
  ButtonWidgetConfig,
  ContainerWidgetConfig,
  IconButtonWidgetConfig,
  WidgetConfig,
  WithOverlay,
  WithPadding,
} from './widgets/types';

/** Tags for filtering templates */
export enum TemplateTag {
  FullSize = 'fullSize',
  Mini = 'mini',
  FeatureImage = 'featureImage',
  BackgroundImage = 'backgroundImage',
  OfferFocused = 'offerFocused',
  TextOnly = 'textOnly',
  SquareLogo = 'SquareLogo',
}

/** Reserved ID for common widgets */
export enum CommonWidgetIds {
  /** Float button that display popup */
  TriggerButton = 'triggerButton',
  /** Popup main container (background) */
  MainContainer = 'mainContainer',
  /** Popup top right close button (cross) */
  PopupCloseButton = 'popupCloseButton',
  /** Button that permanently remove Trigger button (small cross) */
  TriggerButtonCloseButton = 'triggerButtonCloseButton',
  /** Image with company logo */
  LogoImage = 'logoImage',
  /** Main image */
  HeroImage = 'heroImage',
  /** First line of text */
  PreHeadline = 'preHeadline',
  /** Main line of text */
  Headline = 'headline',
  /** Third line of text */
  SubHeadline = 'subHeadline',
  /** Button that sends SMS */
  MainButton = 'mainButton',
  /** Button that closed popup */
  DismissButton = 'dismissButton',
  /** Text with legal info */
  LegalText = 'legalText',
  /** Text with policies info */
  PoliciesText = 'policiesText',
  /** Text with SimpleTexting copyright */
  SimpleTextingCopyright = 'simpleTextingCopyright',
}

/** What must be shown when our JS script will be loaded */
export enum FirstExperience {
  /** Popup will be shown */
  ShowPopUp = 'showPopUp',
  /** Trigger button will be shown */
  TriggerButton = 'triggerButton',
}

/** Type of timer that controls how fast first popup or trigger button must be shown */
export enum TimerType {
  /** Popup or trigger button will be shown immediately after script loaded */
  Immediately = 'immediately',
  /** Popup or trigger button will be shown with delay after script loaded */
  Delay = 'delay',
}

/** Behaviour that control what should happen after popup is closed */
export enum DismissBehavior {
  /** Show trigger button again */
  ShowTriggerButton = 'showTriggerButton',
  /** Hide popup and trigger button forever */
  CloseAll = 'closeAll',
}

/** Configs for some widgets. Used in easy Customizer mode */
export interface WidgetsPresetConfigs {
  /** Preset for popup configuration */
  popup: {
    /** Default overlay configuration */
    overlay: Required<Required<WithOverlay>['overlay']>;
  };
  /** Presets for buttons with different types */
  buttons: {
    rounded: {
      borderRadius: number;
    };
    ghost: {
      borderRadius: number;
      border: {
        width: number;
      };
    };
    singleLine: {
      padding: Required<WithPadding>['padding'];
    };
    doubleLine: {
      padding: Required<WithPadding>['padding'];
    };
  };
}

/** Template definition */
export interface Template {
  /** Base template information */
  info: {
    /** Name of the template */
    name: string;
    /**
     * URL to template preview image.
     *
     * Keep template preview images in `/public/assets/themes/` in PNG format on transparent background.
     * And then use URL like '/assets/themes/FullSizeOfferFocusedHorizLogo.png' (without `/public`).
     */
    previewImageUrl: string;
    /** Tags for filtering */
    tags: TemplateTag[];
  };
  /** Preset for some widgets that will be used in easy Customizer mode */
  widgetPresets: WidgetsPresetConfigs;
  /** How widget should behave after script is loading */
  userEngagement: {
    /** What must be shown (popup or trigger button) */
    firstExperience: FirstExperience;
    /** How fast popup or trigger button must be shown */
    timer:
      | {
          type: TimerType.Immediately;
        }
      | {
          type: TimerType.Delay;
          /** Delay in seconds for waiting before popup or trigger button will be shown */
          delaySec: number;
        };
    /** What should happen when user close popup */
    dismissBehavior: DismissBehavior;
  };
  /** Popup and trigger button horizontal position */
  horizontalPosition: HorizontalAlign;
  /** Trigger button configuration */
  triggerButtonWidget: Omit<ButtonWidgetConfig, 'id' | 'type' | 'onClick'>;
  /** Main popup container (popup background) configuration */
  mainContainerWidget: Omit<ContainerWidgetConfig, 'id' | 'type' | 'child'>;
  /** Popup close button (top right cross) configuration */
  popupCloseButtonWidget: Omit<IconButtonWidgetConfig, 'id' | 'type' | 'icon'>;
  /** Trigger button close button (small cross) configuration */
  triggerButtonCloseButtonWidget: Omit<IconButtonWidgetConfig, 'id' | 'type' | 'icon'>;
  /** Popup child widgets */
  widgets: WidgetConfig[];
}
