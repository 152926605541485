import { Form, InputFormField, InputType } from '../../../../../form-components';
import { WidgetConfig } from '../../../../../../core/widgets/types';
import { useStyleConfigHelpers } from '../../hooks/useStyleConfigHelpers';
import { WidgetStyleConfigPanelProps } from '../types';

export interface WithIconSizeConfig {
  iconSize: string;
}

export function IconSizeConfig<T extends WidgetConfig>(
  props: WidgetStyleConfigPanelProps<{ size?: number | null }, T>
) {
  const [widgetStyle, onChange] = useStyleConfigHelpers(props);

  const initialValue: WithIconSizeConfig = {
    iconSize: widgetStyle?.size?.toFixed(0) ?? '',
  };

  const handleChange = (formValues: WithIconSizeConfig) => {
    onChange({
      size: formValues.iconSize !== '' ? Number(formValues.iconSize) : null,
    });
  };

  return (
    <Form initialValues={initialValue} onValuesChange={handleChange}>
      <InputFormField name="iconSize" label="Icon size" type={InputType.PositiveNumbers} />
    </Form>
  );
}
