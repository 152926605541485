/**
 * Fix URL and return it.
 *
 * Append to URL if needed `http://`
 *
 * @param url Some URL
 */
export function fixUrl(url: string): string {
  const lowerCaseUrl = url.toLowerCase();

  if (lowerCaseUrl.startsWith('https://') || lowerCaseUrl.startsWith('http://')) {
    return url;
  }

  return 'http://' + url;
}
