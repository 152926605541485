import classNames from 'classnames';
import { ReactNode, useEffect, useState } from 'react';
import { ComponentTheme } from '../../components';
import styles from './FormField.module.scss';

interface FormFieldProps {
  label: string;
  children: ReactNode;
  theme?: ComponentTheme;
  error?: string;
  helperText?: ReactNode;
  wrapToLabel?: boolean;
  disabled?: boolean;
}

export function FormField(props: FormFieldProps) {
  const [errorText, setErrorText] = useState('');
  const [helperText, setHelperText] = useState<ReactNode>('');

  useEffect(() => {
    if (props.error) {
      setErrorText(props.error);
    }
  }, [props.error]);

  useEffect(() => {
    if (props.helperText) {
      setHelperText(props.helperText);
    }
  }, [props.helperText]);

  const bottomText = props.error ? 'error' : props.helperText ? 'helper' : '';

  const content = (
    <>
      <span
        className={classNames({
          [styles.label]: true,
          [styles.disabledLabel]: props.disabled,
          [styles.labelTransparent]: props.theme === ComponentTheme.Transparent,
        })}
      >
        {props.label}
      </span>

      {props.children}

      <span className={styles.bottomTextContainer}>
        <span
          className={classNames({
            [styles.error]: true,
            [styles.bottomText]: true,
            [styles.bottomTextVisible]: bottomText === 'error',
          })}
        >
          {errorText}
        </span>

        {props.helperText !== undefined && (
          <span
            className={classNames({
              [styles.helperText]: true,
              [styles.bottomText]: true,
              [styles.bottomTextVisible]: bottomText === 'helper',
            })}
          >
            {helperText}
          </span>
        )}
      </span>
    </>
  );

  const wrapToLabel = props.wrapToLabel ?? true;

  if (wrapToLabel) {
    return <label className={styles.formField}>{content}</label>;
  } else {
    return <div className={styles.formField}>{content}</div>;
  }
}
