import { CSSProperties } from 'react';
import { WidgetType } from '../definitions';
import { baseWidgetStyleToCss, useOnClick } from '../utils';
import { ButtonStyle, ButtonWidgetConfig, ColumnWidgetConfig } from '../types';
import { Widget } from './Widget';

/** Props for {@see ButtonWidget} */
interface ButtonWidgetProps extends ButtonWidgetConfig {
  /** Custom CSS styles */
  style?: CSSProperties;
}

/** Implementation of button widget */
export function ButtonWidget(props: ButtonWidgetProps) {
  const childWidget: ColumnWidgetConfig = {
    id: 'buttonColumn',
    type: WidgetType.Column,
    children: [
      {
        id: 'firstButtonLine',
        type: WidgetType.Text,
        text: Array.isArray(props.text) ? props.text[0] : props.text,
        widgetStyle: props.widgetStyle?.firstRowStyle,
      },
    ],
  };
  if (Array.isArray(props.text) && props.text.length === 2) {
    childWidget.children.push({
      id: 'secondButtonLine',
      type: WidgetType.Text,
      text: props.text[1],
      widgetStyle: props.widgetStyle?.secondRowStyle,
    });
  }

  const { href, handleClick } = useOnClick(props);

  if (href) {
    return (
      <a
        target='_parent'
        href={href}
        style={{
          display: 'block',
          ...buttonWidgetStyleToCss(props.widgetStyle),
          ...props.style,
        }}
      >
        <Widget {...childWidget} />
      </a>
    );
  } else {
    return (
      <button
        style={{
          ...buttonWidgetStyleToCss(props.widgetStyle),
          ...props.style,
        }}
        onClick={handleClick}
      >
        <Widget {...childWidget} />
      </button>
    );
  }
}

function buttonWidgetStyleToCss(style?: ButtonStyle): CSSProperties {
  return {
    ...baseWidgetStyleToCss(style),
    appearance: 'none',
    display: 'block',
    cursor: 'pointer',
    width: style?.isExpanded ? '100%' : undefined,
  };
}
