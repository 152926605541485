import { Validator } from '../../services';
import { PoliciesLinkType } from '../widgets/definitions';
import { PoliciesTextWidgetConfig } from '../widgets/types';

/**
 * Validate polices template configuration. Returns `true` if configuration valid.
 *
 * @param policiesText Polices template configuration.
 */
export function validatePolices(policiesText?: PoliciesTextWidgetConfig): boolean {
  switch (policiesText?.link?.type) {
    case PoliciesLinkType.AutoGenerated: {
      return !!(
        policiesText?.link?.companyName && Validator.isEmail(policiesText?.link?.complianceEmail)
      );
    }
    case PoliciesLinkType.Manual: {
      return (
        Validator.isURL(policiesText?.link?.privacyPolicyLink) &&
        Validator.isURL(policiesText?.link?.termsConditionsLink)
      );
    }
  }

  return false;
}
