import {
  Form,
  InputFormField,
  InputType,
  ColorFormField,
  normalizeColor,
  FontFamilySelectFormField,
  FontWeightSelectFormField,
  HorizontalAlignFormField,
} from '../../../../../form-components';
import {
  FontFamily,
  FontWeight,
  HorizontalAlign,
} from '../../../../../../core/widgets/definitions';
import { TextStyle, WidgetConfig } from '../../../../../../core/widgets/types';
import { useStyleConfigHelpers } from '../../hooks/useStyleConfigHelpers';
import { FormRow } from '../../../../../components/form-row/FormRow';
import { WidgetStyleConfigPanelProps } from '../types';
import styles from './FontConfig.module.scss';

interface WithFontConfig {
  textAlign: HorizontalAlign;
  fontFamily: FontFamily;
  fontSize: string;
  fontWeight: FontWeight;
  color: string;
}

export function FontConfig<T extends WidgetConfig>(
  props: WidgetStyleConfigPanelProps<TextStyle, T>
) {
  const [widgetStyle, onChange] = useStyleConfigHelpers(props);

  const initialValue: WithFontConfig = {
    textAlign: widgetStyle?.textAlign ?? HorizontalAlign.Center,
    fontFamily: widgetStyle?.fontFamily ?? FontFamily.Rubik,
    fontSize: widgetStyle?.fontSize?.toFixed(0) ?? '',
    fontWeight: widgetStyle?.fontWeight ?? FontWeight.w400,
    color: normalizeColor(widgetStyle?.color),
  };

  const handleChange = (formValues: WithFontConfig) => {
    onChange({
      textAlign: formValues.textAlign,
      fontFamily: formValues.fontFamily,
      fontSize: formValues.fontSize !== '' ? Number(formValues.fontSize) : null,
      fontWeight: formValues.fontWeight,
      color: formValues.color || null,
    });
  };

  return (
    <Form initialValues={initialValue} onValuesChange={handleChange}>
      <FormRow>
        <FontFamilySelectFormField
          label="Font"
          name="fontFamily"
          menuPortalTarget={document.body}
        />
      </FormRow>
      <FormRow>
        <InputFormField name="fontSize" label="Font size" type={InputType.NotNullPositiveNumbers} />
        <div className={styles.fontWeight}>
          <FontWeightSelectFormField name="fontWeight" label="Font weight" />
        </div>
      </FormRow>
      <FormRow>
        <ColorFormField name="color" label="Color" />
        <div className={styles.textAlign}>
          <HorizontalAlignFormField name="textAlign" label="Text alignment" />
        </div>
      </FormRow>
    </Form>
  );
}
