import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';
import { Template } from '../../core/template';
import { AppState } from '../types';

/** Set User Engagement configuration for current edited template to given */
export const setUserEngagement = createAction<Template['userEngagement']>('setUserEngagement');

/** Append reducer for {@see setUserEngagement} action to Redux slice */
export const addSetUserEngagementReducer = (builder: ActionReducerMapBuilder<AppState>) => {
  builder.addCase(setUserEngagement, (state, action) => {
    if (!state.template) {
      throw new Error('Template is not loaded');
    }

    state.template.userEngagement = action.payload;
  });
};
