import classNames from 'classnames';
import { ReactNode } from 'react';
import styles from './ModalDescription.module.scss';

interface DescriptionProps {
  children: ReactNode;
  small?: boolean;
}

export function ModalDescription(props: DescriptionProps) {
  return (
    <p className={classNames({ [styles.description]: true, [styles.small]: props.small })}>
      {props.children}
    </p>
  );
}
