import { CommonWidgetIds } from '../../../../../../core/template';
import { TextareaFormField } from '../../../../../form-components';
import { WidgetType } from '../../../../../../core/widgets/definitions';
import { TextWidgetConfig } from '../../../../../../core/widgets/types';
import { AccordionSection, FormRow } from '../../../../../components';
import { useWidgetConfig } from '../../hooks';
import {
  FontConfig,
  MarginConfigSection,
  SetupConfigSection,
  StylingSection,
} from '../../panel-components';

interface TextWidgetPanelProps {
  id: string;
}

interface FormConfig {
  text: string;
}

function fromWidgetConfigToFormConfig(config: TextWidgetConfig): FormConfig {
  return {
    text: config.text,
  };
}

function fromFormConfigToWidgetConfig(config: FormConfig): Partial<TextWidgetConfig> {
  return {
    text: config.text,
  };
}

export function TextWidgetPanel(props: TextWidgetPanelProps) {
  const [widget, handleChanges] = useWidgetConfig(props.id, WidgetType.Text);

  let title: string;
  let subTitle: string | undefined;
  switch (props.id) {
    case CommonWidgetIds.PreHeadline:
      title = 'Pre-headline';
      break;
    case CommonWidgetIds.Headline:
      title = 'Headline';
      break;
    case CommonWidgetIds.SubHeadline:
      title = 'Subheadline';
      break;
    default:
      title = 'Text';
      subTitle = widget.text;
      break;
  }

  return (
    <AccordionSection
      id={props.id}
      title={title}
      subTitle={subTitle}
      isControlledWidgetCanBeRemoved={true}
    >
      <SetupConfigSection
        widget={widget}
        onChange={handleChanges}
        fromWidgetConfigToFormConfig={fromWidgetConfigToFormConfig}
        fromFormConfigToWidgetConfig={fromFormConfigToWidgetConfig}
      >
        <FormRow>
          <TextareaFormField name="text" label="Text" rows={4} placeholder="eg: 10% Off" />
        </FormRow>
      </SetupConfigSection>

      <StylingSection>
        <FontConfig widget={widget} onChange={handleChanges} />
      </StylingSection>

      <MarginConfigSection widget={widget} onChange={handleChanges} />
    </AccordionSection>
  );
}
