import classNames from 'classnames';
import { ReactNode } from 'react';
import mobileSignalImage from './assets/mobile-signal.svg';
import wifiImage from './assets/wifi.svg';
import batteryImage from './assets/battery.svg';
import styles from './DeviceFrame.module.scss';

interface DeviceFrameProps {
  url?: string;
  scale?: number;
  children: ReactNode;
  screenHover?: ReactNode;
}

export function DeviceFrame(props: DeviceFrameProps) {
  const hasScale = props.scale && props.scale < 0.5;

  return (
    <div className={styles.device}>
      <div className={classNames(styles.volume, styles.topVolume)} />
      <div className={classNames(styles.volume, styles.bottomVolume)} />

      <div className={classNames({ [styles.screen]: true, [styles.screenScale]: hasScale })}>
        {props.url && (
          <div className={styles.title}>
            <div className={styles.url}>{props.url}</div>
            <div className={styles.icons}>
              <img
                className={styles.icon}
                src={mobileSignalImage}
                width="9"
                height="6"
                alt="Mobile signal"
              />
              <img className={styles.icon} src={wifiImage} width="9" height="6" alt="WiFi" />
              <img className={styles.icon} src={batteryImage} width="14" height="6" alt="Battery" />
            </div>
          </div>
        )}

        <div
          className={classNames({
            [styles.content]: true,
            [styles.contentWithTitle]: !!props.url,
            [styles.contentScale]: hasScale,
          })}
        >
          {props.children}
        </div>
      </div>

      {props.screenHover && (
        <div className={classNames(styles.screen, styles.screenHover)}>{props.screenHover}</div>
      )}

      <div
        className={classNames({
          [styles.frame]: true,
          [styles.frameScale]: hasScale,
        })}
      />
      <div className={styles.power} />
    </div>
  );
}
