import classNames from 'classnames';
import React, { CSSProperties, ReactNode, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Transition } from 'react-transition-group';
import { openEditPanelAccordionSection, toggleWidgetVisibility } from '../../../../state/actions';
import { selectEditPanelState } from '../../../../state/selectors';
import { IconButton } from '../../icon-button/IconButton';
import { measureDomNode } from '../../../../core/utils';
import { RemoveWidgetModal } from '../../../modals';
import styles from './AccordionSection.module.scss';

/** Props for {@see AccordionSection} */
interface AccordionSectionProps {
  /** Section ID. Must unique in every {@see Accordion} */
  id: string;
  /** Section title text */
  title: string;
  /** Section sub title text. If given than will be show at right of {@see title}. */
  subTitle?: string;
  /** If `true` than section will be red */
  isHasError?: boolean;
  /**
   * If `true` than remove button will be shown.
   * When user click by this button widget with ID like section ID will be removed.
   */
  isControlledWidgetCanBeRemoved?: boolean;
  /**
   * If `true` than hide/show button will be show.
   * When user click by this button widget with ID like section ID will be hide or show.
   */
  isControlledWidgetCanChangeVisible?: boolean;
  /** Mark that indicate that widget wit ID like section ID is visible */
  isControlledWidgetVisible?: boolean;
  children: ReactNode;
}

/**
 * Accordion section. Must be used inside {@see Accordion}. Must contain `title` and can contain
 * `subTitle` that will be shoe at right of `title`. Can be red if `isHasError` is `true`.
 *
 * Also support control by widget with ID like section `id`, if set `isControlledWidgetCanBeRemoved`
 * or `isControlledWidgetCanChangeVisible` to `true`.
 */
export function AccordionSection(props: AccordionSectionProps) {
  const dispatch = useDispatch();
  const editPanelState = useSelector(selectEditPanelState);
  const isAutoScrollEnabled = editPanelState.isAccordionAutoScrollEnabled;
  const currentExpandedSectionId = editPanelState.expandedAccordionSectionId;
  const isExpanded = currentExpandedSectionId === props.id;

  const contentTransitionChildRef = useRef<HTMLDivElement>(null);
  const contentChildRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState<number>(0);

  const getContentHeight = () => {
    if (!contentChildRef.current) {
      return 0;
    }

    const size = measureDomNode(contentChildRef.current, (element) => {
      element.style.width = '292px';
      return element;
    });

    return size.height;
  };

  const updateContentHeight = () => {
    const newContentHeight = getContentHeight();
    if (contentHeight !== newContentHeight) {
      setContentHeight(newContentHeight);
    }
  };

  const handleTitleClick = () => {
    if (currentExpandedSectionId === props.id) {
      dispatch(openEditPanelAccordionSection({ sectionId: '', useAutoScroll: false }));
    } else {
      dispatch(openEditPanelAccordionSection({ sectionId: props.id, useAutoScroll: false }));
    }
  };

  const handleHideClick = () => {
    dispatch(toggleWidgetVisibility(props.id));
  };

  const [widgetRemoveModalVisible, setWidgetRemoveModalVisible] = useState(false);

  const handleRemoveClick = () => {
    setWidgetRemoveModalVisible(true);
  };

  // Auto-scroll behavior
  const sectionRef = useRef<HTMLSelectElement>(null);
  useEffect(() => {
    if (isAutoScrollEnabled && isExpanded && sectionRef.current) {
      setTimeout(() => {
        sectionRef?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }, 400);
    }
  }, [isExpanded, isAutoScrollEnabled]);

  const contentTransitionStyles: { [status: string]: CSSProperties } = {
    entering: { overflow: 'hidden', maxHeight: contentHeight },
    entered: { overflow: 'visible', maxHeight: 'unset' },
    exiting: { overflow: 'hidden', maxHeight: 0 },
    exited: { overflow: 'hidden', maxHeight: 0 },
  };

  return (
    <section ref={sectionRef} className={styles.section}>
      <div
        className={classNames({
          [styles.title]: true,
          [styles.titleWithError]: props.isHasError,
          [styles.expandedTitle]: isExpanded,
          [styles.titleWithRemove]: props.isControlledWidgetCanBeRemoved,
        })}
      >
        <button
          className={classNames({
            [styles.titleButton]: true,
            [styles.titleButtonWithError]: props.isHasError,
            [styles.expandedTitleButton]: isExpanded,
          })}
          onClick={handleTitleClick}
        >
          <span className={classNames({ [styles.titleText]: true })}>
            {props.title}
            {`${props.subTitle ? ': ' : ''}`}
            {props.subTitle && <span className={styles.subTitle}>{props.subTitle}</span>}
          </span>
        </button>

        {props.isControlledWidgetCanBeRemoved && (
          <>
            <div className={styles.rightButton}>
              <IconButton onClick={handleRemoveClick} tooltip="Remove widget">
                delete_outline
              </IconButton>
            </div>
          </>
        )}
        {props.isControlledWidgetCanChangeVisible && (
          <>
            <div className={styles.rightButton}>
              <IconButton
                onClick={handleHideClick}
                tooltip={props.isControlledWidgetVisible ? 'Show widget' : 'Hide widget'}
              >
                {props.isControlledWidgetVisible ? 'visibility' : 'visibility_off'}
              </IconButton>
            </div>
          </>
        )}
      </div>

      <Transition
        in={isExpanded}
        nodeRef={contentTransitionChildRef}
        timeout={300}
        mountOnEnter={true}
        unmountOnExit={true}
        appear={true}
        onEnter={updateContentHeight}
        onExit={() => {
          if (contentTransitionChildRef.current) {
            contentTransitionChildRef.current.style.maxHeight = `${getContentHeight()}px`;
          }
        }}
      >
        {(state) => (
          <div
            ref={contentTransitionChildRef}
            className={styles.content}
            style={{ ...contentTransitionStyles[state] }}
          >
            <div ref={contentChildRef} className={styles.contentPadding}>
              {props.children}
            </div>
          </div>
        )}
      </Transition>

      {props.isControlledWidgetCanBeRemoved && widgetRemoveModalVisible && (
        <RemoveWidgetModal
          widgetId={props.id}
          onRequestClose={() => setWidgetRemoveModalVisible(false)}
        />
      )}
    </section>
  );
}
