import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';
import { AppState } from '../types';

/** Set preview device size to given. Device size must be in `${width}x${height}` format. */
export const setPreviewDevice = createAction<string>('setPreviewDevice');

/** Append reducer for {@see setPreviewDevice} action to Redux slice */
export const addSetPreviewDeviceReducer = (builder: ActionReducerMapBuilder<AppState>) => {
  builder.addCase(setPreviewDevice, (state, action) => {
    state.preview.deviceScreen = action.payload;
  });
};
