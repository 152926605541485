import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';
import { CommonWidgetIds } from '../../core/template';
import { AppState } from '../types';

/** Remove widget with given widget ID */
export const removeWidget = createAction<string>('removeWidget');

/** Append reducer for {@see removeWidget} action to Redux slice */
export const addRemoveWidgetReducer = (builder: ActionReducerMapBuilder<AppState>) => {
  builder.addCase(removeWidget, (state, action) => {
    if (!state.template) {
      throw new Error('Template is not loaded');
    }

    state.isTemplateChanged = true;

    switch (action.payload) {
      case CommonWidgetIds.MainContainer:
      case CommonWidgetIds.PopupCloseButton:
        throw new Error(`Widget with ID=${action.payload} can't be deleted`);
      default:
        const widgetIndex = state.template.widgets.findIndex(
          (widget) => widget.id === action.payload
        );
        if (widgetIndex === -1) {
          throw new Error('Not found widget');
        }
        state.template.widgets.splice(widgetIndex, 1);
        break;
    }
  });
};
