import { createSelector } from '@reduxjs/toolkit';
import { CommonWidgetIds } from '../../core/template';
import { WidgetType } from '../../core/widgets/definitions';
import {
  ButtonWidgetConfig,
  ContainerWidgetConfig,
  IconButtonWidgetConfig,
  WidgetConfig,
} from '../../core/widgets/types';
import { selectTemplate } from './selectTemplate';

/**
 * Select widget by its ID.
 *
 * @param id Widget ID
 */
export const selectWidgetConfig = (id: string) =>
  createSelector([selectTemplate], (state) => {
    switch (id) {
      case CommonWidgetIds.TriggerButton:
        return {
          ...state.triggerButtonWidget,
          type: WidgetType.Button,
        } as ButtonWidgetConfig;
      case CommonWidgetIds.MainContainer:
        return {
          ...state.mainContainerWidget,
          type: WidgetType.Container,
        } as ContainerWidgetConfig;
      case CommonWidgetIds.PopupCloseButton:
        return {
          ...state.popupCloseButtonWidget,
          type: WidgetType.IconButton,
        } as IconButtonWidgetConfig;
      case CommonWidgetIds.TriggerButtonCloseButton:
        return {
          ...state.triggerButtonCloseButtonWidget,
          type: WidgetType.IconButton,
        } as IconButtonWidgetConfig;
      default: {
        const result = state.widgets.find((widget) => widget.id === id);
        if (!result) {
          throw new Error(`Not found widget with ID=${id}`);
        }
        return result as WidgetConfig;
      }
    }
  });
