import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';
import { AppState } from '../types';

/** Open tab with given ID in Customizer accordion */
export const openEditPanelTab = createAction<string>('openEditPanelTab');

/** Append reducer for {@see openEditPanelTab} action to Redux slice */
export const addOpenEditPanelTabReducer = (builder: ActionReducerMapBuilder<AppState>) => {
  builder.addCase(openEditPanelTab, (state, action) => {
    state.editPanel.activeTabId = action.payload;
  });
};
