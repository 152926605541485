import { createAsyncThunk } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import {
  CommonWidgetIds,
  DismissBehavior,
  FirstExperience,
  Template,
  TemplateTag,
  TimerType,
} from '../../core/template';
import {
  ClickActionType,
  FontFamily,
  FontWeight,
  HorizontalAlign,
  ImageFit,
  PoliciesLinkType,
  SmsType,
  WidgetType,
} from '../../core/widgets/definitions';
import { selectTemplate } from '../selectors';
import { AppState } from '../types';

export const downloadTemplate = createAsyncThunk<
  void,
  void,
  {
    state: { app: AppState };
    extra: {
      jwt: string;
    };
  }
>('downloadTemplate', async (action, thunkAPI) => {
  try {
    const template = selectTemplate(thunkAPI.getState());

    const typeScriptFile = templateTypeScript(template);

    await navigator.clipboard.writeText(typeScriptFile);
    alert('Template was copied to clipboard');
  } catch (e) {
    console.error(`Download template failed.`, e);
    throw e;
  }
});

function templateTypeScript(template: Template): string {
  const result: any = cloneDeep(template);

  result.info.tags = result.info.tags.map((tag: any) => mapEnum(tag, 'TemplateTag', TemplateTag));

  result.userEngagement.firstExperience = mapEnum(
    result.userEngagement.firstExperience,
    'FirstExperience',
    FirstExperience
  );
  result.userEngagement.timer.type = mapEnum(
    result.userEngagement.timer.type,
    'TimerType',
    TimerType
  );
  result.userEngagement.dismissBehavior = mapEnum(
    result.userEngagement.dismissBehavior,
    'DismissBehavior',
    DismissBehavior
  );

  result.horizontalPosition = mapEnum(
    result.horizontalPosition,
    'HorizontalAlign',
    HorizontalAlign
  );

  result.widgetPresets.popup = {
    overlay: result.mainContainerWidget.overlay ??
      result.widgetPresets.popup?.overlay ?? {
        color: '#33333320',
        blurRadius: 2,
      },
  };
  result.widgetPresets.buttons = {
    rounded: result.widgetPresets.buttons?.rounded ?? {
      borderRadius: 4,
    },
    ghost: result.widgetPresets.buttons?.ghost ?? {
      borderRadius: 0,
      border: {
        width: 1,
      },
    },
    singleLine: result.widgetPresets.buttons?.singleLine ?? {
      padding: {
        left: 16,
        right: 16,
        top: 13,
        bottom: 13,
      },
    },
    doubleLine: result.widgetPresets.buttons?.doubleLine ?? {
      padding: {
        left: 16,
        right: 16,
        top: 8,
        bottom: 8,
      },
    },
  };

  result.triggerButtonWidget = mapWidgetConfig(result.triggerButtonWidget, WidgetType.Button);
  result.mainContainerWidget = mapWidgetConfig(result.mainContainerWidget, WidgetType.Container);
  result.popupCloseButtonWidget = mapWidgetConfig(
    result.popupCloseButtonWidget,
    WidgetType.IconButton
  );
  result.triggerButtonCloseButtonWidget = mapWidgetConfig(
    result.triggerButtonCloseButtonWidget,
    WidgetType.IconButton
  );

  result.widgets = result.widgets.map((widget: any) => mapWidgetConfig(widget));

  const json = JSON.stringify(result, undefined, 2);

  const tsObject = json.replace(/"%%ENUM%%\.[^"]+"/g, (value) =>
    value.replace('"%%ENUM%%.', '').replace('"', '')
  );

  return `
import {
  ClickActionType,
  FontFamily,
  FontWeight,
  HorizontalAlign,
  ImageFit, PoliciesLinkType,
  SmsType,
  WidgetType
} from '../core/widgets/definitions';
import {
  CommonWidgetIds,
  DismissBehavior,
  FirstExperience,
  Template,
  TemplateTag,
  TimerType,
} from '../core/template';

export const template: Template = ${tsObject};`;
}

function mapEnum(enumValue: any, enumName: string, enumType: any): any {
  if (enumValue === undefined || enumValue === null) {
    return enumValue;
  }

  const resultEnumValue = Object.entries(enumType).find(([name, value]) => value === enumValue);
  if (!resultEnumValue) {
    throw new Error(`Not found ${enumValue} in ${enumName}`);
  }

  return `%%ENUM%%.${enumName}.${resultEnumValue[0]}`;
}

function mapWidgetConfig(widget: any, type?: WidgetType) {
  const widgetType = type ?? widget.type;

  if (
    widget.id !== undefined &&
    ![
      CommonWidgetIds.TriggerButton,
      CommonWidgetIds.MainContainer,
      CommonWidgetIds.PopupCloseButton,
      CommonWidgetIds.TriggerButtonCloseButton,
    ].includes(widget.id)
  ) {
    widget.type = mapEnum(widget.type, 'WidgetType', WidgetType);
    widget.id = mapEnum(widget.id, 'CommonWidgetIds', CommonWidgetIds);
  } else {
    widget.type = undefined;
    widget.id = undefined;
  }

  if (widget.onClick !== undefined) {
    widget.onClick.type = mapEnum(widget.onClick.type, 'ClickActionType', ClickActionType);
    widget.onClick.smsType = mapEnum(widget.onClick.smsType, 'SmsType', SmsType);
  }

  if (widget.widgetStyle.backgroundImage !== undefined) {
    if (widget.widgetStyle.backgroundImage.url !== undefined) {
      widget.widgetStyle.backgroundImage.fit = mapEnum(
        widget.widgetStyle.backgroundImage.fit,
        'ImageFit',
        ImageFit
      );
    } else {
      widget.widgetStyle.backgroundImage = undefined;
    }
  }

  widget.widgetStyle.textAlign = mapEnum(
    widget.widgetStyle.textAlign,
    'HorizontalAlign',
    HorizontalAlign
  );
  widget.widgetStyle.fontFamily = mapEnum(widget.widgetStyle.fontFamily, 'FontFamily', FontFamily);
  widget.widgetStyle.fontWeight = mapEnum(widget.widgetStyle.fontWeight, 'FontWeight', FontWeight);

  if (widgetType === WidgetType.PoliciesText) {
    if (widget.link !== undefined) {
      widget.link.type = mapEnum(widget.link.type, 'PoliciesLinkType', PoliciesLinkType);
    }
  }

  if (widgetType === WidgetType.Button) {
    if (widget.widgetStyle.firstRowStyle !== undefined) {
      widget.widgetStyle.firstRowStyle.textAlign = mapEnum(
        widget.widgetStyle.firstRowStyle.textAlign,
        'HorizontalAlign',
        HorizontalAlign
      );
      widget.widgetStyle.firstRowStyle.fontFamily = mapEnum(
        widget.widgetStyle.firstRowStyle.fontFamily,
        'FontFamily',
        FontFamily
      );
      widget.widgetStyle.firstRowStyle.fontWeight = mapEnum(
        widget.widgetStyle.firstRowStyle.fontWeight,
        'FontWeight',
        FontWeight
      );
      if (widget.widgetStyle.secondRowStyle !== undefined) {
        widget.widgetStyle.secondRowStyle.textAlign = mapEnum(
          widget.widgetStyle.secondRowStyle.textAlign,
          'HorizontalAlign',
          HorizontalAlign
        );
        widget.widgetStyle.secondRowStyle.fontFamily = mapEnum(
          widget.widgetStyle.secondRowStyle.fontFamily,
          'FontFamily',
          FontFamily
        );
        widget.widgetStyle.secondRowStyle.fontWeight = mapEnum(
          widget.widgetStyle.secondRowStyle.fontWeight,
          'FontWeight',
          FontWeight
        );
      }
    }
  }

  if (widgetType === WidgetType.Image) {
    widget.widgetStyle.align = mapEnum(
      widget.widgetStyle.align,
      'HorizontalAlign',
      HorizontalAlign
    );
    widget.widgetStyle.fit = mapEnum(widget.widgetStyle.fit, 'ImageFit', ImageFit);
  }

  return widget;
}
