import { useSelector } from 'react-redux';
import { PoliciesLinkType } from '../../../../../../core/widgets/definitions';
import { selectTemplate } from '../../../../../../state/selectors';
import { Form, InputFormField, RadioButton } from '../../../../../form-components';
import {
  AccordionSection,
  FormRow,
  EditPanelSection,
  EditPanelDescription,
} from '../../../../../components';
import { useForm } from './useForm';

export function LegalPanel() {
  const template = useSelector(selectTemplate);

  const form = useForm(template);

  return (
    <AccordionSection id="base_legal" title="Legal footer" isHasError={!form.isValid}>
      <Form
        initialValues={form.initialValues}
        onValuesChange={form.saveFormValues}
        validate={form.validateForm}
      >
        <EditPanelSection title="Legal">
          <EditPanelDescription>
            How often do you plan to text your subscribers?
          </EditPanelDescription>

          <FormRow>
            <InputFormField name="messagePerMount" label="Messages per month" placeholder="0" />
          </FormRow>
        </EditPanelSection>

        <EditPanelSection title="Policies">
          <EditPanelDescription>
            To help you comply with industry regulations, we can generate and host your Terms &
            Conditions and Privacy Policy pages.
          </EditPanelDescription>

          <RadioButton
            name="linkType"
            label="Auto-generated compliance"
            value={PoliciesLinkType.AutoGenerated}
            maxWidth={292}
          >
            <FormRow>
              <InputFormField
                name="companyName"
                label="Company name"
                placeholder="eg: SimpleTexting"
              />
            </FormRow>
            <FormRow>
              <InputFormField
                name="complianceEmail"
                label="Compliance email"
                placeholder="eg: hello@simpletexting.com"
              />
            </FormRow>
          </RadioButton>

          <RadioButton
            name="linkType"
            label="Custom compliance links"
            value={PoliciesLinkType.Manual}
            maxWidth={292}
          >
            <FormRow>
              <InputFormField
                name="termsConditionsLink"
                label="Terms & Conditions link"
                placeholder="eg: https://simpletexting.com"
              />
            </FormRow>
            <FormRow>
              <InputFormField
                name="privacyPolicyLink"
                label="Privacy Policy link"
                placeholder="eg: https://simpletexting.com"
              />
            </FormRow>
          </RadioButton>
        </EditPanelSection>
      </Form>
    </AccordionSection>
  );
}
