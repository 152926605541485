import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RenderTarget } from '../../core/template-renders/TemplateRender';
import { setConfigId } from '../../state/actions';
import { selectIsTemplateLoaded } from '../../state/selectors';
import { Device, RenderType } from '../components';
import './Preview.scss';

export function Preview() {
  const dispatch = useDispatch();
  const { configId } = useParams<{ configId?: string }>();
  const isTemplateLoaded = useSelector(selectIsTemplateLoaded);

  useEffect(() => {
    document.body.classList.add('preview');
  }, []);

  useEffect(() => {
    if (configId) {
      dispatch(setConfigId(configId));
    }
  }, [configId, dispatch]);

  if (!isTemplateLoaded) {
    return null;
  }

  return (
    <Device
      screenWidth={360}
      screenHeight={640}
      scale={0.4}
      renderTarget={RenderTarget.PopUp}
      renderType={RenderType.Static}
      useSavedTemplate={true}
    />
  );
}
