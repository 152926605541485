import { ComponentTheme } from '../../components';
import { FontFamily } from '../../../core/widgets/definitions';
import { SelectFormField, SelectValue } from '../select-form-field/SelectFormFiled';

export const fontOptions: SelectValue[] = [
  {
    value: FontFamily.Arvo,
    label: 'Avro',
  },
  {
    value: FontFamily.CrimsonText,
    label: 'Crimson text',
  },
  {
    value: FontFamily.HeptaSlab,
    label: 'Hepta slab',
  },
  {
    value: FontFamily.KumbhSans,
    label: 'Kumbh sans',
  },
  {
    value: FontFamily.Lato,
    label: 'Lato',
  },
  {
    value: FontFamily.Lora,
    label: 'Lora',
  },
  {
    value: FontFamily.Merriweather,
    label: 'Merriweather',
  },
  {
    value: FontFamily.Montserrat,
    label: 'Montserrat',
  },
  {
    value: FontFamily.Nunito,
    label: 'Nunito',
  },
  {
    value: FontFamily.OpenSans,
    label: 'Open sans',
  },
  {
    value: FontFamily.Oswald,
    label: 'Oswald',
  },
  {
    value: FontFamily.PlayfairDisplay,
    label: 'Playfair display',
  },
  {
    value: FontFamily.Poppins,
    label: 'Poppins',
  },
  {
    value: FontFamily.Roboto,
    label: 'Roboto',
  },
  {
    value: FontFamily.Rubik,
    label: 'Rubik',
  },
  {
    value: FontFamily.SourceSerifPro,
    label: 'SourceSerifPro',
  },
];

interface FontFamilyFormProps {
  name: string;
  label: string;
  theme?: ComponentTheme;
  menuPortalTarget?: HTMLElement | null;
}

export function FontFamilySelectFormField(props: FontFamilyFormProps) {
  return (
    <SelectFormField
      label={props.label}
      name={props.name}
      options={fontOptions}
      styles={{
        option: (styles, { data }) => {
          return {
            ...styles,
            fontFamily: data.value,
          };
        },
      }}
    />
  );
}
