import {
  ClickActionType,
  FontFamily,
  FontWeight,
  HorizontalAlign,
  ImageFit,
  PoliciesLinkType,
  SmsType,
  WidgetType,
} from '../core/widgets/definitions';
import {
  CommonWidgetIds,
  DismissBehavior,
  FirstExperience,
  Template,
  TemplateTag,
  TimerType,
} from '../core/template';

export const dynamic: Template = {
  mainContainerWidget: {
    widgetStyle: {
      borderRadius: 12,
      margin: {
        left: 12,
        bottom: 12,
        right: 12,
        top: 12,
      },
      backgroundColor: '#101010',
      shadow: {
        color: '#00000029',
        radius: 40,
      },
      padding: {
        bottom: 32,
        right: 32,
        top: 32,
        left: 32,
      },
    },
  },
  triggerButtonWidget: {
    widgetStyle: {
      padding: {
        bottom: 13,
        top: 13,
        right: 16,
        left: 16,
      },
      backgroundColor: '#872811',
      firstRowStyle: {
        fontFamily: FontFamily.Rubik,
        fontSize: 16,
        color: '#FFF',
        fontWeight: FontWeight.w400,
      },
      borderRadius: 4,
      margin: {
        left: 12,
        bottom: 12,
        right: 12,
        top: 12,
      },
      secondRowStyle: {
        fontFamily: FontFamily.Rubik,
        fontWeight: FontWeight.w400,
        color: '#FFF',
        fontSize: 10,
      },
      border: {
        color: '',
        width: 0,
      },
      isExpanded: true,
    },
    text: 'Get 10% Off',
  },
  triggerButtonCloseButtonWidget: {
    widgetStyle: {
      borderRadius: 20,
      color: 'rgba(0, 27, 72, 0.31)',
      shadow: {
        radius: 8,
        color: '#00000029',
      },
      padding: {
        right: 4,
        top: 4,
        bottom: 4,
        left: 4,
      },
      backgroundColor: '#FFFFFF',
      margin: {
        left: -8,
        top: -8,
        right: -8,
      },
      size: 14,
    },
  },
  popupCloseButtonWidget: {
    widgetStyle: {
      padding: {
        right: 8,
        left: 8,
        top: 8,
        bottom: 8,
      },
      color: 'rgba(225, 225, 225, 0.7)',
      size: 20,
    },
  },
  widgetPresets: {
    buttons: {
      rounded: {
        borderRadius: 4,
      },
      ghost: {
        border: {
          width: 1,
        },
        borderRadius: 0,
      },
      singleLine: {
        padding: {
          top: 13,
          left: 16,
          right: 16,
          bottom: 13,
        },
      },
      doubleLine: {
        padding: {
          left: 16,
          top: 8,
          right: 16,
          bottom: 8,
        },
      },
    },
    popup: {
      overlay: {
        color: '#33333320',
        blurRadius: 2,
      },
    },
  },
  widgets: [
    {
      id: CommonWidgetIds.LogoImage,
      localUrl: '',
      url:
        'https://firebasestorage.googleapis.com/v0/b/simple-texting-tools.appspot.com/o/configs%2FeAWWZ4Pv5xDBOsbW-vt6m%2Fimages%2FO1zZGACQbGnmKv6yzKSGJ.png?alt=media',
      type: WidgetType.Image,
      widgetStyle: {
        margin: {
          right: null,
          top: null,
          left: null,
          bottom: 0,
        },
        size: {
          width: 166,
          height: null,
        },
        fit: ImageFit.Contain,
        align: HorizontalAlign.Center,
      },
      fileName: 'suavecita-script-pink.png',
      onClick: {
        url: 'https://www.suavecito.com',
        type: ClickActionType.OpenLink,
      },
    },
    {
      type: WidgetType.Text,
      id: CommonWidgetIds.Headline,
      text: 'Coming soon',
      widgetStyle: {
        color: '#848484',
        fontFamily: FontFamily.Montserrat,
        margin: {
          left: null,
          right: null,
          top: 17,
          bottom: 12,
        },
        fontSize: 27,
        textAlign: HorizontalAlign.Center,
        fontWeight: FontWeight.w400,
      },
    },
    {
      type: WidgetType.Text,
      widgetStyle: {
        fontSize: 14,
        fontWeight: FontWeight.w400,
        fontFamily: FontFamily.Rubik,
        margin: {
          left: null,
          bottom: 24,
          right: null,
          top: null,
        },
        color: '#868686',
        textAlign: HorizontalAlign.Center,
      },
      text: 'As seen on Instagram',
      id: CommonWidgetIds.SubHeadline,
    },
    {
      type: WidgetType.Button,
      onClick: {
        smsType: SmsType.Keyword,
        text: '',
        phone: '(844) 928-4252',
        type: ClickActionType.SendMessage,
      },
      id: CommonWidgetIds.MainButton,
      text: ['SIGN UP', 'for new drops by text'],
      widgetStyle: {
        isExpanded: true,
        margin: {
          bottom: 20,
          right: null,
          left: null,
          top: null,
        },
        borderRadius: 0,
        border: {
          width: 0,
          color: '',
        },
        firstRowStyle: {
          fontWeight: FontWeight.w600,
          color: '#FFFFFF',
          fontFamily: FontFamily.Montserrat,
          fontSize: 16,
          textAlign: HorizontalAlign.Center,
        },
        secondRowStyle: {
          fontFamily: FontFamily.Montserrat,
          fontWeight: FontWeight.w500,
          fontSize: 10,
          color: '#FFFFFF',
          textAlign: HorizontalAlign.Center,
        },
        padding: {
          top: 10,
          bottom: 10,
          right: 16,
          left: 16,
        },
        backgroundColor: '#FD857A',
      },
    },
    {
      type: WidgetType.LegalText,
      widgetStyle: {
        fontSize: 9,
        textAlign: HorizontalAlign.Center,
        color: '#75849C',
        margin: {
          bottom: 16,
        },
        fontWeight: FontWeight.w400,
        fontFamily: FontFamily.Rubik,
      },
      id: CommonWidgetIds.LegalText,
      smsRate: 4,
    },
    {
      link: {
        type: PoliciesLinkType.AutoGenerated,
        companyName: 'Suavo Cito',
        complianceEmail: 'stephen+suavo+dark@simpletexting.net',
      },
      widgetStyle: {
        margin: {
          left: null,
          right: null,
          top: null,
          bottom: 0,
        },
        color: '#75849C',
        fontWeight: FontWeight.w400,
        fontSize: 9,
        textAlign: HorizontalAlign.Center,
        fontFamily: FontFamily.Rubik,
      },
      type: WidgetType.PoliciesText,
      id: CommonWidgetIds.PoliciesText,
    },
    {
      widgetStyle: {
        fontSize: 13,
        fontWeight: FontWeight.w400,
        color: '#75849C',
        fontFamily: FontFamily.Rubik,
        textAlign: HorizontalAlign.Center,
        margin: {
          top: 16,
        },
      },
      id: CommonWidgetIds.SimpleTextingCopyright,
      type: WidgetType.SimpleTextingCopyrightText,
      hidden: false,
    },
  ],
  info: {
    name: 'Dynamic',
    previewImageUrl: '/assets/themes/dynamic.png',
    tags: [TemplateTag.Mini, TemplateTag.OfferFocused, TemplateTag.TextOnly],
  },
  horizontalPosition: HorizontalAlign.Right,
  userEngagement: {
    timer: {
      type: TimerType.Immediately,
    },
    firstExperience: FirstExperience.TriggerButton,
    dismissBehavior: DismissBehavior.ShowTriggerButton,
  },
};
