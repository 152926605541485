import { CSSProperties } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import { Transition } from 'react-transition-group';
import { RenderTarget } from '../../../core/template-renders/TemplateRender';
import { setExpertModeEnabled } from '../../../state/actions';
import {
  selectEditPanelState,
  selectIsTemplateLoaded,
  selectPreviewConfig,
} from '../../../state/selectors';
import { ComponentTheme, Device, PreviewConfigForm, RenderType } from '../../components';
import { Form, Size, SwitchFormField } from '../../form-components';
import { BaseWidgetsConfigPanel } from './base-widgets-config-panel/BaseWidgetsConfigPanel';
import styles from './CustomizerPage.module.scss';
import { ExpertWidgetsConfigPanel } from './expert-widgets-config-panel/ExpertWidgetsConfigPanel';
import arrowImage from './assets/arrow.svg';

const leftPanelTransitionStyles: { [status: string]: CSSProperties } = {
  entering: { left: 0, opacity: 1 },
  entered: { left: 0, opacity: 1 },
  exiting: { left: -360, opacity: 0 },
  exited: { left: -360, opacity: 0 },
};

export function CustomizerPage() {
  const dispatch = useDispatch();

  const editPanelConfig = useSelector(selectEditPanelState);
  const isTemplateLoaded = useSelector(selectIsTemplateLoaded);
  const config = useSelector(selectPreviewConfig);

  const screenSize = config.deviceScreen.split('x');
  if (screenSize.length !== 2) {
    throw new Error(`Invalid screen size ${screenSize}`);
  }
  const screenWidth = Number(screenSize[0]);
  const screenHeight = Number(screenSize[1]);

  const handleExpertModeFormChange = (values: { expertMode: boolean }) => {
    dispatch(setExpertModeEnabled(values.expertMode));
  };

  if (!isTemplateLoaded) {
    return null;
  }

  return (
    <section className={styles.customizerPage}>
      <div className={styles.leftPart}>
        <div className={styles.configPanel}>
          <Transition
            in={editPanelConfig.isExpertModeEnabled}
            timeout={300}
            mountOnEnter={true}
            unmountOnExit={true}
          >
            {(state) => (
              <div
                className={styles.configPanelTransition}
                style={{ ...leftPanelTransitionStyles[state] }}
              >
                <ExpertWidgetsConfigPanel />
              </div>
            )}
          </Transition>
          <Transition
            in={!editPanelConfig.isExpertModeEnabled}
            timeout={300}
            mountOnEnter={true}
            unmountOnExit={true}
          >
            {(state) => (
              <div
                className={styles.configPanelTransition}
                style={{ ...leftPanelTransitionStyles[state] }}
              >
                <BaseWidgetsConfigPanel />
              </div>
            )}
          </Transition>
        </div>

        <div className={styles.expertMode}>
          <Form
            initialValues={{ expertMode: editPanelConfig.isExpertModeEnabled }}
            onValuesChange={handleExpertModeFormChange}
          >
            <SwitchFormField
              name="expertMode"
              label="Advanced mode"
              theme={ComponentTheme.Transparent}
              size={Size.Mini}
            />
          </Form>
        </div>
      </div>

      <PerfectScrollbar className={styles.previewScroll}>
        <div className={styles.preview}>
          <PreviewConfigForm />

          <div className={styles.devices}>
            <section className={styles.device}>
              <Device
                screenWidth={260}
                screenHeight={440}
                renderTarget={RenderTarget.TriggerButton}
                renderType={RenderType.Develop}
                showUrl={true}
              />
            </section>

            <img className={styles.arrow} src={arrowImage} alt="Arrow" />

            <section className={styles.device}>
              <Device
                screenWidth={screenWidth}
                screenHeight={screenHeight}
                renderTarget={RenderTarget.PopUp}
                renderType={RenderType.Develop}
              />
            </section>
          </div>
        </div>
      </PerfectScrollbar>
    </section>
  );
}
