import { Dispatch } from 'react';
import { useDispatch } from 'react-redux';
import { CommonWidgetIds, Template } from '../../../../../../core/template';
import { contrastToHexColor, findTemplateWidget } from '../../../../../../core/utils';
import { HorizontalAlign, WidgetType } from '../../../../../../core/widgets/definitions';
import { mergeWidgetConfig, setMainHorizontalPosition } from '../../../../../../state/actions';
import { normalizeColor } from '../../../../../form-components';
import { UseFormResult } from '../../types';

interface FormConfig {
  position: HorizontalAlign;
  backgroundColor: string;
  simpleTextingCopyrightEnabled: boolean;
  overlayVisible: boolean;
}

function validateForm(values: FormConfig) {
  const errors: { [name in keyof FormConfig]?: string } = {};

  return errors;
}

function getWidgets(template: Template) {
  const mainContainer = findTemplateWidget(
    template,
    CommonWidgetIds.MainContainer,
    WidgetType.Container
  );
  const simpleTextingCopyright = findTemplateWidget(
    template,
    CommonWidgetIds.SimpleTextingCopyright,
    WidgetType.SimpleTextingCopyrightText
  );
  const mainButton = findTemplateWidget(template, CommonWidgetIds.MainButton, WidgetType.Button);
  const triggerButton = findTemplateWidget(
    template,
    CommonWidgetIds.TriggerButton,
    WidgetType.Button
  );
  const dismissButton = findTemplateWidget(
    template,
    CommonWidgetIds.DismissButton,
    WidgetType.Button
  );
  const popupCloseButton = findTemplateWidget(
    template,
    CommonWidgetIds.PopupCloseButton,
    WidgetType.IconButton
  );

  return {
    mainContainer,
    simpleTextingCopyright,
    mainButton,
    triggerButton,
    dismissButton,
    popupCloseButton,
  };
}

function formInitialValue(template: Template): FormConfig {
  const { mainContainer, simpleTextingCopyright } = getWidgets(template);

  return {
    position: template.horizontalPosition ?? HorizontalAlign.Right,
    backgroundColor: normalizeColor(mainContainer?.widgetStyle?.backgroundColor),
    simpleTextingCopyrightEnabled: !simpleTextingCopyright?.hidden,
    overlayVisible:
      !!mainContainer?.widgetStyle?.overlay?.color ||
      !!mainContainer?.widgetStyle?.overlay?.blurRadius,
  };
}

const saveFormValues = (template: Template, dispatch: Dispatch<any>) => (values: FormConfig) => {
  const {
    mainContainer,
    simpleTextingCopyright,
    mainButton,
    triggerButton,
    dismissButton,
    popupCloseButton,
  } = getWidgets(template);

  dispatch(setMainHorizontalPosition(values.position));

  if (mainContainer) {
    dispatch(
      mergeWidgetConfig({
        ...mainContainer,
        widgetStyle: {
          backgroundColor: values.backgroundColor,
          overlay: {
            color: values.overlayVisible
              ? mainContainer.widgetStyle?.overlay?.color ??
                template.widgetPresets.popup.overlay.color
              : null,
            blurRadius: values.overlayVisible
              ? mainContainer.widgetStyle?.overlay?.blurRadius ??
                template.widgetPresets.popup.overlay.blurRadius
              : null,
          },
        },
      })
    );
  }

  if (simpleTextingCopyright) {
    dispatch(
      mergeWidgetConfig({
        ...simpleTextingCopyright,
        hidden: !values.simpleTextingCopyrightEnabled,
      })
    );
  }

  if (mainButton && mainButton.widgetStyle?.border?.width) {
    dispatch(
      mergeWidgetConfig({
        ...mainButton,
        widgetStyle: {
          ...mainButton.widgetStyle,
          backgroundColor: values.backgroundColor,
        },
      })
    );
  }

  if (triggerButton && triggerButton.widgetStyle?.border?.width) {
    dispatch(
      mergeWidgetConfig({
        ...triggerButton,
        widgetStyle: {
          ...triggerButton.widgetStyle,
          backgroundColor: values.backgroundColor,
        },
      })
    );
  }

  if (dismissButton && dismissButton.widgetStyle?.border?.width) {
    dispatch(
      mergeWidgetConfig({
        ...dismissButton,
        widgetStyle: {
          ...dismissButton.widgetStyle,
          backgroundColor: values.backgroundColor,
        },
      })
    );
  }

  if (popupCloseButton) {
    dispatch(
      mergeWidgetConfig({
        ...popupCloseButton,
        widgetStyle: {
          ...popupCloseButton.widgetStyle,
          color: contrastToHexColor(values.backgroundColor, 0.7),
        },
      })
    );
  }
};

export function useGeneralForm(template: Template): UseFormResult<FormConfig> {
  const dispatch = useDispatch();
  const initialValue = formInitialValue(template);

  return {
    initialValues: initialValue,
    saveFormValues: saveFormValues(template, dispatch),
    validateForm: validateForm,
    isValid: true,
  };
}
