import { WidgetConfig, WithOverlay } from '../../../../../../core/widgets/types';
import { FormRow } from '../../../../../components';
import {
  ColorFormField,
  Form,
  InputFormField,
  InputType,
  normalizeColor,
} from '../../../../../form-components';
import { useStyleConfigHelpers } from '../../hooks';
import { WidgetStyleConfigPanelProps } from '../types';

interface FormValue {
  color: string;
  blurRadius: number;
}

export function OverlayColorConfig<T extends WidgetConfig>(
  props: WidgetStyleConfigPanelProps<WithOverlay, T>
) {
  const [widgetStyle, onChange] = useStyleConfigHelpers(props);

  const initialValue: FormValue = {
    color: normalizeColor(widgetStyle?.overlay?.color),
    blurRadius: widgetStyle?.overlay?.blurRadius ?? 0,
  };

  const handleChange = (formValues: FormValue) => {
    onChange({
      overlay: {
        color: formValues.color || null,
        blurRadius: formValues.blurRadius || null,
      },
    });
  };

  return (
    <Form initialValues={initialValue} onValuesChange={handleChange}>
      <FormRow>
        <ColorFormField name="color" label="Overlay color" />
        <InputFormField
          name="blurRadius"
          label="Overlay blur radius"
          type={InputType.PositiveNumbers}
        />
      </FormRow>
    </Form>
  );
}
