import { ReactNode } from 'react';
import { DeepPartial } from '../../../../../../types';
import { Form } from '../../../../../form-components';
import { WidgetConfig } from '../../../../../../core/widgets/types';
import { EditPanelSection } from '../../../../../components/edit-panel-section/EditPanelSection';

interface SetupConfigSectionProps<T, D> {
  widget: T;
  onChange: (config: DeepPartial<T>) => void;
  onIsValidChange?: (isValid: boolean) => void;
  fromWidgetConfigToFormConfig: (config: T) => D;
  fromFormConfigToWidgetConfig: (config: D) => DeepPartial<T>;
  validate?: (config: D) => { [field in keyof D]?: string };
  children: ReactNode;
}

export function SetupConfigSection<T extends WidgetConfig, D>(
  props: SetupConfigSectionProps<T, D>
) {
  const initialValue: D = props.fromWidgetConfigToFormConfig(props.widget);

  const handleChange = (formValues: D) => {
    props.onChange(props.fromFormConfigToWidgetConfig(formValues));
  };

  return (
    <EditPanelSection title="Setup">
      <Form
        initialValues={initialValue}
        onValuesChange={handleChange}
        onIsValidChange={props.onIsValidChange}
        validate={props.validate}
      >
        {props.children}
      </Form>
    </EditPanelSection>
  );
}
