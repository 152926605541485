import classNames from 'classnames';
import { useField } from 'formik';
import Select from 'react-select';
import { StylesConfig } from 'react-select/src/styles';
import { OptionsType } from 'react-select/src/types';
import { ComponentTheme } from '../../components';
import { FormField } from '../form-field/FormField';
import './SelectFormField.scss';

export interface SelectValue {
  label: string;
  value: string;
}

interface SelectFormFiledProps {
  name: string;
  label: string;
  options: OptionsType<SelectValue>;
  theme?: ComponentTheme;
  styles?: StylesConfig<SelectValue, false>;
}

export function SelectFormField(props: SelectFormFiledProps) {
  const [field, , helpers] = useField(props as any);

  const value = props.options.find((option) => option.value === field.value);

  return (
    <FormField label={props.label} theme={props.theme}>
      <Select
        className={classNames({
          'st-select-form-field': true,
          'st-select-form-field--transparent': props.theme === ComponentTheme.Transparent,
        })}
        classNamePrefix="st-select-form-field"
        value={value}
        options={props.options}
        onChange={(option) => helpers.setValue(option?.value ?? '')}
        styles={props.styles}
      />
    </FormField>
  );
}
