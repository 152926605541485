import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { AppConfig } from '../../../core/appConfig';
import { CommonWidgetIds } from '../../../core/template';
import { DebugTemplateRender } from '../../../core/template-renders/debug/DebugTemplateRender';
import { ReleaseTemplateRender } from '../../../core/template-renders/release/ReleaseTemplateRender';
import { RenderTarget } from '../../../core/template-renders/TemplateRender';
import { findTemplateWidget, fixUrl } from '../../../core/utils';
import { ClickActionType, WidgetType } from '../../../core/widgets/definitions';
import { selectPreviewConfig, selectSavedTemplate, selectTemplate } from '../../../state/selectors';
import { DeviceFrame } from '../device-frame/DeviceFrame';
import styles from './Device.module.scss';
import { SmsApplication } from './sms-application/SmsApplication';

export enum RenderType {
  Develop,
  Static,
  Release,
}

interface DeviceProps {
  screenWidth: number;
  screenHeight: number;
  renderTarget?: RenderTarget;
  renderType: RenderType;
  scale?: number;
  showUrl?: boolean;
  useSavedTemplate?: boolean;
}

export function Device(props: DeviceProps) {
  const url = useSelector(selectPreviewConfig).url;
  const editedTemplate = useSelector(selectTemplate);
  const savedTemplate = useSelector(selectSavedTemplate);
  const template = props.useSavedTemplate ? savedTemplate : editedTemplate;
  if (!template) {
    throw new Error('Saved template not exists.');
  }

  const mainButton = findTemplateWidget(template, CommonWidgetIds.MainButton, WidgetType.Button);
  if (!mainButton) {
    throw new Error('Not found main button');
  }
  if (mainButton.onClick?.type !== ClickActionType.SendMessage) {
    throw new Error('Main button has wrong onClick type');
  }

  const [smsApplicationVisible, setSmsApplicationVisible] = useState(false);

  const screenshotUrl = new URL(`${AppConfig.backendUrl}/sitePreview`);
  screenshotUrl.searchParams.append('url', fixUrl(url));

  return (
    <div
      className={styles.deviceContainer}
      style={{
        minWidth: props.screenWidth * (props.scale ?? 1),
        width: props.screenWidth * (props.scale ?? 1),
        height: props.screenHeight * (props.scale ?? 1),
      }}
    >
      <DeviceFrame url={props.showUrl ? url : undefined} scale={props.scale}>
        <div
          className={styles.screen}
          style={{
            width: `${100 / (props.scale ?? 1)}%`,
            height: `${100 / (props.scale ?? 1)}%`,
            transform: props.scale ? `scale(${props.scale})` : undefined,
            transformOrigin: 'top left',
          }}
        >
          <div
            className={styles.screenBackground}
            style={{
              backgroundImage: `url(${screenshotUrl.toString()})`,
            }}
          >
            {smsApplicationVisible ? (
              <SmsApplication
                phone={mainButton.onClick.phone}
                message={mainButton.onClick.text}
                onBackClick={() => setSmsApplicationVisible(false)}
              />
            ) : (
              <div className={styles.widget}>
                {props.renderType === RenderType.Release ? (
                  <ReleaseTemplateRender
                    template={template}
                    fitToDiv={true}
                    onShowSmsApplication={() => setSmsApplicationVisible(true)}
                  />
                ) : (
                  <DebugTemplateRender
                    template={template}
                    renderTarget={props.renderTarget}
                    useReleaseWidgets={props.renderType === RenderType.Static}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </DeviceFrame>
      {/*<div*/}
      {/*  className={styles.device}*/}
      {/*  style={{*/}
      {/*    width: props.screenWidth + 20,*/}
      {/*    height: props.screenHeight + 88,*/}
      {/*    transform: props.scale ? `scale(${props.scale})` : undefined,*/}
      {/*    transformOrigin: 'top left',*/}
      {/*    borderWidth,*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <div className={styles.cameraAndSpeaker} style={{ borderWidth }}>*/}
      {/*    <div className={styles.camera} style={{ borderWidth }} />*/}
      {/*    <div className={styles.speaker} style={{ borderWidth }} />*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  );
}
