import classNames from 'classnames';
import { useField } from 'formik';
import { ReactNode } from 'react';
import { ComponentTheme } from '../../components';
import { FormField } from '../form-field/FormField';
import styles from './InputFormField.module.scss';
import MaskedInput, { maskArray } from 'react-text-mask';

export enum InputType {
  Text,
  Numbers,
  PositiveNumbers,
  NotNullPositiveNumbers,
  PhoneNumber,
  Keyword,
}

interface InputFormFieldProps {
  name: string;
  label: string;
  theme?: ComponentTheme;
  type?: InputType;
  placeholder?: string;
  helperText?: ReactNode;
  mask?: maskArray;
  disabled?: boolean;
  showErrorOnTouched?: boolean;
  max?: number;
}

const keywordMask: RegExp[] = [];
for (let i = 0; i < 40; ++i) {
  keywordMask.push(/\w/);
}

const phoneMask = (value: string): maskArray => {
  const phone = value.replace(/[^\d]/gi, '');

  if (phone.length < 7) {
    return [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
  }

  return ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
};

// const positiveNumberMask = [/[1-9]/, /\d/, /\d/, /\d/];

export function InputFormField(props: InputFormFieldProps) {
  const [field, meta, helpers] = useField(props as any);

  let type: 'text' | 'number' = 'text';
  let mask: maskArray | ((value: string) => maskArray) | undefined = props.mask;
  let min: number | undefined;
  let max: number | undefined;
  if (!mask) {
    switch (props.type) {
      case InputType.Numbers:
        type = 'number';
        min = -999;
        max = 999;
        break;
      case InputType.PositiveNumbers:
        type = 'number';
        min = 0;
        max = props.max ?? 999;
        break;
      case InputType.NotNullPositiveNumbers:
        type = 'number';
        min = 1;
        max = 999;
        break;
      case InputType.PhoneNumber:
        mask = phoneMask;
        break;
      case InputType.Keyword:
        mask = keywordMask;
        break;
    }
  }

  let placeholder: string | undefined = props.placeholder;
  if (!placeholder) {
    switch (props.type) {
      case InputType.PositiveNumbers:
        placeholder = props.placeholder ?? '0';
        break;
    }
  }

  const error = props.showErrorOnTouched ? (meta.touched ? meta.error : undefined) : meta.error;

  return (
    <FormField
      label={props.label}
      theme={props.theme}
      error={error}
      helperText={props.helperText}
      disabled={props.disabled}
    >
      {mask ? (
        // TODO: Make MaskedInput valid control Forkim initialValues changes
        <MaskedInput
          {...field}
          placeholder={placeholder}
          mask={mask || false}
          guide={false}
          showMask={false}
          className={classNames({
            [styles.input]: true,
            [styles.inputTransparent]: props.theme === ComponentTheme.Transparent,
            [styles.hasError]: error,
          })}
          onInput={(event) => {
            // This is MaskedInput fix
            // Without it, when value deleted onChange is not emitted
            const target = event.currentTarget;
            setTimeout(() => {
              if (field.value !== target.value) {
                helpers.setValue(target.value);
              }
            });
          }}
          disabled={props.disabled}
        />
      ) : (
        <input
          {...field}
          placeholder={placeholder}
          className={classNames({
            [styles.input]: true,
            [styles.inputTransparent]: props.theme === ComponentTheme.Transparent,
            [styles.hasError]: error,
          })}
          type={type}
          min={min}
          max={max}
          disabled={props.disabled}
        />
      )}
    </FormField>
  );
}
