import { Dispatch } from 'react';
import { useDispatch } from 'react-redux';
import { findTemplateWidget } from '../../../../../../core/utils';
import { mergeWidgetConfig } from '../../../../../../state/actions';
import { CommonWidgetIds, Template } from '../../../../../../core/template';
import { normalizeColor } from '../../../../../form-components';
import {
  FontFamily,
  FontWeight,
  HorizontalAlign,
  WidgetType,
} from '../../../../../../core/widgets/definitions';
import { UseFormResult } from '../../types';

interface FormConfig {
  headlineFontFamily: FontFamily;
  headlineFontWight: FontWeight;
  headlineColor: string;
  headlineFontSize: string;
  headlineAlign: HorizontalAlign;
  preAndSubHeadlineFontFamily: FontFamily;
  preAndSubHeadlineFontWight: FontWeight;
  preAndSubHeadlineColor: string;
  preAndSubHeadlineFontSize: string;
  preAndSubHeadlineAlign: HorizontalAlign;
  preHeadlineTopPadding: string;
  subHeadlineBottomPadding: string;
  headlineTopPadding: string;
  headlineBottomPadding: string;
}

function validateForm(values: FormConfig) {
  const errors: { [name in keyof FormConfig]?: string } = {};

  return errors;
}

function getWidgets(template: Template) {
  const headlineText = findTemplateWidget(template, CommonWidgetIds.Headline, WidgetType.Text);
  const preHeadlineText = findTemplateWidget(
    template,
    CommonWidgetIds.PreHeadline,
    WidgetType.Text
  );
  const subHeadlineText = findTemplateWidget(
    template,
    CommonWidgetIds.SubHeadline,
    WidgetType.Text
  );
  const preOrSubHeadlineText = preHeadlineText || subHeadlineText;

  return {
    headlineText,
    preHeadlineText,
    subHeadlineText,
    preOrSubHeadlineText,
  };
}

function formInitialValue(template: Template): FormConfig {
  const { headlineText, preHeadlineText, subHeadlineText, preOrSubHeadlineText } = getWidgets(
    template
  );

  return {
    headlineFontFamily: headlineText?.widgetStyle?.fontFamily ?? FontFamily.Arvo,
    headlineFontWight: headlineText?.widgetStyle?.fontWeight ?? FontWeight.w400,
    headlineColor: normalizeColor(headlineText?.widgetStyle?.color),
    headlineFontSize: headlineText?.widgetStyle?.fontSize?.toFixed() ?? '14',
    headlineAlign: headlineText?.widgetStyle?.textAlign ?? HorizontalAlign.Center,
    preAndSubHeadlineFontFamily: preOrSubHeadlineText?.widgetStyle?.fontFamily ?? FontFamily.Arvo,
    preAndSubHeadlineFontWight: preOrSubHeadlineText?.widgetStyle?.fontWeight ?? FontWeight.w400,
    preAndSubHeadlineColor: normalizeColor(preOrSubHeadlineText?.widgetStyle?.color),
    preAndSubHeadlineFontSize: preOrSubHeadlineText?.widgetStyle?.fontSize?.toFixed() ?? '12',
    preAndSubHeadlineAlign: preOrSubHeadlineText?.widgetStyle?.textAlign ?? HorizontalAlign.Center,
    preHeadlineTopPadding:
      (preHeadlineText ? preHeadlineText.widgetStyle?.margin?.top?.toFixed() : '0') ?? '0',
    subHeadlineBottomPadding:
      (subHeadlineText ? subHeadlineText.widgetStyle?.margin?.bottom?.toFixed() : '0') ?? '0',
    headlineTopPadding: headlineText?.widgetStyle?.margin?.top?.toFixed() ?? '0',
    headlineBottomPadding: headlineText?.widgetStyle?.margin?.bottom?.toFixed() ?? '0',
  };
}

const saveFormValues = (template: Template, dispatch: Dispatch<any>) => (values: FormConfig) => {
  const { headlineText, preHeadlineText, subHeadlineText } = getWidgets(template);

  if (headlineText) {
    dispatch(
      mergeWidgetConfig({
        ...headlineText,
        widgetStyle: {
          textAlign: values.headlineAlign,
          fontFamily: values.headlineFontFamily,
          fontWeight: values.headlineFontWight,
          fontSize: values.headlineFontSize !== '' ? Number(values.headlineFontSize) : null,
          color: values.headlineColor || null,
          margin: {
            top: values.headlineTopPadding !== '' ? Number(values.headlineTopPadding) : null,
            bottom:
              values.headlineBottomPadding !== '' ? Number(values.headlineBottomPadding) : null,
          },
        },
      })
    );
  }

  if (preHeadlineText) {
    dispatch(
      mergeWidgetConfig({
        ...preHeadlineText,
        widgetStyle: {
          textAlign: values.preAndSubHeadlineAlign,
          fontFamily: values.preAndSubHeadlineFontFamily,
          fontWeight: values.preAndSubHeadlineFontWight,
          fontSize:
            values.preAndSubHeadlineFontSize !== ''
              ? Number(values.preAndSubHeadlineFontSize)
              : null,
          color: values.preAndSubHeadlineColor || null,
          margin: {
            top: values.preHeadlineTopPadding !== '' ? Number(values.preHeadlineTopPadding) : null,
          },
        },
      })
    );
  }

  if (subHeadlineText) {
    dispatch(
      mergeWidgetConfig({
        ...subHeadlineText,
        widgetStyle: {
          textAlign: values.preAndSubHeadlineAlign,
          fontFamily: values.preAndSubHeadlineFontFamily,
          fontWeight: values.preAndSubHeadlineFontWight,
          fontSize:
            values.preAndSubHeadlineFontSize !== ''
              ? Number(values.preAndSubHeadlineFontSize)
              : null,
          color: values.preAndSubHeadlineColor || null,
          margin: {
            bottom:
              values.subHeadlineBottomPadding !== ''
                ? Number(values.subHeadlineBottomPadding)
                : null,
          },
        },
      })
    );
  }
};

export function useTextForm(template: Template): UseFormResult<FormConfig> {
  const dispatch = useDispatch();
  const initialValue = formInitialValue(template);

  return {
    initialValues: initialValue,
    saveFormValues: saveFormValues(template, dispatch),
    validateForm: validateForm,
    isValid: true,
  };
}
